import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { BsBookmark, BsBookmarkFill, BsPinAngleFill } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import {
  ImageUtil,
  getUrlParams,
  utf8ToBase64,
  getContentTypeNamesById2,
} from "../../../utility/utility";
import CardExampleSize from "../../../commonui/card/Card";
import Comments from "../comments/comments";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import TextEditor from "../texteditor/textEditor";
import {
  getDateFromUTC,
  getDateFromUTC2,
} from "../../../utility/timefunctions";
import { Divider, ReplyIcon, Image } from "@fluentui/react-northstar";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import DialogBox from "../../../commonui/dialogBox/DialogBox";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { getData, postData } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import Constant from "../../../utility/constants";
import TelemetryEventNames from "../../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../../services/appInsights";
import CustomizedToolTip from "../../../commonui/tooltip/toolTip";
import findIndex from "lodash/findIndex";
import "./bulletinCard.css";

const BulletinCard = (props) => {
  const {
    item,
    config,
    onClick,
    showDivider,
    handleUnpin,
    scrolledCardId,
    hasMoreData,
  } = props;
  let { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  /*"pinDetail":{"createdDetails": {
          "displayName": "User 107",
          "email": "M365.User107@exo.eydevo365.com",
          "date": "23/04/23 10:00 PM",
      }}*/

  const trackedItemsRef = useRef(new Set());
  const [showEditor, setShowEditor] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isBookmarkActive, setBookmark] = useState(
    item?.customData?.userLevel?.bookmarked
  );
  const [comments, setComments] = useState([]);
  const [parentCommentId, setParentCommentId] = useState(null);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [currentCardClicked, setCurrentCardClicked] = useState(false);
  const { instance } = useMsal();

  const handleToggle = (event) => {
    if (event.type === "click") {
      setIsCommentsOpen(!isCommentsOpen);
    } else if (event.type === "keydown" && event.keyCode === 13) {
      setIsCommentsOpen(!isCommentsOpen);
    }
  };

  const unpinCard = () => {
    setLoader(true);
    let payload = {
      metaDataId: item?.metaDataId,
      isPinned: false,
      pinMetaDataId: item?.pinDetail?.pinMetaDataId,
    };
    postData(
      payload,
      APIEndPoints.PIN_UNPIN_CARD(
        props.contentTypeId,
        item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId,
        false
      )
    )
      .then((response) => {
        setOpenDialog(false);
        setLoader(false);
        // if (response?.data?.statusCode === "STCS001") {
        //   props?.pathname === "/search"
        //     ? props?.handleDelete()
        //     : props?.handleReload();

        // }
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg:
            response?.data?.status === "success"
              ? props.contentTypeId == 13
                ? Constant.APP_UNPINNED_SUCCESSFULLY
                : Constant.UNPINNED_SUCCESSFULLY
              : Constant.ContentNotAvailable,
          type: response?.data?.status === "success" ? "Success" : "Fail",
          isFailed: false,
        });
        handleUnpin(item?.metaDataId, props.contentTypeId);
        props?.isBulletinBoard == true && props?.dismissModal();
        TrackEvent(TelemetryEventNames.UCC_BB_UNPIN_EVENT, userInfo, {
          SectionName:
            item?.contentType == 4
              ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
              : TelemetryEventNames.SECTION_TYPE_CONTENT,
          ContentTypeId: item.contentTypeId?.toString() ?? "",
          ContentTypeName: getContentTypeNamesById2(item.contentTypeId) ?? "",
          MetadataId: item.metaDataId?.toString(),
          UniqueId: item.contentUniqueId ?? "",
          Title: item.title ?? "",
          PublishDate: item.publishedDate ?? "",
          PinStatus: "Unpinned",
        });
      })
      .catch((err) => {
        setOpenDialog(false);
        setLoader(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };
  let prevViewportWidth = window.innerWidth;
  const handleZoomChange = () => {
    const currentViewportWidth = window.innerWidth;
    const zoomFactor = currentViewportWidth / prevViewportWidth;

    const overlay = document.querySelector(".loaderOnDelete");
    if (overlay) {
      overlay.style.width = parseFloat(overlay.style.width) * zoomFactor + "px";
      overlay.style.height =
        parseFloat(overlay.style.height) * zoomFactor + "px";
    }
    prevViewportWidth = currentViewportWidth;
  };

  const handleBookmarkKeyDown = (e) => {
    if (e.key === "Enter") {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      handleBookmarkClick(event);
      e.preventDefault();
    }
  };
  const handleBookmarkClick = (e) => {
    e.stopPropagation();
    setBookmark((current) => !current);

    let obj = {
      isBookmarked: !isBookmarkActive,
      metaDataId: item?.metaDataId,
    };
    postData(
      obj,
      APIEndPoints.CONTENT_BOOKMARK(
        props.contentTypeId,
        item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data.statusCode == "STCS002" || response.data == "") {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "fail",
            isFailed: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const addComment = (comment) => {
    if (
      comment !==
        `<p class="editor-paragraph"></p><p class="editor-paragraph"><br></p>` &&
      comment !== `<p class="editor-paragraph"></p>` &&
      comment !== `<p class="editor-paragraph"><br></p>`
    ) {
      const payload = {
        pinMetaDataId: item?.pinDetail?.pinMetaDataId,
        message: utf8ToBase64(comment),
        parentCommentId: null,
        metaDataId: item?.metaDataId,
      };
      setAddingComment(true);
      postData(
        payload,
        APIEndPoints.ADD_BB_COMMENT(
          item?.contentTypeId,
          item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
            ? true
            : false,
          userInfo?.teams?.companyId
        )
      )
        .then((res) => {
          // setParentCommentId(res?.data?.parentCommentId);
          setAddingComment(false);
          setComments((prev) =>
            prev.concat(
              res?.data
              //     {
              //     createdByName: userInfo.displayName !== undefined ? userInfo.displayName : userInfo.email,
              //     createdByEmail: userInfo.email,
              //     createdOn: "Just now",
              //     isDeleted: false,
              //     pinMetaDataId: item?.pinDetail?.pinMetaDataId,
              //     comment: comment
              // }
            )
          );

          setIsCommentsOpen(true);

          TrackEvent(TelemetryEventNames.UCC_BB_REPLY_EVENT, userInfo, {
            SectionName:
              item?.contentType == 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: item.contentTypeId?.toString() ?? "",
            ContentTypeName: getContentTypeNamesById2(item.contentTypeId) ?? "",
            MetadataId: item.metaDataId?.toString(),
            UniqueId: item.contentUniqueId ?? "",
            Title: item.title ?? "",
            PublishDate: item.publishedDate ?? "",
          });
        })
        .catch((err) => {
          setAddingComment(false);
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: err?.response?.data?.Errors,
            type: "fail",
            isFailed: false,
          });
        });
    }
  };

  const editComment = (comment, i, changeEditLoader) => {
    if (
      comment !==
        `<p class="editor-paragraph"></p><p class="editor-paragraph"><br></p>` &&
      comment !== `<p class="editor-paragraph"></p>` &&
      comment !== `<p class="editor-paragraph"><br></p>`
    ) {
      const payload = {
        pinMetaDataId: item?.pinDetail?.pinMetaDataId,
        message: utf8ToBase64(comment),
        parentCommentId: null,
        metaDataId: item?.metaDataId,
        pinMetaDataCommentsId: i?.pinMetaDataCommentsId,
      };
      postData(
        payload,
        APIEndPoints.UPDATE_BB_COMMENT(
          item?.contentTypeId,
          item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
            ? true
            : false,
          userInfo?.teams?.companyId
        )
      )
        .then((res) => {
          const index = findIndex(comments, {
            pinMetaDataId: item?.pinDetail?.pinMetaDataId,
            pinMetaDataCommentsId: i?.pinMetaDataCommentsId,
          });

          if (index !== -1) {
            const newArray = [...comments];
            newArray[index] = res?.data;
            setComments(newArray);
          }

          changeEditLoader(false);
        })
        .catch((err) => {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: err?.response?.data?.Errors,
            type: "fail",
            isFailed: false,
          });
          changeEditLoader(false);
        });
    }
  };

  const deleteUndoComment = async (comment, isDeleted) => {
    const allComments = comments;
    const updatedArray = allComments.map((item) =>
      item?.pinMetaDataCommentsId === comment?.pinMetaDataCommentsId
        ? { ...item, isDeleted }
        : item
    );

    let payload = {
      pinMetaDataCommentId: comment?.pinMetaDataCommentsId,
      pinMetaDataId: comment?.pinMetaDataId,
      parentCommentId: comment?.parentCommentId,
      isDeleted: isDeleted,
      metaDataId: item?.metaDataId,
    };
    return await postData(
      payload,
      APIEndPoints.DELETE_UNDO_BB_COMMENT(
        item?.contentTypeId,
        item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((res) => {
        setComments(updatedArray);
        TrackEvent(
          isDeleted
            ? TelemetryEventNames.UCC_BB_DELETE_REPLY_EVENT
            : TelemetryEventNames.UCC_BB_UNDO_DELETE_REPLY_EVENT,
          userInfo,
          {
            SectionName:
              item?.contentType == 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: item.contentTypeId?.toString() ?? "",
            ContentTypeName: getContentTypeNamesById2(item.contentTypeId) ?? "",
            MetadataId: item.metaDataId?.toString(),
            UniqueId: item.contentUniqueId ?? "",
            Title: item.title ?? "",
            PublishDate: item.publishedDate ?? "",
            DeleteStatus: isDeleted ? "Deleted" : "Undo Delete",
          }
        );
        return true;
      })
      .catch((err) => {
        setComments(allComments);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
        return false;
      });
  };

  const handleEvent = (item) => {
    if (item?.contentInfo?.[0]?.contentSourceType === Constant.OTHERS) {
      if (!trackedItemsRef.current.has(item.contentUniqueId)) {
        TrackEvent(
          TelemetryEventNames.UCC_CONTENT_VIEW_DETAILS_PAGE_EVENT,
          userInfo,
          {
            SectionName:
              props.contentTypeId === 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: props.contentTypeId?.toString() ?? "",
            ContentTypeName:
              getContentTypeNamesById2(props.contentTypeId) ?? "",
            MetadataId: item.metaDataId?.toString() ?? "",
            UniqueId: item?.contentUniqueId ?? "",
            Title: item?.title ?? "",
            CategoryName: item?.category[0]?.categoryName ?? "",
            PublishedDate: item?.publishedDate?.toString() ?? "",
          }
        );
        trackedItemsRef.current.add(item.contentUniqueId);
      }
    }
  };

  const fetchComments = (setFetching = true) => {
    setFetchingComments(setFetching);
    getData(
      APIEndPoints.GET_BB_COMMENTS(
        item?.contentTypeId,
        item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId,
        item?.pinDetail?.pinMetaDataId
      )
    )
      .then((res) => {
        setComments(res.data);
        setFetchingComments(false);
        setFetching && setIsCommentsOpen(res.data.length === 1);
      })
      .catch(() => {
        setFetchingComments(false);
      });
  };

  useEffect(() => {
    getUrlParams("backButton") ? getDataFromCache() : getDataFromAPI();
    if (scrolledCardId === item?.pinDetail?.pinMetaDataId) {
      postData(
        {},
        APIEndPoints.CHECK_IF_CONTENT_EXIST(
          props?.contentTypeId,
          item?.metaDataId,
          userInfo?.teams?.companyId
        )
      )
        .then((itemsDetails) => {
          if (itemsDetails?.data?.statusCode == "STCS002") {
            handleUnpin(item?.metaDataId, props.contentTypeId);
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.ContentNotAvailable,
              type: "Fail",
              isFailed: false,
            });
          }
        })
        .catch((error) => {
          handleUnpin(item?.metaDataId, props.contentTypeId);
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "Fail",
            isFailed: false,
          });
        });
      setTimeout(() => {
        setCurrentCardClicked(true);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (
        instance?.getActiveAccount() &&
        instance?.getAllAccounts()?.length > 0
      ) {
        setLocalStorage();
      }
    };
  }, [comments, isCommentsOpen, showEditor, addingComment]);

  const setLocalStorage = () => {
    comments.length > 0 &&
      localStorage.setItem(
        "bulletinComments" + item?.pinDetail?.pinMetaDataId,
        JSON.stringify({
          comments: comments,
          isCommentsOpen: isCommentsOpen,
          showEditor: showEditor,
        })
      );
  };

  const getDataFromCache = () => {
    // getting data from cache
    let dataItem = JSON.parse(
      localStorage.getItem("bulletinComments" + item?.pinDetail?.pinMetaDataId)
    );

    if (!dataItem) {
      //if data is not present in cache, we are calling api
      getDataFromAPI();
      return;
    }
    setComments(dataItem?.comments);
    setIsCommentsOpen(dataItem?.isCommentsOpen);
    setShowEditor(dataItem?.showEditor);
    localStorage.removeItem(
      "bulletinComments" + item?.pinDetail?.pinMetaDataId
    );
    getDataFromAPI(false);
  };

  const getDataFromAPI = (setFetching = true) => {
    if (item?.pinDetail?.commentDetails?.commentCount > 0) {
      fetchComments(setFetching);
    }
    localStorage.removeItem(
      "bulletinComments" + item?.pinDetail?.pinMetaDataId
    );
  };

  const updateCardInfo = (updatedCardData, id, sectionNames, isVoted) => {
    sectionNames?.map((sectionName) => {
      props?.updateEachSection(updatedCardData, id, sectionName, isVoted);
    });
  };

  let owner =
    userInfo?.email?.toLowerCase() ===
    item?.pinDetail?.createdDetails?.emailAddress?.toLowerCase();
  return (
    <>
      {showDivider && (
        <Divider
          content={getDateFromUTC2(item?.pinDetail?.createdDetails?.createdOn)}
          important
          style={{ fontSize: "11px", fontWeight: "600" }}
        />
      )}
      <div className="bulletinCardContainer">
        <div className="bulletinCard">
          <div className="bulletinCard__avatar">
            <img
              className="bulletinCard__avatar_img"
              src={ImageUtil(item?.pinDetail?.createdDetails?.displayName)}
              alt=""
            />
          </div>
          <div
            className={`bulletinCard__body ${
              currentCardClicked ? "element-with-shadow" : ""
            }`}
          >
            <div
              className={`bulletinCard__card ${
                userInfo?.email?.toLowerCase() ===
                item?.pinDetail?.createdDetails?.emailAddress?.toLowerCase()
                  ? "my_pinnedCard"
                  : ""
              }`}
            >
              <div className="bulletinCard__card_header">
                <div className="bulletinCard__card__pinnedByAndDate">
                  <div className="bulletinCard__card_pinnedBy bulletinCard__card_pinnedByImg">
                    <img
                      className="bulletinCard__avatar_img"
                      src={ImageUtil(
                        item?.pinDetail?.createdDetails?.displayName
                      )}
                      alt=""
                    />
                  </div>
                  <div className="bulletinCard__card_pinnedBy">
                    {item?.pinDetail?.createdDetails?.displayName}
                  </div>
                  <div className="bulletinCard__card_dateTime">
                    {getDateFromUTC(item?.customData?.pinInfo?.pinnedDate)}
                  </div>
                </div>
                {/* {owner || config?.adminUnpin?.adminUnpinDisplay ? ( */}
                <>
                  <DialogBox
                    cancelButton={"Cancel"}
                    onCancelMethod={() => {
                      setOpenDialog(false);
                    }}
                    closeOnOutsideClick={false}
                    confirmButton={"Unpin"}
                    onConfirmMethod={unpinCard}
                    content={
                      loader === true ? (
                        <div
                          className={
                            loader && "loaderLayover bulletinBoardLoaderLayover"
                          }
                        >
                          <ReusableLoader
                            isOverlay={true}
                            label={
                              <p>
                                The content will be unpinned for everyone who
                                views the bulletin board.
                              </p>
                            }
                            labelPosition={"start"}
                          />
                        </div>
                      ) : (
                        <p>
                          The content will be unpinned for everyone who views
                          the bulletin board.
                        </p>
                      )
                    }
                    headerName={"Unpin this content?"}
                    headerActionName={{
                      icon: <GrClose />,
                      title: "Close",
                      onClick: () => {
                        setOpenDialog(false);
                      },
                    }}
                    backdrop={true}
                    openDialog={openDialog}
                    className={`dialogDeleteStyles headerStyles ${
                      loader && "dialogLayover"
                    }`}
                  />
                  {/* <Modal isOpen={openDialog} isBlocking={false} className="popupModal">
                                            <div className="modalPopupDelete">
                                                {loader && <ReusableLoader isOverlay={true} className={"loaderOnDelete"} />}
                                                <div className="deletePopupHeader">
                                                    <div className='popupLeftHeader'>
                                                        <p className="popupHeaderTitle">{"Unpin this content?"}</p>
                                                    </div>
                                                    <div>
                                                        <span tabIndex={0} className="closeIconPopup" onClick={props.hideModal} title="Close" onKeyDown={(e) => { if (e.key === "Enter") { setOpenDialog(false); } }}>
                                                            <MdClose onClick={() => { setOpenDialog(false); }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="popupBody"><p>{"The content will be unpinned for everyone who views the bulletin board."}
                                                </p>
                                                </div>
                                                <div className="popupFooter">
                                                    <FooterTechButtons
                                                        disabled={false}
                                                        cancelText={"Cancel"}
                                                        sendText={"Unpin"}
                                                        onClickAddData={unpinCard}
                                                        onClickCancel={() => {
                                                            setOpenDialog(false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Modal> */}

                  {userInfo?.teams?.featureFlagsObj["Bulletin Board"]
                    ?.isEnabled === true && (
                    <div
                      tabIndex={0}
                      aria-label="Pin/UnPin"
                      role="button"
                      className="bulletinCard__card_pinIcon__menu"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (owner || config?.adminUnpin?.adminUnpinEnabled) {
                            setOpenDialog(true);
                          }
                        }
                      }}
                    >
                      {config?.bookmark?.bookmarkDisplay &&
                        (isBookmarkActive ? (
                          <CustomizedToolTip
                            tabIndex={0}
                            normalTooltip={true}
                            position="below"
                            align="center"
                            content={"Bookmark"}
                          >
                            <BsBookmarkFill
                              tabIndex={0}
                              onKeyDown={
                                config.bookmark.bookmarkEnabled
                                  ? handleBookmarkKeyDown
                                  : undefined
                              }
                              onClick={
                                config.bookmark.bookmarkEnabled
                                  ? handleBookmarkClick
                                  : undefined
                              }
                              className={`ui-bookmark-icon ${
                                config.bookmark.bookmarkEnabled
                                  ? "ui-bookmark-fill"
                                  : "ui-bookmark-disable-fill"
                              } ${
                                props?.item?.contentInfo?.[0]
                                  ?.contentSourceType == Constant.OTHERS
                                  ? "ui-pin-icon-cursor"
                                  : ""
                              }`}
                            />
                          </CustomizedToolTip>
                        ) : (
                          <CustomizedToolTip
                            tabIndex={0}
                            normalTooltip={true}
                            position="below"
                            align="center"
                            content={"Bookmark"}
                          >
                            <BsBookmark
                              tabIndex={0}
                              onKeyDown={
                                config.bookmark.bookmarkEnabled
                                  ? handleBookmarkKeyDown
                                  : undefined
                              }
                              onClick={
                                config.bookmark.bookmarkEnabled
                                  ? handleBookmarkClick
                                  : undefined
                              }
                              className={` ${
                                config.bookmark.bookmarkEnabled
                                  ? "ui-bookmark-icon"
                                  : "ui-bookmark-disable-icon"
                              } ${
                                props?.item?.contentInfo?.[0]
                                  ?.contentSourceType == Constant.OTHERS
                                  ? "ui-pin-icon-cursor"
                                  : ""
                              }`}
                            />
                          </CustomizedToolTip>
                        ))}

                      {(owner || config?.adminUnpin?.adminUnpinDisplay) && (
                        <CustomizedToolTip
                          tabIndex={0}
                          normalTooltip={true}
                          position="below"
                          align="center"
                          content={"Pinned"}
                        >
                          <BsPinAngleFill
                            onClick={
                              owner || config?.adminUnpin?.adminUnpinEnabled
                                ? () => setOpenDialog(true)
                                : undefined
                            }
                            className={`ui-pin-icon ${
                              owner || config?.adminUnpin?.adminUnpinEnabled
                                ? "ui-pin-fill"
                                : "ui-pin-disable-fill"
                            } bulletinCard__card_pinIcon`}
                          />
                        </CustomizedToolTip>
                      )}

                      {item?.contentSecurity?.availability?.domainLevel ==
                        Constant.LOCAL && (
                        <CustomizedToolTip
                          tabIndex={0}
                          normalTooltip={true}
                          position="below"
                          align="center"
                          content={userInfo?.teams?.companyName}
                        >
                          <Image
                            alt=""
                            fit="cover"
                            width={21}
                            height={21}
                            src={`${
                              userInfo?.channelLogo !== ""
                                ? userInfo?.channelLogo
                                : require("../../../assets/Default_Channelogo.png")
                            }`}
                          />
                        </CustomizedToolTip>
                      )}
                    </div>
                  )}
                </>
                {/* // ) : (
                //   <></>
                // )} */}
              </div>
              <div
                className="bulletinCard__card__cardwrapper"
                onMouseEnter={() => handleEvent(item)} //Track desktop mouse hover
                onTouchStart={() => handleEvent(item)} //Track mobile taps
              >
                <CardExampleSize
                  key={item.metaDataId}
                  id={item.metaDataId}
                  item={item}
                  config={config}
                  isImage={true}
                  contentType={props.contentTypeId}
                  //   pathname={props.id}
                  onClick={onClick}
                  fixHeight={true}
                  showMoreButton={true}
                  //   handleReload={handleReload}
                  //   contentTypeName={props?.name}
                  //   section={cardFetchDetails.new.subContentType}
                  contentSource={item?.contentSource}
                  bulletinBoard={true}
                  {...props}
                  updateCardData={updateCardInfo}
                  isPolls={true}
                  isBulletinBoard={props?.isBulletinBoard}
                />
              </div>
            </div>
            <div className="bulletinCard__replies">
              {
                <Comments
                  deleteUndoComment={deleteUndoComment}
                  addingComment={addingComment}
                  fetchingComments={fetchingComments}
                  comments={comments}
                  handleToggle={handleToggle}
                  isCommentsOpen={isCommentsOpen}
                  key={item.metaDataId}
                  config={config}
                  editComment={editComment}
                  contentTypeId={item?.contentTypeId}
                  isGlobal={item?.contentSecurity?.availability?.domainLevel}
                />
              }
            </div>
            {config?.createReply?.createReplyDisplay && (
              <div className="bulletinCard__textEditor" key={item.metaDataId}>
                <>
                  {!showEditor ? (
                    <div
                      tabIndex={0}
                      className="reply-btn"
                      style={
                        config?.createReply?.createReplyEnabled
                          ? {}
                          : { cursor: "text", color: "gray" }
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            config &&
                            config.createReply &&
                            config.createReply.createReplyEnabled
                          ) {
                            setShowEditor(function (prev) {
                              return !prev;
                            });
                          }
                          e.preventDefault();
                        }
                      }}
                      onClick={
                        config?.createReply?.createReplyEnabled
                          ? () => {
                              setShowEditor((prev) => !prev);
                            }
                          : undefined
                      }
                    >
                      <ReplyIcon style={{ width: "17px" }} />
                      &nbsp; Reply
                    </div>
                  ) : (
                    <TextEditor addComment={addComment} />
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BulletinCard;
