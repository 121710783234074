import React from "react";
import { CiCircleInfo } from "react-icons/ci";
import "./Label.css";

function Label(props) {
  return (
    <div className="formLabelStyles">
      <label>{props?.content}</label>
      {props?.displayField && <label>{`: ${props?.displayText}`}</label>}
      {props?.checkIfRequired && <span className="mandatoryField">*</span>}
      {props?.checkForAdditionalInfo && (
        <span>
          <CiCircleInfo className="additionalInfoIcon" />
        </span>
      )}
      {props?.checkForAdditionalText && (
        <span className={props?.className}>
          {props?.checkForAdditionalText}
        </span>
      )}
    </div>
  );
}

export default Label;
