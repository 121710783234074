import React from "react";
import { MenuButton as MenuButtonComponent } from "@fluentui/react-northstar";
import { v4 as uuidv4 } from "uuid";

function MenuButton(props) {
  const uniqueId = "ui-flex-button" + uuidv4();
  return (
    <MenuButtonComponent
      tabIndex={0}
      trigger={props?.trigger}
      menu={props.menu}
      className={props?.className}
      id={uniqueId}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          document
            .getElementById(uniqueId)
            .querySelector("svg")
            .dispatchEvent(event);
        }
      }}
    />
  );
}

export default MenuButton;
