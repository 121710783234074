import React, { useEffect, useState } from "react";
import { app } from "@microsoft/teams-js";
import { MdClose } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import ButtonPrimary from "../button/buttonPrimary";
import Button from "../button/button";
import { checkIfiOS } from "../../utility/utility";
import DialogBox from "../dialogBox/DialogBox";
import { checkIfDeviceIsMob } from "../../utility/utility";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import "./popup.css";

function PopupBox(props) {
  let deviceCheck = checkIfDeviceIsMob();
  const { userInfo } = UseUserInfoContext();
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);

  useEffect(() => {
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  return (
    <DialogBox
      closeOnOutsideClick={false}
      onConfirmMethod={() => {}}
      content={
        <div
          className={`popupContainer ${checkIfiOS() ? "iOSClass" : ""}`}
          style={
            window.innerWidth < 500 && props.needCustomStyle
              ? { width: window.innerWidth }
              : {}
          }
        >
          <div
            className={`topContent ${props.isBorderNeeded && "topborder"}`}
            style={
              window.innerWidth < 500 && props.needCustomStyle
                ? { width: window.innerWidth }
                : {}
            }
          >
            <div className="popupHeader">
              <span>{props.title}</span>
              {props?.hideCloseIcon === true ? (
                <> </>
              ) : (
                <span
                  tabIndex={0}
                  className="closeIconPopup"
                  onClick={props.hideModal}
                  title="Close"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.hideModal();
                    }
                  }}
                >
                  <MdClose />
                </span>
              )}
            </div>
          </div>
          <div className="popupBody">{props.children}</div>
          <>
            {props.isFooter ? (
              <>
                {" "}
                <div
                  className={`popupFooter ${
                    checkIfiOS() ? "iOSClass-footer" : ""
                  }`}
                >
                  <div className="btncontainer">
                    {props?.userManagement ||
                    (props?.isOpenInTeams &&
                      (userInfo.loggedInWeb === true ||
                        loggedInM365Disable)) ? (
                      ""
                    ) : (
                      <ButtonPrimary
                        text={`${
                          props?.sendButtonText ? props?.sendButtonText : "Send"
                        }`}
                        onClick={props.onClick}
                        disabled={props.sendButtonDisabled}
                      />
                    )}
                    <Button
                      text={`${
                        props?.cancelButtonText
                          ? props?.cancelButtonText
                          : "Cancel"
                      }`}
                      onClick={props.hideModal}
                    />
                  </div>
                  {props?.isOpenInTeams && (
                    <button
                      type="button"
                      onClick={props.onClickOpenInTeams}
                      className="openInTeams"
                      // disabled={props.teamsButtonDisabled}
                    >
                      <GoLinkExternal />
                      &nbsp;{" "}
                      {userInfo.loggedInWeb === true || loggedInM365Disable
                        ? "Open in Outlook Calendar"
                        : "Open in Teams"}
                    </button>
                  )}
                  {props?.isOpenInOutlook && (
                    <button
                      type="button"
                      onClick={props.onClickOpenInOutlook}
                      className="openInTeams"
                      // disabled={props.sendButtonDisabled}
                    >
                      <GoLinkExternal />
                      &nbsp; Open in Outlook
                    </button>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      }
      // headerName={props?.title}
      // headerActionName={!props?.hideCloseIcon ? {
      //   icon:  <MdClose />,
      //   title: 'Close',
      //   onClick: () =>  props.hideModal(),
      // }:{}}
      backdrop={true}
      openDialog={props.isModalOpen}
      className={`dialogStyles yelloBtn ${props?.className}`}
      onCancel={props.hideModal}
    />
  );
}

export default PopupBox;
