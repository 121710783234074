import React, { useState, useEffect } from "react";
import { Attachment as ProgressAttachment } from "@fluentui/react-northstar";
import Dropzone from "react-dropzone";
import { MdCloudUpload } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import { formatBytes } from "../../../utility/utility";
import UploadData from "../../../commonui/uploaddata/UploadData";
import "./templatecontrol.css";

const TemplateControl = (props) => {
  const [file, setDropFile] = useState(props?.files?.files);
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const [isDropzoneDisabed, setIsDropzoneDisabed] = useState(false);
  const allValidations = props?.validations;

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    if (props.editMode == true) {
      setDropFile(props?.files?.files);
      props.updateValidationState(
        props?.name,
        props?.files?.files,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    }
    if (props?.disabled == true) {
      setIsDropzoneDisabed(true);
    } else if (props?.files?.files) {
      setIsDropzoneDisabed(true);
    } else {
      setIsDropzoneDisabed(false);
    }
  }, [props?.files?.files, props?.disabled]);

  useEffect(() => {
    setDropFile(props?.files?.files);
    props.updateValidationState(
      props?.name,
      props?.files?.files,
      props?.hasAnyError,
      props?.errMessage
    );
    if (props?.disabled == true) {
      setIsDropzoneDisabed(true);
    } else if (props?.files?.files == undefined) {
      setIsDropzoneDisabed(false);
    }
  }, [props?.files?.files, props?.disabled]);

  const handleDropFile = (file) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        file[0]
      );
      setError(hasError);
      setErrorMessage(errMessage);
      if (!hasError) {
        setIsDropzoneDisabed(true);
        setDropFile(file[0]);
        props?.onDrop(
          file[0],
          props?.fieldsName,
          props?.name,
          props?.isCustomField,
          props?.isContentField,
          props?.parentName,
          hasError,
          errMessage
        );
      } else {
        props?.onDrop(
          null,
          props?.fieldsName,
          props?.name,
          props?.isCustomField,
          props?.isContentField,
          props?.parentName,
          hasError,
          errMessage
        );
      }
    } else {
      setIsDropzoneDisabed(true);
      setDropFile(file[0]);
      if (props.onDrop) {
        props?.onDrop(
          file[0],
          props?.fieldsName,
          props?.name,
          props?.isCustomField,
          props?.isContentField,
          props?.parentName,
          false,
          ""
        );
      }
    }
  };

  const removeDropFile = () => {
    setIsDropzoneDisabed(false);
    setDropFile({});
    if (props.onDrop) {
      props?.onDrop(
        null,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.isContentField,
        props?.parentName,
        false,
        ""
      );
    }
  };

  const dropzoneContent = () => {
    return (
      <div>
        <MdCloudUpload className="UploadButton" id={props?.id} />
        <p>
          {props?.placeholder}{" "}
          <span
            className={isDropzoneDisabed === true ? "BrowseDisabled" : "Browse"}
          >
            browse
          </span>
        </p>
        <p>{props?.placeholderSubText}</p>
      </div>
    );
  };

  return (
    <>
      <div
        className={`DropzoneContainer ${hasAnyError ? "drpzone_error" : ""}`}
      >
        <UploadData
          multiple={false}
          isDropzoneEnabled={!isDropzoneDisabed}
          dropzoneContent={dropzoneContent}
          handleDropFile={handleDropFile}
          rootProps={props?.rootProps}
        />
        <div>
          {props?.additionalMessage !== "" && (
            <span className="additionalMessage">
              {props?.additionalMessage}
            </span>
          )}
        </div>
        <div>
          {hasAnyError && (
            <>
              <MdError color="rgb(196, 49, 75)" />
              <span className="errMessage">{errorMessage}</span>
            </>
          )}
        </div>
        {(file?.actualFileName !== undefined || file?.name !== undefined) && (
          <div className="templateAttachmentClass">
            <ProgressAttachment
              header={
                file?.actualFileName != undefined
                  ? file?.actualFileName + "." + file?.fileType
                  : file?.name
              }
              actionable
              action={{
                icon: <GrClose />,
                onClick: () => removeDropFile(),
                title: props?.actionTitle,
              }}
              description={
                file?.actualFileName != undefined
                  ? formatBytes(file?.fileSize * 1024)
                  : formatBytes(file?.size)
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateControl;
