import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@fluentui/react-northstar";
import { ImageUtil } from "../../utility/utility";
import { AiOutlineClose } from "react-icons/ai";
import { Button, Flex } from '@fluentui/react-northstar';
import { LiaUserEditSolid } from "react-icons/lia";
import { GrClose } from "react-icons/gr";
import { postData } from "../../services/customApis";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import ManageAccesscard from "../../commonui/manageAccessCard/manageAccesscard";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import FooterTechButtons from "../../commonui/commonformcomponents/footertechbuttons/FooterTechButtons";  
import Constant from "../../utility/constants";
import { UseManageAccessContext } from "../../context/manageaccesscontext/manageAccessContext";  
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";  
import APIEndPoints from "../../utility/apiendpoints";  
import "./membersDropdown.css";

const MembersDropdown = (props) => {
  const { allOptions, shouldAdjustPosition, ishasAccess, usersList, metaDataId, setIsOpen } = props;  
  const [filterText, setFilterText] = useState("");
  const [openManageAccessDialog, setOpenManageAccessDialog] = useState(false);
  const [options, setOptions] = useState([]);
  let { userInfo } = UseUserInfoContext();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState(5);
  const [searchText, setSearchText] = useState("");  
  const containerRef = useRef(null);
  const [dropdownClass, setDropdownClass] = useState("");
  const isCustomVisualizationHeader = props?.visualizatioManageAccess;
  const [fileIDManageAccess, setFileIDManageAccess] = useState(0);
  const [loaderManageAccess, setLoaderManageAccess] = useState(false);
  const { accessData } = UseManageAccessContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  useEffect(() => {
    const handleScroll = () => {
      const dropdownOptions = document?.getElementById("mdropdown-options");
      if (
        dropdownOptions.scrollHeight - dropdownOptions.scrollTop <=
        dropdownOptions.clientHeight + 10
      ) {
        if (allOptions.length > loadedOptions) {
          setLoading(true);
          setTimeout(() => {
            setLoadedOptions((prevLoadedOptions) => prevLoadedOptions + 5);
            setLoading(false);
          }, 1000);
        }
      }
    };

    document
      ?.getElementById("mdropdown-options")
      ?.addEventListener("scroll", handleScroll);
    return () =>
      document
        ?.getElementById("mdropdown-options")
        ?.removeEventListener("scroll", handleScroll);
  }, [loadedOptions, options]);

  useEffect(() => {
    setOptions(
      allOptions
        ?.filter((option) =>
          filterText.length > 0
            ? option?.name?.toLowerCase().includes(filterText?.toLowerCase())
            : true
        )
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.slice(0, loadedOptions)
    );
  }, [loadedOptions, filterText, allOptions]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 2 || e.target.value.length == 0) {
      if (filterText.length < 2) {
        setLoadingText(true);
        setLoading(true);
        setTimeout(() => {
          setFilterText(e.target.value);
          setLoadingFalse();
        }, 1000);
      } else {
        setFilterText(e.target.value);
      }
    } else if (e.target.value.length == 1 || e.target.value.length == 2) {
      setFilterText("");
    }
  };

  const clearSearch = (e = null) => {
    e?.stopPropagation();
    setLoadingFalse();
    setSearchText("");
    setFilterText("");
  };

  const setLoadingFalse = () => {
    setLoadingText(false);
    setLoading(false);
  };

  useEffect(() => {
    if (shouldAdjustPosition) {
      const adjustDropdownPosition = () => {
        const container = containerRef.current;
        const detailsHeader = document.querySelector('.ms-DetailsHeader');
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const screenHeight = window.innerHeight;
          let detailsHeaderRect;
          if(ishasAccess == true){
            detailsHeaderRect = detailsHeader?.getBoundingClientRect();
          }
          if (containerRect.bottom > screenHeight) {
            container.style.position = "absolute";
            container.style.top = `-${containerRect.height}px`;
            setDropdownClass("dropdown-top");
  
            // Check if the dropdown touches the ms-DetailsHeader class div
            if (detailsHeaderRect && containerRect.top - containerRect.height < detailsHeaderRect.bottom) {
              container.style.top = "initial";
              container.style.bottom = "-203";
              setDropdownClass("dropdown-bottom");
            }
          }
        }
      };
      adjustDropdownPosition();
    }
  }, [options, shouldAdjustPosition]);
  const handleManageAccess = async (obj) => {
    setOpenManageAccessDialog(true);
    setFileIDManageAccess(metaDataId);
  };

  const onClickEditPermissions = async () => {
    setLoaderManageAccess(true);
    let payload = {
      fileId: fileIDManageAccess,
      manageAccessType: accessData?.manageAccessType,
      dataTypeId: 3,
      fileTypeId: 1,
      userDetails: accessData?.userDetails,
    };
    await postData(
      payload,
      APIEndPoints.UPDATE_VISUALIZATION_ACCESS(userInfo?.teams?.companyId)
    )
      .then((response) => {
        setLoaderManageAccess(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg:
            response?.status == 200
              ? "Permissions updated successfully."
              : Constant.SOMETHING_WENTWRONG,
          type: response?.status == 200 ? "Success" : "Fail",
          isFailed: false,
        });
        setOpenManageAccessDialog(false);
        props.getVisualizationData();
        setIsOpen(false);
      })
      .catch((err) => {
        setLoaderManageAccess(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  return (
    <>
      <div className={`member-dropdown ${dropdownClass}`}>      
        <div className="member-dropdown-body"  ref={containerRef}>
          <div className="msearch-container" style={{}}>
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              spellCheck={false}
            />
            {searchText && (
              <AiOutlineClose
                className="mclear-search-icon"
                onClick={(e) => clearSearch(e)}
              />
            )}
          </div>
          {searchText.length < 3 && searchText.length > 0 && (
            <div className="mHintMsg">{Constant.HINT_MESSAGE}</div>
          )}
          {searchText.length > 2 && options.length < 1 && (
            <div className="mHintMsg">{Constant.NO_RESULT_MESSAGE}</div>
          )}
          {options?.length > 0 && (
            <div
              className="mdropdown-options"
              id="mdropdown-options"
              style={{}}
            >
              {!loadingText && (
                <>
                  {" "}
                  {options?.map((option) => (
                    <span className="membername_and_avatar">
                      <img
                        className="users-avatar-mdropdown"
                        src={ImageUtil(option.name)}
                        alt=""
                      />
                      &nbsp;
                      <div key={option.name} className={`mdropdown-option`}>
                        {option.name}
                      </div>
                    </span>
                  ))}
                </>
              )}
              {loading && (
                <div className={`mdropdown-loader`}>
                  <Loader />
                </div>
              )}
              {isCustomVisualizationHeader && 
              <>
              <Button 
              icon={<LiaUserEditSolid />} 
              className="visualManageAccessButton" 
              content="Manage access" 
              iconPosition="before" 
              onClick={() => handleManageAccess(usersList)}
              />
              </>
              }
            </div>
          )}
        </div>
      </div>    
      {
        <DialogBox
          openDialog={openManageAccessDialog}
          headerName={"Manage Access"}
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            onClick: (e) => {
              e.stopPropagation();
              setOpenManageAccessDialog(false);
              setIsOpen(false);
            },
          }}
          content={
            <div onClick={(e) => e.stopPropagation()}>
              <ManageAccesscard
                isVizualizationLibrary={openManageAccessDialog}
                loaderManageAccess={loaderManageAccess}
                fileIDManageAccess={fileIDManageAccess}
                setOpenManageAccessDialog={setOpenManageAccessDialog}
                isPopUpManageAccess={true}
                metaDataId={metaDataId}
              />
            </div>
          }
          className="manageAccessDialog"
          closeOnOutsideClick={false} 
          footer={
            <div onClick={(e) => e.stopPropagation()}>
              <FooterTechButtons
                cancelText={"Cancel"}
                sendText={"Submit"}
                onClickAddData={onClickEditPermissions}
                onClickCancel={() => {
                  setOpenManageAccessDialog(false);
                  setIsOpen(false);
                }}
              />
            </div>
          }
        />
      }
    </>
  );
};

export default MembersDropdown;