import React, { useState, useEffect, useRef } from "react";
import { MdFilterList } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import { Popup, Input } from "@fluentui/react-northstar";
import { Checkbox } from "@fluentui/react-northstar";
import { Loader } from "@fluentui/react-northstar";
import Constant from "../../utility/constants";
import FooterTechButtons from "../commonformcomponents/footertechbuttons/FooterTechButtons";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { postData } from "../../services/customApis";
import DialogBox from "../dialogBox/DialogBox";
import { UseLibrarySearchFormContext } from "../../context/librarysearchformcontext/librarySearchFormContext";
import { utils } from "../datagrid/utils";
import "./searchAndFilterColumn.css";

const PAGE_SIZE =
  Constant.INTERNAL_APPS_GENERAL_CONSTANTS.FIELD_LEVEL_PAGE_SIZE;

export function SearchAndFilter(props) {
  let { userInfo } = UseUserInfoContext();
  let checkResponsePropertyName = props?.appDetails?.headerDataResponse?.filter(
    (item) => item?.fieldUniqueId === props?.fieldName
  );
  let ResponsePropertyName =
    checkResponsePropertyName?.length > 0
      ? checkResponsePropertyName[0]?.responsePropertyInfo?.responsePropertyName
      : props?.columnName;
  const [open, setOpen] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState("");
  const [noRecordsFound, setNoRecordsFound] = useState("");
  //const [filteredColumnResults,setFilteredColumnResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState(PAGE_SIZE);
  const [loadedOptionsSearch, setLoadedOptionsSearch] = useState(PAGE_SIZE);
  const [loadedPageInfo, setLoadedPageInfo] = useState({
    pageLength: PAGE_SIZE,
    pageNumber: 1,
  });
  const [loadedPageInfoSearch, setLoadedPageInfoSearch] = useState({
    pageLength: PAGE_SIZE,
    pageNumber: 1,
  });
  const [pageInfo, setPageInfo] = useState({});
  const [pageInfoSearch, setPageInfoSearch] = useState({});
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();

  const methodToReturnStatus = (item) => {
    if (
      props?.pageRoute?.toLowerCase() ===
      Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.DATABASE.toLowerCase()
    ) {
      switch (item) {
        case "1":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.NEW;
        case "2":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.INPROGRESS;
        case "3":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.PROCESSED;
        case "4":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.PROMPTED;
        case "5":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.COMPLETED;
        case "6":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.SKIPPED;
        case "7":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.FAILED;
        case "8":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS
            .BULKPUSHINPROGRESS;
        case "9":
          return Constant.DATABASE_GRID_STATUS_COLUMN_CONSTANTS.BULKPUSHFAILED;
        default:
          return item;
      }
    } else if (
      props?.pageRoute?.toLowerCase() ===
      Constant.LIBRARY_GRID_STATUS_COLUMN_CONSTANTS.LIBRARY.toLowerCase()
    ) {
      switch (item) {
        case "1":
          return Constant.LIBRARY_GRID_STATUS_COLUMN_CONSTANTS.PROMPTED;
        case "2":
          return Constant.LIBRARY_GRID_STATUS_COLUMN_CONSTANTS.PUBLISHED;
        default:
          return item;
      }
    }
  };
  const initializeFilterColumnResultsApplied = () => {
    if (
      props?.searchFilters.hasOwnProperty(props?.fieldName) &&
      props?.searchFilters[props?.fieldName]?.length > 0
    ) {
      const previouslySelectedItems = props?.searchFilters[
        props?.fieldName
      ].map((item) => {
        return {
          name: item,
          key: item,
          label:
            ResponsePropertyName?.toLowerCase() !==
            Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
              ? item.length > 25
                ? item.substring(0, 25) + "..."
                : item
              : methodToReturnStatus(item),
          selected: true,
        };
      });
      return previouslySelectedItems;
    }
    return [];
  };
  const [filteredColumnResultsApplied, setFilteredColumnResultsApplied] =
    useState(initializeFilterColumnResultsApplied);
  const [
    filteredColumnResultsApplied_copy,
    setFilteredColumnResultsApplied_copy,
  ] = useState([]);
  const [
    filteredColumnResultsApplied_Filter,
    setFilteredColumnResultsApplied_Filter,
  ] = useState(filteredColumnResultsApplied);
  const [filterApplied, setFilterApplied] = useState(
    filteredColumnResultsApplied?.length > 0 ? true : false
  );
  const filterIconClick = useRef(false);
  const textToBeSearched = useRef("");
  //const [collectAllResponseValues, setCollectAllResponseValues] = useState (false);
  useEffect(() => {
    if (!open) {
      setTextBoxValue("");
      if (filterApplied) {
        setFilteredColumnResultsApplied(filteredColumnResultsApplied_Filter);
      } else {
        setFilteredColumnResultsApplied(filteredColumnResultsApplied_copy);
      }
    }
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      if (!loading && open) {
        const dropdownOptions = document?.getElementById(
          "filteredColumnResultsCheckBox"
        );
        if (
          dropdownOptions.scrollHeight - dropdownOptions.scrollTop <=
          dropdownOptions.clientHeight + 10
        ) {
          if (filterIconClick.current) {
            if (
              pageInfo.totalCount > loadedOptions &&
              pageInfo.totalCount != pageInfo.rowNumber
            ) {
              setLoading(true);
              setTimeout(() => {
                setLoadedOptions(
                  (prevLoadedOptions) => prevLoadedOptions + PAGE_SIZE
                );
                setLoadedPageInfo((prevState) => ({
                  ...prevState,
                  pageNumber: prevState.pageNumber + 1,
                }));
              }, 1000);
            }
          } else {
            if (
              pageInfoSearch.totalCount > loadedOptionsSearch &&
              pageInfoSearch.totalCount != pageInfoSearch.rowNumber
            ) {
              setLoading(true);
              setTimeout(() => {
                setLoadedOptionsSearch(
                  (prevLoadedOptions) => prevLoadedOptions + PAGE_SIZE
                );
                setLoadedPageInfoSearch((prevState) => ({
                  ...prevState,
                  pageNumber: prevState.pageNumber + 1,
                }));
              }, 1000);
            }
          }
        }
      }
    };
    document
      ?.getElementById("filteredColumnResultsCheckBox")
      ?.addEventListener("scroll", handleScroll);
    return () =>
      document
        ?.getElementById("filteredColumnResultsCheckBox")
        ?.removeEventListener("scroll", handleScroll);
  }, [loadedOptions, loadedOptionsSearch, pageInfo, pageInfoSearch, loading]);

  useEffect(() => {
    if (loadedOptions != PAGE_SIZE && open && filterIconClick.current) {
      performSearchOnColumn(false, true);
    }
  }, [loadedOptions]);
  useEffect(() => {
    if (loadedOptionsSearch != PAGE_SIZE && open && !filterIconClick.current) {
      performSearchOnColumn(false, true);
    }
  }, [loadedOptionsSearch]);

  const getUniqueValues = (array1, array2 = []) => {
    const combinedValues = [...array1, ...array2];
    if (combinedValues.length === 0) {
      return [];
    }
    const uniqueValues = combinedValues.filter(
      (value, index, self) =>
        index ===
        self.findIndex((v) => JSON.stringify(v) === JSON.stringify(value))
    );
    return uniqueValues;
  };

  const methodToReset = (onApplyFilter) => {
    const selectedItemscheckBoxes = filteredColumnResultsApplied.filter(
      (item) => item?.selected === true
    );
    const resetFilteredColumnResultsApplied = filteredColumnResultsApplied_copy
      .map((item) => {
        if (
          selectedItemscheckBoxes.filter(
            (filterItem) => filterItem?.name === item?.name
          ).length < 1
        ) {
          //const dynamicDataObjectFilter =
          return {
            name: item?.name,
            key: item?.key,
            label: item?.label,
            selected: item?.selected,
          };
          //return dynamicDataObjectFilter;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);
    setFilteredColumnResultsApplied([
      ...selectedItemscheckBoxes,
      ...resetFilteredColumnResultsApplied,
    ]);

    if (onApplyFilter)
      setFilteredColumnResultsApplied_Filter([
        ...selectedItemscheckBoxes,
        ...resetFilteredColumnResultsApplied,
      ]);
  };

  const textBoxChange = (event, data) => {
    setTextBoxValue(data?.value);
    //setFilteredColumnResultsApplied(filteredColumnResultsApplied.filter((item)=>item?.selected === true))
    setNoRecordsFound("");
    if (data?.value?.length === 0) {
      filterIconClick.current = true;
      methodToReset(false);
    }
  };
  const clearToApplySearch = () => {
    setFilteredColumnResultsApplied([]);
  };
  const performSearchOnColumn = async (isFromOnClick, isNextPage) => {
    //setNoRecordsFound(Constant.SEARCH_AND_FILTER_COM_CONSTANTS.Please_WAIT);
    if (isFromOnClick && textBoxValue?.length > 2)
      textToBeSearched.current = textBoxValue;
    else textToBeSearched.current = "";
    const existingSelectedValuesTrue = filteredColumnResultsApplied.filter(
      (item) => item?.selected === true
    );
    if (
      textBoxValue?.trim().length > 2 ||
      (isFromOnClick && filterIconClick.current) ||
      isNextPage
    ) {
      if (loading != true) {
        setLoading(() => {
          return true;
        });
      }
      if (isFromOnClick) {
        clearToApplySearch();
      }
      let searchFilters = { ...props.searchFilters };
      const deleteAProp = delete searchFilters[props?.fieldName];
      Object.entries(searchFilters).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          searchFilters[key] = values.map((value) =>
            btoa(unescape(encodeURIComponent(value)))
          );
        }
      });
      let requestJSON = {
        filters: {
          fields: searchFilters,
        },
        pageInfo: {
          pageLength: filterIconClick.current
            ? isFromOnClick
              ? PAGE_SIZE
              : loadedPageInfo.pageLength
            : isFromOnClick
            ? PAGE_SIZE
            : loadedPageInfoSearch.pageLength,
          pageNumber: filterIconClick.current
            ? isFromOnClick
              ? 1
              : loadedPageInfo.pageNumber
            : isFromOnClick
            ? 1
            : loadedPageInfoSearch.pageNumber,
        },
      };
      const columnSearchPayload = props?.appDetails?.appID
        ? {
            templateId: props?.templateDetails?.templateId?.toString() ?? "",
            fieldName: props?.fieldName,
            searchText: btoa(
              unescape(encodeURIComponent(textToBeSearched?.current?.trim()))
            ),
            searchQuery: props?.appSearchQueryValue,
            //searchQuery: "",
            appId: props?.appDetails?.appID,
            requestJson: JSON.stringify(requestJSON),
          }
        : {
            templateId: props?.templateDetails?.templateId?.toString() ?? "",
            fieldName: null,
            searchText: btoa(
              unescape(encodeURIComponent(textToBeSearched?.current?.trim()))
            ),
            searchQuery: props?.appSearchQueryValue,
            //searchQuery: "",
            appId: props?.appDetails?.appID,
            requestJson: JSON.stringify(requestJSON),
          };
      await postData(
        columnSearchPayload,
        APIEndPoints.GET_DATA_LIBRARY_COLUMN_FILTER_DATA(
          props?.appDetails?.contentTypeId,
          userInfo?.teams?.companyId
        )
      )
        .then((filterValuesResponseColumn) => {
          if (filterValuesResponseColumn?.data?.status === "success") {
            let obtainedValues =
              filterValuesResponseColumn?.data?.resultData?.values;
            if (
              props?.fieldType ===
                Constant.INTERNALAPP_CONSTANTS.PROMPT_FIELD_TYPE_DATETIME ||
              props?.fieldType ===
                Constant.INTERNALAPP_CONSTANTS.DATABASE_FIELD_TYPE_DATE
            ) {
              const formattedValues = obtainedValues.map((date) => ({
                id: date,
                value: utils.identifyDateFormat(date),
              }));
              obtainedValues = [
                ...new Set(
                  formattedValues.map((item) => ({
                    id: utils.convertDate(item.id, false),
                    value: item.value,
                  }))
                ),
              ];
            }
            if (obtainedValues?.length < 1) {
              setNoRecordsFound(
                Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND
              );
            } else {
              setNoRecordsFound("");
            }

            if (filterIconClick.current) {
              const existingSelectedValues = filteredColumnResultsApplied.sort(
                (a, b) => {
                  if (a.selected === b.selected) {
                    return 0;
                  }
                  return a.selected ? -1 : 1;
                }
              );
              const receivedFilterValues = obtainedValues.map((item) => {
                if (
                  props?.fieldType ===
                    Constant.INTERNALAPP_CONSTANTS.PROMPT_FIELD_TYPE_DATETIME ||
                  props?.fieldType ===
                    Constant.INTERNALAPP_CONSTANTS.DATABASE_FIELD_TYPE_DATE
                ) {
                  return {
                    name: item.id,
                    key: item.id,
                    label:
                      ResponsePropertyName?.toLowerCase() !==
                      Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
                        ? item.id.length > 25
                          ? item.id.substring(0, 25) + "..."
                          : item.id
                        : methodToReturnStatus(item),
                    selected:
                      existingSelectedValuesTrue.filter(
                        (filterItem) => filterItem?.name === item.id
                      ).length < 1
                        ? false
                        : true,
                    format: item.value,
                  };
                } else {
                  return {
                    name: item,
                    key: item,
                    label:
                      ResponsePropertyName?.toLowerCase() !==
                      Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
                        ? item.length > 25
                          ? item.substring(0, 25) + "..."
                          : item
                        : methodToReturnStatus(item),
                    selected:
                      existingSelectedValuesTrue.filter(
                        (filterItem) => filterItem?.name === item
                      ).length < 1
                        ? false
                        : true,
                  };
                }
              });
              const receivedFilterValuesUnSelected =
                receivedFilterValues.filter((item) => item?.selected === false);
              if (isFromOnClick && filterApplied) {
                const receivedFilterValuesAllFalse = receivedFilterValues.map(
                  (record) => {
                    if (record?.selected === true) {
                      return { ...record, selected: false };
                    }
                    return record;
                  }
                );
                setFilteredColumnResultsApplied_copy(
                  receivedFilterValuesAllFalse
                );
                setFilteredColumnResultsApplied([
                  ...existingSelectedValuesTrue,
                  ...receivedFilterValuesUnSelected,
                ]);
                setFilteredColumnResultsApplied_Filter([
                  ...existingSelectedValuesTrue,
                  ...receivedFilterValuesUnSelected,
                ]);
              } else if (isFromOnClick) {
                setFilteredColumnResultsApplied_copy(receivedFilterValues);
                setFilteredColumnResultsApplied(
                  getUniqueValues(receivedFilterValues)
                );
              } else {
                const resetTrueToFalse = receivedFilterValues.map((record) => {
                  if (record?.selected === true) {
                    return { ...record, selected: false };
                  }
                  return record;
                });
                setFilteredColumnResultsApplied_copy([
                  ...filteredColumnResultsApplied_copy,
                  ...resetTrueToFalse,
                ]);
                setFilteredColumnResultsApplied(
                  getUniqueValues([
                    ...existingSelectedValues,
                    ...receivedFilterValuesUnSelected,
                  ])
                );
                if (filterApplied)
                  setFilteredColumnResultsApplied_Filter([
                    ...existingSelectedValues,
                    ...receivedFilterValuesUnSelected,
                  ]);
              }
              setPageInfo(
                filterValuesResponseColumn?.data?.resultData?.pageInfo
              );
            } else {
              const receivedSearchedValues = obtainedValues
                .map((item) => {
                  if (
                    props.fieldType ===
                      Constant.INTERNALAPP_CONSTANTS.DATABASE_FIELD_TYPE_DATE ||
                    props.fieldType ===
                      Constant.INTERNALAPP_CONSTANTS.PROMPT_FIELD_TYPE_DATETIME
                  ) {
                    return {
                      name: item.id,
                      key: item.id,
                      label:
                        ResponsePropertyName?.toLowerCase() !==
                        Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
                          ? item.id.length > 25
                            ? item.id.substring(0, 25) + "..."
                            : item.id
                          : methodToReturnStatus(item),
                      selected:
                        existingSelectedValuesTrue.filter(
                          (filterItem) => filterItem?.name === item.id
                        ).length < 1
                          ? false
                          : true,
                      format: item.value,
                    };
                  } else {
                    return {
                      name: item,
                      key: item,
                      label:
                        ResponsePropertyName?.toLowerCase() !==
                        Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
                          ? item.length > 25
                            ? item.substring(0, 25) + "..."
                            : item
                          : methodToReturnStatus(item),
                      selected:
                        existingSelectedValuesTrue.filter(
                          (filterItem) => filterItem?.name === item
                        ).length < 1
                          ? false
                          : true,
                    };
                  }
                })
                .filter((item) => item?.selected !== true);
              let sortedExistingSelectedValuesTrue;
              if (existingSelectedValuesTrue?.length > 0) {
                sortedExistingSelectedValuesTrue =
                  existingSelectedValuesTrue.sort((a, b) => {
                    if (a.name === b.name) {
                      return 0;
                    }
                    return a.name ? -1 : 1;
                  });
              }
              if (isFromOnClick) {
                if (sortedExistingSelectedValuesTrue?.length > 0) {
                  setFilteredColumnResultsApplied([
                    ...sortedExistingSelectedValuesTrue,
                    ...receivedSearchedValues,
                  ]);
                } else {
                  setFilteredColumnResultsApplied(receivedSearchedValues);
                }
              } else {
                setFilteredColumnResultsApplied([
                  ...filteredColumnResultsApplied,
                  ...receivedSearchedValues,
                ]);
              }
              setPageInfoSearch(
                filterValuesResponseColumn?.data?.resultData?.pageInfo
              );
            }
            setLoading(false);
            return true;
          } else {
            setNoRecordsFound(
              Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND
            );
            setFilteredColumnResultsApplied(existingSelectedValuesTrue);
            setLoading(false);
          }
        })
        .catch((apiError) => {
          setNoRecordsFound(
            Constant.SEARCH_AND_FILTER_COM_CONSTANTS.ERROR_OCCURRED
          );
          setFilteredColumnResultsApplied(existingSelectedValuesTrue);
          setLoading(false);
        });
    }
  };
  const checkboxChanged = (event, checkboxProps) => {
    const updateCheckedStatus = filteredColumnResultsApplied.map((record) => {
      if (record?.name === checkboxProps?.name) {
        return { ...record, selected: checkboxProps?.checked };
      }
      return record;
    });
    setFilteredColumnResultsApplied(updateCheckedStatus);
  };
  const applyFilter = () => {
    setOpen(false);
    props?.columnFilteringUpdateMethod(
      props?.fieldName,
      filteredColumnResultsApplied.filter((item) => item?.selected === true),
      true
    );
    setFilterApplied(true);
    methodToReset(true);
    //setFilteredColumnResultsApplied_copy(filteredColumnResultsApplied);
  };

  const clearFilter = () => {
    setTextBoxValue("");
    setFilteredColumnResultsApplied(filteredColumnResultsApplied_copy);
    if (filterApplied) {
      props?.columnFilteringUpdateMethod(props?.fieldName, [], true);
      setOpen(false);
    }
    setFilterApplied(false);
  };
  const resetLoadOption = () => {
    if (filterIconClick.current) {
      setLoadedOptions(PAGE_SIZE);
      setLoadedPageInfo({ pageLength: PAGE_SIZE, pageNumber: 1 });
    } else {
      setLoadedOptionsSearch(PAGE_SIZE);
      setLoadedPageInfoSearch({ pageLength: PAGE_SIZE, pageNumber: 1 });
    }
  };

  const initialfunction = (collectAllValues) => {
    collectAllValues
      ? (filterIconClick.current = true)
      : (filterIconClick.current = false);

    let allowedSplChars = librarySearchFormJsonData.specialCharacters;
    let regex = new RegExp(allowedSplChars?.replace("/", "//"));
    if (!regex.test(textBoxValue?.replace(/\s+/g, ""))) {
      setNoRecordsFound(() => {
        return Constant.SEARCH_AND_FILTER_COM_CONSTANTS
          .SPECIAL_CHARACTERS_NOT_ALLOWED;
      });
    } else if (
      ResponsePropertyName?.toLowerCase() ===
        Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase() &&
      !filterIconClick.current &&
      textBoxValue?.trim().length > 2
    ) {
      const existingSelectedValuesTrue = filteredColumnResultsApplied.filter(
        (item) => item?.selected === true
      );
      const filterSearchStatus_copy = filteredColumnResultsApplied_copy.filter(
        (item) => item?.label.toLowerCase().includes(textBoxValue.toLowerCase())
      );
      const filterSearchStatus = filterSearchStatus_copy
        .map((item) => {
          if (
            existingSelectedValuesTrue.filter(
              (filterItem) => filterItem?.name === item?.name
            ).length < 1
          ) {
            //const dynamicDataObjectFilter =
            return {
              name: item?.name,
              key: item?.key,
              label: item?.label,
              selected: item?.selected,
            };
            //return dynamicDataObjectFilter;
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
      if (
        filteredColumnResultsApplied_copy.filter((item) =>
          item?.label.toLowerCase().includes(textBoxValue.toLowerCase())
        )?.length < 1
      )
        setNoRecordsFound(
          Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND
        );
      setFilteredColumnResultsApplied([
        ...existingSelectedValuesTrue,
        ...filterSearchStatus,
      ]);
    } else {
      resetLoadOption();
      performSearchOnColumn(true, false);
    }
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const popupContent = (
    <>
      <div>
        <div className="mtextInputStyles">
          <Input
            clearable
            placeholder="Enter minimum of 3 characters"
            value={textBoxValue}
            onChange={textBoxChange}
            onKeyDown={(event) => {
              if (event?.key === "Enter") {
                initialfunction(false);
              }
            }}
          />
          <div
            className={
              textBoxValue?.length > 2
                ? "container-filterSearchIconClass enabledClass"
                : "container-filterSearchIconClass disbledClass"
            }
          >
            <AiOutlineSearch
              className="filterSearchIconClass"
              onClick={() => {
                initialfunction(false);
              }}
            />
          </div>
        </div>
        {loading == true && filteredColumnResultsApplied?.length == 0 ? (
          <div className={`filteredColumnResultsCheckBoxLoader`}>
            <Loader />
          </div>
        ) : (
          // : loading == false && filteredColumnResultsApplied?.length == 0 && noRecordsFound.length > 0 ?
          //   <div className="recordsMessage">{noRecordsFound}</div>
          <>
            {noRecordsFound.length > 0 && (
              <div className="recordsMessage">{noRecordsFound}</div>
            )}
            {filteredColumnResultsApplied.length > 0 ? (
              <div
                className="filteredColumnResultsCheckBox"
                id="filteredColumnResultsCheckBox"
              >
                {filteredColumnResultsApplied.map((item) => (
                  <div className="checkBoxDiv">
                    <Checkbox
                      key={item?.key}
                      name={item?.name}
                      label={item?.label}
                      onChange={checkboxChanged}
                      checked={item?.selected}
                      title={
                        ResponsePropertyName?.toLowerCase() !==
                        Constant.SEARCH_AND_FILTER_COM_CONSTANTS.STATUS.toLowerCase()
                          ? item?.name
                          : item?.label
                      }
                      className={"checkBoxClass"}
                    />
                  </div>
                ))}
                {loading && (
                  <div className={`filteredColumnResultsCheckBoxLoader`}>
                    <Loader />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {filteredColumnResultsApplied?.length > 0 && (
          <div className="filterFooterButtons">
            <FooterTechButtons
              disabled={false}
              split={false}
              cancelText={"Clear"}
              sendText={"Apply"}
              onClickAddData={applyFilter}
              onClickCancel={clearFilter}
            />
          </div>
        )}
      </div>
    </>
  );
  return (
    <>
      {window.innerWidth > 500 ? (
        <Popup
          open={open}
          position="below"
          align="bottom"
          closeOnScroll={false}
          onOpenChange={(e, { open }) => {
            setOpen(open);
          }}
          trigger={
            // <Button icon={<HiDownload />} className="buttonpp" />
            <MdFilterList
              className="filterIconClass"
              onClick={() => {
                initialfunction(true);
              }}
            />
          }
          content={{
            content: popupContent,
          }}
          trapFocus
        />
      ) : (
        <DialogBox
          openDialog={open}
          content={popupContent}
          triggerAction={
            <MdFilterList
              className="filterIconClass"
              onClickCapture={() => {
                setOpen(true);
              }}
              onClick={() => {
                initialfunction(true);
              }}
            />
          }
          headerName={props?.columnName}
          backdrop={true}
          className={`dialogOverlayInternal `}
          closeOnOutsideClick={true}
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            onClick: closeDialog,
          }}
        />
      )}
    </>
  );
}
