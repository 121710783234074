import React, { useEffect, useState } from "react";
import { MdError } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { DatePicker as Datepicker } from "@fluentui/react";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import { customFormatter } from "../../../utility/utility";
import "./datePicker.css";

function DatePicker(props) {
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const allValidations = props?.validations;
  const [curValue, setCurValue] = useState(props?.value);
  //const [curSelection, setCurrentSelection] = useState(props?.defaultSelectedDate);
  const datePickerRef = React.useRef(null);

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  const handleDateChange = (value, fieldName, name) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        value
      );
      setError(hasError);
      setErrorMessage(errMessage);
      props.onChangeHandler(
        value,
        fieldName,
        name,
        props?.isCustomField,
        hasError,
        errMessage
      );
    } else {
      props.onChangeHandler(
        value,
        fieldName,
        name,
        props?.isCustomField,
        false,
        ""
      );
    }
    setCurValue(value);
  };

  useEffect(() => {
    if (props?.defaultSelectedDate) {
      props.onChangeHandler(
        props?.defaultSelectedDate,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        false,
        ""
      );
    }
  }, []);

  useEffect(() => {
    if (props.editMode == true) {
      setCurValue(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    }
  }, [props?.value]);

  useEffect(() => {
    if (props?.isPolls || props?.isLibrarySearch) {
      setCurValue(props?.value);
    }
  }, [props?.value]);

  // useEffect(() => {
  //   props.onChangeHandler(
  //     curSelection,
  //     props?.fieldsName,
  //     props?.name,
  //     props?.isCustomField,
  //     props?.parentName,
  //     false,
  //     ""
  //   );
  // }, []);

  const resetDate = () => {
    setCurValue(undefined);
    datePickerRef.current?.focus();
    handleDateChange("", props?.fieldsName, props?.name);
  };
  const datePickerStyles = {
    root: {
      selectors: {
        "&:hover": {
          border: "none !important", // Remove border on hover
        },
        "&:focus": {
          border: "none !important", // Remove border on focus
        },
        "&:active": {
          border: "none !important",
        },
      },
    },
  };

  return (
    <>
      <span
        className={`datePlaceHolderClass ${
          props?.placeholderClassName != undefined
            ? props?.placeholderClassName
            : ""
        }`}
      >
        {props?.placeholder}
      </span>
      <div className="formDatePickerStyles CCDatePicker">
        <Datepicker
          componentRef={datePickerRef}
          disabled={props?.disabled}
          required={props?.required}
          formatMonthDayYear={customFormatter}
          placeholder={props?.inputPlaceholder}
          allowManualInput={props?.allowManualInput}
          defaultSelectedDate={props?.defaultSelectedDate}
          className={`datePickerStyles ${props?.className} ${
            hasAnyError ? "date_error" : ""
          }`}
          // onDateChange={(e, value) => {
          //   console.log(e);
          //   handleDateChange(value, props?.fieldsName, props?.name);
          // }
          // }
          onChangeCapture={(e) => console.log(e)}
          input={{
            clearable: props?.clearable,
          }}
          value={curValue}
          minDate={props?.minDate}
          maxDate={props?.maxDate}
          onClick={props?.onClickHandler}
          allowTextInput
          styles={datePickerStyles}
          formatDate={customFormatter}
          onSelectDate={(value) => {
            handleDateChange(value, props?.fieldsName, props?.name);
          }}
          title="Open calendar"
          id={props?.id}
        />
        {curValue !== "" && curValue !== null && (
          <GrClose
            title="Clear"
            className={`clearDateIcon ${props?.disabled ? "disableClear" : ""}`}
            onClick={resetDate}
          />
        )}
      </div>
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
}

export default DatePicker;
