import React, { useState, useEffect } from "react";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { postData } from "../../services/customApis";
import Button from "../../commonui/button/button";
import ButtonPrimary from "../../commonui/button/buttonPrimary";
import { app } from "@microsoft/teams-js";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import StaticPageContainer from "../../components/staticpagesforcatalystapp/staticPageContainer";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../services/appInsights";
import "./privacyNoticePopup.css";

function PrivacyNoticePopup(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, SetError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [divStyle, setDivStyle] = useState({});
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hideModal = () => {
    setIsModalOpen(false);
    handleUserInfoChange({ teams: { privacyAgreementDeclined: true } });
    props?.setOpenDialog(false);
  };

  useEffect(() => {
    const handleHeight = (ev) => {
      let height = window.innerHeight;
      let heightVal = height + "px";
      setDivStyle({
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: heightVal,
      });
    };

    try {
      window.addEventListener("resize", handleHeight, true);
    } catch (e) {
      console.log(e);
    }
    return () => {
      window.removeEventListener("resize", handleHeight, true);
    };
  }, []);

  const AcceptPrivacyAgreement = async () => {
    let newInputObj = {};
    let defaultComp = userInfo.allCompanies?.[0];
    handleUserInfoChange({ loading: true });
    setIsModalOpen(false);
    await postData(
      newInputObj,
      APIEndPoints.ACCEPT_PRIVACY_AGREEMENT(
        userInfo.teams.companyId ?? defaultComp?.companyId
      )
    )
      .then((item) => {
        handleUserInfoChange({ loading: true, privacyAgreementAccepted: true });
        window.localStorage.setItem(
          "curUserSelectedCompany",
          JSON.stringify(
            userInfo.teams.companyId
              ? {
                  ...userInfo.teams,
                  privacyAgreementDeclined: false,
                  privacyAgreementAccepted: true,
                }
              : {
                  ...defaultComp,
                  key: defaultComp.companyId,
                  text: defaultComp.companyName,
                  schema: defaultComp.schema,
                  companyFeatureFlags: defaultComp.companyFeatureFlags,
                  isSelected: defaultComp.isSelected,
                  privacyAgreementDeclined: false,
                  privacyAgreementAccepted: true,
                }
          )
        );
        let privacyAgreementAccepted = userInfo.allCompanies?.some(
          (obj) => obj?.privacyAgreementAccepted === true
        );

        TrackEvent(TelemetryEventNames.UCC_ACCEPT_PRIVACY_NOTICE, userInfo, {
          privacyAgreementAccepted: true,
        });

        window.location.href =
          Constant.REDIRECTION_PATH +
          (privacyAgreementAccepted ? "" : "?showTaketheTour=true");
      })
      .catch((err) => {
        SetError(true);
        setIsLoading(false);
        SetErrorMsg(err?.response?.data?.Errors);
        props?.setOpenDialog(false);
      });
  };

  useEffect(() => {
    if (
      userInfo.teams.companyId &&
      !userInfo.teams.privacyAgreementAccepted &&
      userInfo.isAuthorized
    ) {
      setIsModalOpen(true);
    }
  }, [userInfo.teams.companyId]);

  return (
    <DialogBox
      closeOnOutsideClick={false}
      backdrop={true}
      openDialog={isModalOpen}
      className={"dialogStyles datadropheader"}
      footer={true}
      headerName={"Privacy Notice"}
      content={
        <div>
          <div>
            {userInfo?.teams?.isInternalCompany ? (
              <div className="justifyContent">
                Your contact information will be used to authenticate you into
                the tool and will be handled in accordance with{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    userInfo.loggedInWeb === true
                      ? window.open(
                          "https://www.ey.com/en_gl/privacy-statement",
                          "_blank"
                        )
                      : app.openLink(
                          "https://www.ey.com/en_gl/privacy-statement"
                        )
                  }
                >
                  {" "}
                  EY's Privacy Statement
                </a>
              </div>
            ) : !userInfo?.isInternal ? (
              <div className="justifyContent">
                <br></br>
                Review{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    userInfo.loggedInWeb === true
                      ? window.open(
                          "https://login.ey.com/myey/privacy-notice",
                          "_blank"
                        )
                      : app.openLink("https://login.ey.com/myey/privacy-notice")
                  }
                >
                  {" "}
                  My EY Privacy Notice
                </a>{" "}
                and click OK to proceed.
                <br></br>
              </div>
            ) : (
              <div className="justifyContent">
                <StaticPageContainer
                  apiName={"/PrivacyNotice/getPrivacyNotice"}
                  tabName={"EY Catalyst Connect"}
                  privacyNotice="true"
                />
                <p style={{ display: "flex", gap: "10px" }}>
                  <span style={{ marginTop: "3px" }}>
                    <input
                      type="checkbox"
                      style={{
                        height: "18px",
                        width: "18px",
                        cursor: "pointer",
                      }}
                      checked={checkBoxChecked}
                      onChange={(e) => setCheckBoxChecked(e.target.checked)}
                    />
                  </span>
                  <span>
                    By clicking on 'Accept', you are confirming that you have
                    read and agree to EY Catalyst Connect's{" "}
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        userInfo.loggedInWeb === true
                          ? window.open(
                              "https://www.ey.com/en_gl/legal-statement",
                              "_blank"
                            )
                          : app.openLink(
                              "https://www.ey.com/en_gl/legal-statement"
                            )
                      }
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        userInfo.loggedInWeb === true
                          ? window.open(
                              "https://www.ey.com/en_gl/legal-and-privacy",
                              "_blank"
                            )
                          : app.openLink(
                              "https://www.ey.com/en_gl/legal-and-privacy"
                            )
                      }
                    >
                      Privacy Policy.
                    </a>
                  </span>
                </p>
                <br />
                <p style={{ fontWeight: "600" }}>
                  We look forward to working with you.
                  <br />
                  Sincerely, EY
                </p>
              </div>
            )}
          </div>
          <>
            {
              <>
                {" "}
                <div>
                  <div className="btncontainer">
                    <ButtonPrimary
                      text={`${!userInfo?.isInternal ? "OK" : "Accept"}`}
                      onClick={AcceptPrivacyAgreement}
                      disabled={
                        userInfo?.teams?.isInternalCompany ||
                        !userInfo?.isInternal
                          ? false
                          : !checkBoxChecked
                      }
                    />

                    <Button
                      text={`${"Decline"}`}
                      onClick={hideModal}
                      className={!userInfo?.isInternal && "hideButton"}
                    />
                  </div>
                </div>
              </>
            }
          </>
        </div>
      }
    />
  );
}

export default PrivacyNoticePopup;
