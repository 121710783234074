import { useRef, useEffect } from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { checkIfDeviceIsMob } from "../../utility/utility";
import "./button.css";

function ButtonPrimary(props) {
  const btnRef = useRef(null);

  useEffect(() => {
    if (!props.disabled) {
      if (checkIfDeviceIsMob()) {
        btnRef.current.focus();
      }
    }
  }, [props.disabled]);
  return (
    <div className="teamsbtn primaryBtn">
      <PrimaryButton
        text={props.text}
        onClick={props.onClick}
        disabled={props.disabled}
        componentRef={btnRef}
        className={props.disabled ? "btn_disabled" : "btn_enabled"}
      />
    </div>
  );
}

export default ButtonPrimary;
