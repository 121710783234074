import React from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import "./FooterTechButtons.css";

function FooterTechButtons(props) {
  const defaultButtonProps = {
    text: props?.sendText,
    onTouchStart: props?.onTouchStart
      ? (ev) => handleEventsForMob(ev)
      : undefined,
    split: props?.split,
    iconProps: { iconName: props?.buttonIcon },
    menuProps: props?.menuProps,
    "data-testid": "data-submit",
    onClick: props.onClickAddData,
    disabled: props.disabled,
    className:
      props.disabled === true ? "btn_save disabled" : "btn_save enabled",
    styles: props?.splitStyle,
  };

  const handleEventsForMob = (event, action) => {
    if (!props.disabled && event.target.className.indexOf("ms-Button") > -1) {
      props.onClickAddData();
    }
  };

  // ask ai for
  return (
    <div className="FooterTechButtons">
      <DefaultButton {...defaultButtonProps} />
      <DefaultButton
        text={props.cancelText}
        onClick={props?.onClickCancel}
        disabled={props.disabledCancel}
        className={
          props.disabledCancel === true ? "btn_cancel disabled" : "btn_cancel"
        }
      />
    </div>
  );
}

export default FooterTechButtons;
