import { ISignalHandler } from "./ISignalHandler";

export class SignalHandler implements ISignalHandler {
  private connection: signalR.HubConnection;

  constructor(conn: signalR.HubConnection) {
    this.connection = conn;
  }

  public static offAll(conn: signalR.HubConnection): void {
    conn.off("DataLibraryUpdated");
    conn.off("ReportLibraryUpdated");
    conn.off("PinLibraryUpdated");
    //conn.off("DuplicateFileUpdated");
  }

  public onDataLibraryUpdated(action: () => void): void {
    this.connection.on("DataLibraryUpdated", () => {
      action();
    });
  }
  public onReportLibraryUpdated(action: () => void): void {
    this.connection.on("ReportLibraryUpdated", () => {
      action();
    });
  }
  public onPinLibraryUpdated(action: () => void): void {
    this.connection.on("PinLibraryUpdated", () => {
      action();
    });
  }
  public onNotificationSent(
    action: (
      messageType: string,
      header: string,
      details: string,
      companyId: number,
      isHeaderFormatRequired: boolean
    ) => void
  ): void {
    this.connection.on(
      "NotificationSent",
      (messageType, header, details, companyId, isHeaderFormatRequired) => {
        action(messageType, header, details, companyId, isHeaderFormatRequired);
      }
    );
  }
  public onDuplicateFileUpdated(action: (dataFileId: number) => void): void {
    this.connection.on("DuplicateFileUpdated", (dataFileId) => {
      action(dataFileId);
    });
  }
  public onSendExtractedFormList(
    action: (
      dataFileId: number,
      fileName: string,
      extractedFormList: string,
      companyId: number
    ) => void
  ): void {
    this.connection.on(
      "SendExtractedFormList",
      (dataFileId, fileName, extractedFormList, companyId) => {
        action(dataFileId, fileName, extractedFormList, companyId);
      }
    );
  }
  public onDataLibraryUpdatedForUser(
    action: (companyId: number) => void
  ): void {
    this.connection.on("DataLibraryUpdatedForUser", (companyId) => {
      action(companyId);
    });
  }
  public onReportLibraryUpdatedForUser(
    action: (companyId: number) => void
  ): void {
    this.connection.on("ReportLibraryUpdatedForUser", (companyId) => {
      action(companyId);
    });
  }
  public onVisualizationLibraryUpdatedForUser(
    action: (companyId: number) => void
  ): void {
    this.connection.on(
      "VisualizationLibraryFileAccessUpdatedForUser",
      (companyId) => {
        action(companyId);
      }
    );
  }
}
