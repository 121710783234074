import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import "./multiSelectParentChild.css";

const SelectedOption = ({
  selectedCategories,
  setSelectedCategories,
  maxSelectionLimit,
  disabled
}) => {
  const handleRemove = (item) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.filter((category) => category.id !== item.id)
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedSelected = Array.from(selectedCategories);
    const [removed] = updatedSelected.splice(result.source.index, 1);
    updatedSelected.splice(result.destination.index, 0, removed);

    setSelectedCategories(updatedSelected);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="selected-categories" direction="horizontal" >
        {(provided) => (
          <div
            className="selected-option-container-multiSelect"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {selectedCategories.map((item, index) => {
              const isTNUCategory = item?.source === "TNU" || disabled == true;
              return (<Draggable
                // key={item.id.toString()}
                key={index}
                // draggableId={item.id.toString()}
                draggableId={index?.toString()}
                index={index}
                isDragDisabled={isTNUCategory}
              >
                {(provided) => (
                  <div
                    className={`selected-option-pill-multiSelect ${isTNUCategory ? " TNUselected-option-pill-multiSelect" : ""}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <span title={item.name} className="overflowValuesCharcount-multiSelect">{item.name}</span>
                    <button
                      className={`remove-button-multiSelect ${isTNUCategory ? " TNURemoveBtn-multiSelect" : ""}`}
                      onClick={isTNUCategory ? undefined : () => handleRemove(item)}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </Draggable>);
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const OptionCheckBox = ({
  item,
  selectedCategories,
  setSelectedCategories,
  paddingLeft,
  maxSelectionLimit,
  filterText,
}) => {
  const [checked, setChecked] = useState(
    selectedCategories.some((category) => category.id === item.id)
  );

  const toggleSelection = () => {
    if (checked) {
      setSelectedCategories((prevSelected) =>
        prevSelected.filter((category) => category.id !== item.id)
      );
    } else {
      if (!maxSelectionLimit || selectedCategories.length < maxSelectionLimit) {
        setSelectedCategories((prevSelected) => [...prevSelected, item]);
      }
    }
  };

  useEffect(() => {
    setChecked(selectedCategories.some((category) => category.id === item.id));
  }, [selectedCategories, item.id]);

  return (
    <div
      className={`option-label-input-multiSelect ${!checked &&
        maxSelectionLimit &&
        selectedCategories.length >= maxSelectionLimit
        ? "disabled-option-multiSelect"
        : ""
        }`}
      style={{ paddingLeft: `${paddingLeft + 25}px` }}
    >
      <input
        tabIndex={0}
        type="checkbox"
        className="option-checkbox-multiSelect"
        checked={checked}
        onChange={toggleSelection}
        disabled={
          !checked &&
          maxSelectionLimit &&
          selectedCategories.length >= maxSelectionLimit
        }
      />
      <span className="overflowValues-multiSelect" title={item.name}>
        {`${item.categoryId} - ${item.name}`}
      </span>
    </div>
  );
};

const OptionCheckBox_New = ({
  item,
  selectedCategories,
  setSelectedCategories,
  paddingLeft,
  maxSelectionLimit,
  filterText,
}) => {
  const [checked, setChecked] = useState(
    selectedCategories.some((category) => category.id === item.id)
  );

  const toggleSelection = () => {
    const removeItemAndChildren = (item, selected) => {
      let updatedSelected = selected.filter((category) => category.id !== item.id);
      if (item.children) {
        item.children.forEach((child) => {
          updatedSelected = removeItemAndChildren(child, updatedSelected);
        });
      }
      return updatedSelected;
    };
  
    const addItemAndChildren = (item, selected) => {
      let updatedSelected = [...selected, item];
      if (item.children) {
        item.children.forEach((child) => {
          updatedSelected = addItemAndChildren(child, updatedSelected);
        });
      }
      return updatedSelected;
    };
    if (checked) {
      setSelectedCategories((prevSelected) => removeItemAndChildren(item, prevSelected));
    } else {
      if (!maxSelectionLimit || selectedCategories.length < maxSelectionLimit) {
        setSelectedCategories((prevSelected) => addItemAndChildren(item, prevSelected));
      }
    }
  };

  useEffect(() => {
    setChecked(selectedCategories.some((category) => category.id === item.id));
  }, [selectedCategories, item.id]);

  return (
    <input
      tabIndex={0}
      type="checkbox"
      className="option-checkbox-multiSelect"
      checked={checked}
      onChange={toggleSelection}
      disabled={
        !checked &&
        maxSelectionLimit &&
        selectedCategories.length >= maxSelectionLimit
      }
    />
  );
};

const Option = ({
  item,
  selectedCategories,
  setSelectedCategories,
  paddingLeft,
  maxSelectionLimit,
  filterText,
  propToCompareAgainst,
  searchRef,
  ancestor,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOption = () => {
    //console.log(searchRef);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    setOpen(filterText !== "");
  }, [filterText]);

  // useEffect(() => {
  //   setOpen(ancestor.includes(item?.id));
  // }, [ancestor]);

  const memoizedOptionCheckBox = useMemo(
    () => (
      <OptionCheckBox
        item={item}
        paddingLeft={paddingLeft}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        maxSelectionLimit={maxSelectionLimit}
        filterText={filterText}
      />
    ),
    [
      item,
      paddingLeft,
      selectedCategories,
      setSelectedCategories,
      maxSelectionLimit,
      filterText,
    ]
  );

  const memoizedOptionCheckBox_New = useMemo(
    () => (
      <OptionCheckBox_New
        item={item}
        paddingLeft={paddingLeft}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        maxSelectionLimit={maxSelectionLimit}
        filterText={filterText}
      />
    ),
    [
      item,
      paddingLeft,
      selectedCategories,
      setSelectedCategories,
      maxSelectionLimit,
      filterText,
    ]
  );

  const memoizedNestedOptions = useMemo(
    () =>
      item.children?.map((childItem, index) => (
        <Option
          item={childItem}
          key={index}
          paddingLeft={paddingLeft + 20}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          maxSelectionLimit={maxSelectionLimit}
          filterText={filterText}
          ancestor={ancestor}
        />
      )),
    [
      item.children,
      paddingLeft,
      selectedCategories,
      setSelectedCategories,
      maxSelectionLimit,
      ancestor,
    ]
  );

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
        const event = new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        view: window
        });
        document.dispatchEvent(event);}}}
      className={`option-container-multiSelect ${open ? "option-container-open-multiSelect" : ""}`}
    >
      <div className="option">
        {item?.children?.length > 0 && (
          <div
            className="option-label-multiSelect"
            style={{ paddingLeft: `${paddingLeft}px` }}
          >
            {open ? (
              <MdOutlineKeyboardArrowDown
                className="option-arrow-multiSelect"
                onClick={toggleOption}
              />
            ) : (
              <MdOutlineKeyboardArrowRight
                className="option-arrow-multiSelect"
                onClick={toggleOption}
              />
            )}{" "}
            <span className="nested-span-checkbox-multiSelect">
              {" "}
              {memoizedOptionCheckBox_New}
              <span title={item.name} className="overflowValues-multiSelect">{`${item.categoryId} - ${item.name}`}</span>
            </span>
          </div>
        )}
        {item?.children?.length > 0 ? (
          memoizedNestedOptions
        ) : (
          <div
            className={`option-children ${!open || filterText !== ""
              ? "option-children-visible-multiSelect"
              : "option-children-hidden-multiSelect"
              }`}
          >
            {memoizedOptionCheckBox}
          </div>
        )}
      </div>
    </div>
  );
};

const MultiSelectDropdownWithParentChildSelection = (props) => {
  const {
    items,
    placeholder,
    maxSelectionLimit,
    getSelectedItems,
    additionalMessage,
    validations,
    fieldsName,
    name,
  } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState(items);
  const [filterText, setFilterText] = useState("");
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const [showDrpDown, setDrpDownVisibilty] = useState(false);
  const [ancestor, setAncestor] = useState([]);
  const [editFirstRender, setEditFirstRender] = useState(0);
  const [categoriesSel, setCategoriesSel] = useState([]);
  useEffect(() => {
    setCategoriesData(filterData(items, filterText));
  }, [items, filterText]);

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    if (validations != undefined && validations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        validations,
        selectedCategories
      );
      setError(hasError);
      setErrorMessage(errMessage);
      // setSelectedCategories(selectedCategories);
      getSelectedItems(
        selectedCategories,
        fieldsName,
        name,
        hasAnyError,
        errMessage
      );
    } else {
      // setSelectedCategories(selectedCategories);
      getSelectedItems(selectedCategories, fieldsName, name, false, "");
    }
    let allAncestor = [];
    selectedCategories.forEach((selectedItem) => {
      categoriesData.forEach((category) => {
        findAncestor(category, selectedItem.id, allAncestor);
      });
    });
    setAncestor(allAncestor);
  }, [selectedCategories]);

  useEffect(() => {
    if(props?.isFromInternalApps && props.editMode == true && editFirstRender == 0)
    {
      setEditFirstRender(1);
      setCategoriesSel(props?.defaultSelectedItems);
    }
    else if (props.editMode == true) {
      setSelectedCategories(props?.defaultSelectedItems);
      props?.updateValidationState(
        props?.name,
        props?.defaultSelectedItems,
        props?.hasAnyError,
        props?.errMessage
      );
      props?.setEditProperties({ [props?.name]: false });
    }
  }, [props?.defaultSelectedItems]);

  useEffect(() => {
    if(editFirstRender == 1 && categoriesSel.length > 0)
    {
      setSelectedCategories(categoriesSel);
    }
  }, [editFirstRender, categoriesSel]);

  const findAncestor = (currentItem, valToCompare, arryaOfParents) => {
    //if currentItem id is not equal to ValToCompare and doesn'thave children return false
    if (currentItem.id != valToCompare && currentItem.children && currentItem.children?.length == 0) {
      return false;
    }
    // current Item id is what we are looking for
    if (currentItem.id == valToCompare) {
      return true;
    }
    //if currentItem id is not equal to ValToCompare but has children , possibility is this can be a parent
    if (currentItem.id != valToCompare && currentItem.children && currentItem.children.length != 0) {
      //add this item
      for (let i = 0; i < currentItem.children.length; i++) {
        let isItem = findAncestor(
          currentItem.children[i],
          valToCompare,
          arryaOfParents
        );
        if (isItem) {
          arryaOfParents.push(currentItem.id);
          return true;
        }
      }
    }
  };

  const filterData = (data, text) => {
    if (text === "") return data;

    let result = [];

    for (let item of data) {
      if (
        item.name.toLowerCase().includes(text.toLowerCase()) ||
        item.categoryId.toLowerCase().includes(text.toLowerCase())
      ) {
        result.push(item);
      } else if (item.children) {
        let nestedResult = filterData(item.children, text);
        if (nestedResult.length > 0) {
          result.push({ ...item, children: nestedResult });
        }
      }
    }

    return result;
  };

  const handleFocusInEvent = (e) => {
    setDrpDownVisibilty(true);
    setError(false);
  };

  const handleFocusOutEvent = (e) => {
    //console.log(e.relatedTarget);
    if (e.relatedTarget) {
      if (e.relatedTarget.className.indexOf("option-") > -1) {
        e.target.focus({ preventScroll: true });
      } else {
        setDrpDownVisibilty(false);
      }
    } else {
      setDrpDownVisibilty(false);
    }
  };

  const handleSearchChange = (e) => {
    setFilterText(e.target.value.replace(/"/g, ""));
  };

  // console.log(props?.defaultSelectedItems);

  const memoizedOptions = useMemo(
    () =>
      categoriesData?.map((item, index) => (
        <Option
          item={item}
          key={index}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          paddingLeft={10}
          maxSelectionLimit={maxSelectionLimit}
          filterText={filterText}
          ancestor={ancestor}
        />
      )),
    [
      categoriesData,
      selectedCategories,
      setSelectedCategories,
      maxSelectionLimit,
      filterText,
      ancestor,
    ]
  );

  const elementToRender = () => {
    if (memoizedOptions.length > 0) {
      return memoizedOptions;
    } else {
      return <div className="noOption-class-multiSelect">{props?.noOptionsMessage}</div>;
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Close the dropdown if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef?.current?.contains(event.target)) {
        setDrpDownVisibilty(false);
      }
    };

    // Attach the event listener to the entire document
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className="multiselect-group-parent"
      tabIndex={0}
      // onFocus={handleFocusInEvent}
      ref={dropdownRef}
    >
      <div className="multiselectdivlabel_parent">
        {props?.additionalMessage !== "" && (
          <span className="additionalMessage">{props?.additionalMessage}</span>
        )}
      </div>
      <input
        tabIndex={0}
        type="text"
        value={filterText}
        onChange={handleSearchChange}
        placeholder={placeholder}
        className="option-search-box-multiSelect"
        onFocus={() => setDrpDownVisibilty(true)}
        id={props?.id}
        disabled={props?.disabled}
      />
      {showDrpDown ? (
        <div className="dropdown-container-multiSelect">{elementToRender()}</div>
      ) : (
        <div></div>
      )}
      <SelectedOption
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        maxSelectionLimit={maxSelectionLimit}
        disabled={props?.disabled}
      />
      {hasAnyError && (
        <div>
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdownWithParentChildSelection;
