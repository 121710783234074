import React from "react";
import "./Content.css";

function Content(props) {
  return (
    <div className="contentList">
      {props.listItems.map((item, index) => {
        return (
          <p key={index}>
            <span className="ContentBullets">&#8227;</span>
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default Content;
