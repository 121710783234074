/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillBell,
} from "react-icons/ai";
import { MdInfo } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { Alert, Button } from "@fluentui/react-northstar";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import DialogBox from "../dialogBox/DialogBox";
import Constant from "../../utility/constants";
import "./toasterNotifications.css";

function ToasterNotifications(props) {
  const { notificationMsgsData, handleNotificationMsgsData } =
    UseToasterNotificationsContext();
  let { showMsgBar, completed, msg, type, button, validationMsg } =
    notificationMsgsData;
  const timeoutRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [validationDetails, setValidationDetails] = useState("");
  const [headerMsg, setHeaderMsg] = useState("");

  useEffect(() => {
    if (completed === true) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        handleNotificationMsgsData({
          showMsgBar: false,
          started: false,
          completed: false,
          msg: "",
          type: "",
          isFailed: false,
          button: "",
          validationMsg: "",
        });
        timeoutRef.current = null;
      }, 5000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [completed, notificationMsgsData]);
  const onClear = () => {
    handleNotificationMsgsData({
      showMsgBar: false,
      started: false,
      completed: false,
      msg: "",
      type: "",
      isFailed: false,
      button: null,
      validationMsg: "",
    });
  };

  const handleSeeDetails = (val, validationMsg, msg) => {
    setOpenDialog(val);
    setHeaderMsg(msg);

    const detailsArray = validationMsg?.split(",");
    if (detailsArray.length > 1) {
      setValidationDetails(
        detailsArray.map((item, index) => <li key={index}>{item.trim()}</li>)
      );
    } else {
      setValidationDetails(validationMsg);
    }
    // console.log("openDialog", openDialog);
  };
  const handleCancel = (val) => {
    setOpenDialog(val);
    // console.log("openDialog", openDialog);
  };
  return (
    <div className={props.showClass}>
      {showMsgBar && (
        <Alert
          content={
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                {type.toLocaleLowerCase() === "success" ? (
                  <AiFillCheckCircle />
                ) : type.toLocaleLowerCase() === "fail" ? (
                  <AiFillExclamationCircle />
                ) : type.toLocaleLowerCase() === "warning" ||
                  type.toLocaleLowerCase() === "bulletin board" ? (
                  <AiFillBell />
                ) : type.toLocaleLowerCase() === "info" ? (
                  <MdInfo />
                ) : null}
                {msg}
              </div>
              {button && (
                <span className={"seeDetails"}>
                  <Button
                    size="small"
                    onClick={() => handleSeeDetails(true, validationMsg, msg)}
                    content={button}
                  />
                </span>
              )}
            </span>
          }
          visible={showMsgBar}
          success={type.toLocaleLowerCase() === "success" ? true : false}
          danger={type.toLocaleLowerCase() === "fail" ? true : false}
          warning={
            type.toLocaleLowerCase() === "info" ||
            type.toLocaleLowerCase() === "warning" ||
            type.toLocaleLowerCase() === "bulletin board"
              ? true
              : false
          }
          dismissible
          dismissAction={{
            onClick: onClear,
          }}
        />
      )}
      <DialogBox
        cancelButton={"Close"}
        onCancelMethod={() => {
          handleCancel(false);
        }}
        closeOnOutsideClick={false}
        content={
          <p className={"validationContent"}>
            <br />
            {headerMsg}
            <br />
            <br />
            <ul>
              <li>{validationDetails}</li>
            </ul>
            <br />
            <p style={{ fontStyle: "italic", fontSize: "13px" }}>
              {Constant.DI_APP_CONSTANTS.FURTHER_ASSISTANCE_FOOTER}
            </p>
          </p>
        }
        headerName={"Metadata validation failed"}
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          onClick: () => {
            handleCancel(false);
          },
        }}
        backdrop={true}
        openDialog={openDialog}
        className={"dialogStyles"}
      />
    </div>
  );
}

export default ToasterNotifications;
