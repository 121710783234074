import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Slider from "react-slick";
import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
} from "@fluentui/react";
import { Image } from "@fluentui/react-northstar";
import { postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../commonui/button/button";
import "./takeatour.css";

const wrapperStyle = { display: "flex" };

const getCustomElementsExampleThree = () => {
  return (
    <div style={wrapperStyle}>
      <ShimmerElementsGroup
        width={"90px"}
        shimmerElements={[
          { type: ShimmerElementType.line, height: 80, width: 80 },
          { type: ShimmerElementType.gap, width: 10, height: 80 },
        ]}
      />
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <ShimmerElementsGroup
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 40 },
            { type: ShimmerElementType.gap, width: 10, height: 40 },
          ]}
        />
        <ShimmerElementsGroup
          flexWrap
          width={"calc(100% - 50px)"}
          shimmerElements={[
            { type: ShimmerElementType.line, width: "90%", height: 10 },
            { type: ShimmerElementType.gap, width: "10%", height: 20 },
            { type: ShimmerElementType.line, width: "100%", height: 10 },
          ]}
        />
        <ShimmerElementsGroup
          flexWrap
          width={"100%"}
          shimmerElements={[
            {
              type: ShimmerElementType.line,
              width: "80%",
              height: 10,
              verticalAlign: "bottom",
            },
            { type: ShimmerElementType.gap, width: "20%", height: 20 },
            {
              type: ShimmerElementType.line,
              width: "40%",
              height: 10,
              verticalAlign: "bottom",
            },
            { type: ShimmerElementType.gap, width: "2%", height: 20 },
            {
              type: ShimmerElementType.line,
              width: "58%",
              height: 10,
              verticalAlign: "bottom",
            },
          ]}
        />
      </div>
    </div>
  );
};

const ImageComponent = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const { card } = props;

  useEffect(() => {
    postData(
      {
        downloadTypeId: card.pageTypeId,
        requestOriginatorId: card.pageId,
        requestToken: card.requestToken,
      },
      APIEndPoints.DOWNLOAD_TAKETOUR_IMAGES,
      false
    )
      .then((item) => {
        setImageUrl(item.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      {imageUrl === "" ? (
        <Shimmer
          customElementsGroup={getCustomElementsExampleThree()}
          width="90%"
        />
      ) : (
        <Image width="auto" alt={card.pageHeader} src={imageUrl} fit="cover" />
      )}
    </>
  );
};

const TakeaTour = (props) => {
  const customeSlider = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [tourcards, setTourCards] = useState([]);

  const sliderSettings = {
    dots: slideIndex > 0,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    lazyLoad: true,
    // afterChange: (current) => setSlideIndex(current),
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };
  const gotoNext = () => {
    customeSlider.current.slickNext();
  };
  const closeModal = () => {
    setIsModalOpen(false);
    props.handleTakeaTourDialogToggle();
  };

  useEffect(() => {
    const getTakeTourDetails = async () => {
      await postData({}, APIEndPoints.GET_TAKETOUR_DETAILS(1), false)
        .then((response) => {
          if (response?.status === 200) {
            setTourCards(response?.data);
          }
        })

        .catch((error) => console.log("error", error));
    };
    getTakeTourDetails();
  }, []);

  const takeaTourDialog = () => {
    return (
      <div>
        <Slider ref={customeSlider} {...sliderSettings}>
          {tourcards.map((card, index) => (
            <div key={index}>
              <div className="tat-div-image">
                <ImageComponent card={card} />
              </div>
              <h2 className="tat-header-title">{card.pageHeader}</h2>
              <div
                className="tat-content"
                dangerouslySetInnerHTML={{
                  __html: card.pageContent,
                }}
              />
            </div>
          ))}
        </Slider>
        <br></br>
        {/* <hr></hr> */}
        <div>
          {slideIndex !== 0 && (
            <Button
              className="tat-prev-button"
              text="Back"
              onClick={gotoPrev}
            />
          )}
          <Button
            className="tat-next-button"
            text={`${
              tourcards.length - 1 === slideIndex
                ? "Close"
                : slideIndex === 0
                ? "Take the tour"
                : "Next"
            }`}
            onClick={
              tourcards.length - 1 === slideIndex ? closeModal : gotoNext
            }
          />
        </div>
      </div>
    );
  };

  return (
    <DialogBox
      closeOnOutsideClick={false}
      content={takeaTourDialog()}
      headerActionName={{
        icon: <MdClose />,
        title: "Close",
        onClick: () => {
          setIsModalOpen(false);
          props.handleTakeaTourDialogToggle();
        },
      }}
      className="takeAtourDialoag"
      backdrop={true}
      openDialog={isModalOpen}
    />
  );
};

export default TakeaTour;
