/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
import ComingSoon from "../comminsoon/comingSoon";
import {
  useMenuContext,
  useHamburgerMenuContext,
} from "../../context/menucontext/menuContext";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { scrollIntoViewWithOffset } from "../../../src/utility/menuscroll";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import Constant from "../../utility/constants";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import { getUrlParams } from "../../utility/utility";
import "./commonLayout.css";

function CommonLayout(props) {
  const { children } = props;
  const { menuState } = useMenuContext();
  const { menuInfo, handleMenuInfo } = UseContactUsContext();

  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { cardTitle } = useContext(BreadCrumbContext);
  let { userInfo } = UseUserInfoContext();
  const [showMenu, setShowMenu] = useState(false);
  let location = useLocation();
  const { notificationMsgsData } = UseToasterNotificationsContext();
  const navigate = useNavigate();

  let searchPage = location.pathname.indexOf("search") > 0;
  let requestChannel = location.pathname.indexOf("requestChannel") > 0;
  let pendingApprovals = location.pathname.indexOf("pendingApprovals") > 0;
  let channelsettings = location.pathname.indexOf("channelsettings") > 0;
  let editChannel = location.pathname.indexOf("editChannelSettings") > 0;
  let channelManagement = location.pathname.indexOf("channelManagement") > 0;

  const handleCloseContactUs = () => {
    if (!toggleHamburger) {
      if (window.innerWidth > 900) {
        handleHamburgerChange();
      }
    }
    if (
      menuInfo.menuName === "Search" ||
      menuInfo.menuName === "What's Catalyst"
    ) {
      //handleMenuInfo({ isClickedClose: true, sourceComponent: "" });

      const prevPath = getUrlParams("prevPath");
      const backButtonParams = `?loadFromCache=true&backButton=true`;

      if (prevPath) {
        navigate(`${prevPath}${backButtonParams}`);
      } else {
        navigate("/");
      }
    } else if (menuInfo.menuName === "Apps") {
      navigate(`/apps`);
    } else {
      navigate(-1);
    }
    // navigate(`${contentCurationData?.pageId}`);
  };

  useEffect(() => {
    try {
      let topbarHeight = document
        .getElementById("topbar")
        .getBoundingClientRect().height;
      let height1 = notificationMsgsData.showMsgBar ? 20 : 0;
      userInfo.loggedInWeb === true
        ? scrollIntoViewWithOffset(
            location.pathname,
            -(topbarHeight + height1 - 10 + 50)
          )
        : scrollIntoViewWithOffset(
            location.pathname,
            -(topbarHeight + height1 - 10)
          );
    } catch (e) {}
  }, [window.location]);
  useEffect(() => {
    location.pathname.indexOf("eycatalyst") > 0 ||
    location.pathname.indexOf("search") > 0 ||
    location.pathname.indexOf("settings") > 0 ||
    location.pathname === "/apps"
      ? setShowMenu(true)
      : setShowMenu(false);

    // window.scrollTo(0, 0)
  }, []);
  useEffect(() => {
    if (location.pathname.indexOf("customusergroups") > 0) {
      setShowMenu(false);
    } else if (location.pathname.indexOf("settings") > 0) {
      setShowMenu(true);
    }
  }, [location.pathname]);
  return (
    <div
      className={`common_container ${toggleHamburger ? "large" : "small"} ${
        notificationMsgsData.showMsgBar ? "showNotifications" : ""
      }`}
    >
      {cardTitle === "" &&
        location.pathname.indexOf("yourchannel") < 0 &&
        location.pathname !== "/" &&
        !requestChannel &&
        !pendingApprovals &&
        !channelsettings &&
        !editChannel &&
        !channelManagement && (
          <div
            className={
              showMenu || searchPage
                ? `common_container_header ${
                    notificationMsgsData.showMsgBar && "showSubMenuToaster"
                  }`
                : ""
            }
            id="common_container_header"
          >
            {location.pathname.indexOf("eycatalyst") > 0 && (
              <header>
                <p className="common_title">
                  {showMenu &&
                    !userInfo.loading &&
                    `Technology: ${Constant.APPLICATION_NAME} `}
                  <BsXLg
                    title="Close"
                    className="contactus-close1"
                    onClick={() => handleCloseContactUs()}
                  />
                </p>
              </header>
            )}

            {location.pathname.indexOf("eycatalyst") < 0 &&
              !searchPage &&
              !requestChannel && (
                <header>
                  <p className="common_title">
                    {showMenu && !userInfo.loading && menuState.menu.name}
                  </p>
                </header>
              )}

            {location.pathname.indexOf("search") > 0 && (
              <header>
                <p className="common_title">
                  Search libraries
                  <BsXLg
                    title="Close"
                    className="contactus-close1"
                    onClick={() => handleCloseContactUs()}
                  />
                </p>
              </header>
            )}
          </div>
        )}
      {children !== undefined ? children : <ComingSoon />}
    </div>
  );
}

export default CommonLayout;
