import React, { useEffect, useState } from "react";
import { Loader } from "@fluentui/react-northstar";
import { getRepliers } from "../../../utility/utility";
import { MdArrowDropDown } from "react-icons/md";
import Comment from "./comment";
import "./comments.css";

const Comments = (props) => {
  const {
    addingComment,
    comments,
    handleToggle,
    isCommentsOpen,
    fetchingComments,
    deleteUndoComment,
    editComment,
    config,
    contentTypeId,
    isGlobal,
  } = props;
  const [numberOfReplies, setNumberOfReplies] = useState("");

  useEffect(() => {
    setNumberOfReplies(getRepliers(comments));
  }, [comments]);

  return (
    <>
      <div className="accordion">
        {fetchingComments ? (
          <div className="comments-loader">
            <Loader />{" "}
          </div>
        ) : (
          comments?.length > 1 && (
            <div
              className="accordion-header"
              tabIndex={0}
              onKeyDown={handleToggle}
              onClick={handleToggle}
            >
              {isCommentsOpen ? (
                <>
                  <MdArrowDropDown className="icon-BiDownArrow" />
                  <p className="accordion-header-text">Collapse all</p>
                </>
              ) : (
                <>
                  <p className="accordion-header-text p2">{numberOfReplies}</p>
                </>
              )}
            </div>
          )
        )}
        {comments?.length > 1 && <hr className="accordion-divider" />}
        {isCommentsOpen && (
          <>
            <div className="accordion-content">
              <div className="commentContainer">
                {comments.map((item, index) => {
                  return (
                    <Comment
                      deleteUndoComment={deleteUndoComment}
                      editComment={editComment}
                      key={index}
                      item={item}
                      config={config}
                      contentTypeId={contentTypeId}
                      isGlobal={isGlobal}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
        {addingComment ? (
          <div className="comment-loading">
            <Loader />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Comments;
