import React, { useEffect, useState } from "react";
import { Checkbox } from "@fluentui/react-northstar";
import "./checkbox.css";

function CheckboxComponent(props) {
  const [currentValue, setCurrentValue] = useState(props?.checked);

  useEffect(() => {
    setCurrentValue(props?.checked);
  }, [props?.checked]);

  useEffect(() => {
    if (props.editMode == true) {
      setCurrentValue(props?.checked);
      props.updateValidationState(
        props?.name,
        props?.checked,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    } else if (props?.isPolls == true) {
      setCurrentValue(props?.checked);
    }
  }, [props?.checked]);
  return (
    <div className={"formCheckboxStyles"} id={props?.id}>
      {props?.labelExists && (
        <p className={!props?.checked ? "bold_text has_margin" : "has_margin"}>
          {props?.singleContent}
        </p>
      )}
      <Checkbox
        label={props?.label}
        checked={currentValue}
        disabled={props?.disabled}
        value={props?.value}
        onChange={(e, value) => {
          if (props?.dontAllowToUncheck) {
            if (
              (props?.fieldsName?.toLowerCase() === "not" &&
                props?.currentItem?.uiConfigs?.reOrderIndex === 1) ||
              (props?.currentItem?.userInputs?.selectedOperators?.length > 0 &&
                props?.currentItem?.userInputs?.selectedOperators[0]?.toLowerCase() !==
                  props?.fieldsName?.toLowerCase())
            ) {
              setCurrentValue(value?.checked);
            }
          } else {
            setCurrentValue(value?.checked);
          }
          props?.onChange(
            e,
            value,
            props?.fieldsName,
            props?.isContentField,
            props?.name,
            props?.hasAnyError,
            props?.errMessage,
            props?.index,
            props?.option
          );
        }}
        toggle={props?.toggle}
        // styles={{ checkmark: checkboxStyles }}
        className={`${props?.className} ${props?.newProp} ${
          currentValue === true && props?.contentType == 7
            ? "showOnlyOnChecked"
            : ""
        }`}
      />
      {props?.labelExists && (
        <p className={props?.checked ? "bold_text" : ""}>
          {props?.multipleContent}
        </p>
      )}
    </div>
  );
}

export default CheckboxComponent;
