import React from "react";
import { Attachment as ProgressAttachment } from "@fluentui/react-northstar";
import "./Attachment.css";

function Attachment(props) {
  return (
    props?.files?.length > 0 && (
      <div className="attachmentClass" id="attachmentClass">
        <ProgressAttachment
          header={
            <div>
              {props?.title()}
              {props?.removeTransition && (
                <div className="attachmentSizeStyles">
                  {" "}
                  {props?.description}{" "}
                </div>
              )}
            </div>
            //props?.title()
          }
          actionable={props?.actionable}
          action={{
            icon: props?.actionIcon,
            onClick: () => props?.onRemoveClick(),
            title: props?.actionTitle,
          }}
          description={
            !props?.removeTransition && props?.isModel
              ? "Processing Upload..."
              : ""
          }
          progress={props?.progress}
          className={`attachmentStyles ${
            props?.isErrorClass
              ? "ErrorAttach"
              : props?.removeTransition
              ? "removeTranform"
              : ""
          }`}
          id={"attachmentStylesId"}
          data-testid="attachment-testid"
        />
      </div>
    )
  );
}

export default Attachment;
