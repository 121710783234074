import React, { useEffect, useState, useContext } from "react";
import { Image } from "@fluentui/react-northstar";
import Tab from "./tab/tab";
import { useNavigate } from "react-router-dom";
import Constant from "../../utility/constants";
import {
  useMenuContext,
  useSideNavbarDataContext,
} from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { ImageUtil } from "../../utility/utility";
import MenuButton from "../../commonui/menubutton/menuButton";
import { useMsal } from "@azure/msal-react";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { deleteData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import "./navBar.css";

const NavBar = (props) => {
  const [activeTab, setActiveTab] = useState("Home");
  const { handleSideNavbarDataChange } = useSideNavbarDataContext();
  const { userInfo } = UseUserInfoContext();
  const { instance } = useMsal();
  const { loggedOut } = props;
  const nav = useNavigate();
  const { menuState } = useMenuContext();
  const { ResetBreadCrumb3LevelTitle } = useContext(BreadCrumbContext);

  useEffect(() => {
    const path = window.location.pathname;
    if (
      path.indexOf("settings") > -1 ||
      path.indexOf("customUserGroupForm") > -1
    ) {
      if (path.indexOf("channelsettings") > -1) {
        setActiveTab("Home");
      } else {
        setActiveTab("Settings");
      }
    } else {
      setActiveTab("Home");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const handlePopState = () => {
      const path = window.location.pathname;
      if (
        path.indexOf("settings") > -1 ||
        path.indexOf("customUserGroupForm") > -1
      ) {
        if (path.indexOf("channelsettings") > -1) {
          handleSideNavbarDataChange("menu");
        } else {
          handleSideNavbarDataChange("settings");
        }
        ResetBreadCrumb3LevelTitle();
      } else if (path.indexOf("/apps") > -1) {
      } else {
        handleSideNavbarDataChange("menu");
      }
    };

    handlePopState();

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleLogout = () => {
    deleteData("", APIEndPoints.Delete_EY_TOKEN).then(() => {
      const keyToKeep = "curUserSelectedCompany";
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== keyToKeep) {
          localStorage.removeItem(key);
        }
      });
      instance.setActiveAccount([]);
      instance.logoutRedirect();
    });
  };

  useEffect(() => {
    handleSideNavbarDataChange("menu");
    ResetBreadCrumb3LevelTitle();
  }, [userInfo.teams.companyId]);

  return (
    <div className="navBarTabs">
      <div className="navBarTabs__right-section">
        <div className="navBarTabs__right-section__logo">
          <Image
            alt=""
            src={require("../../assets/EY_Logo.png")}
            fit="cover"
            className="navBarTabs__right-section__logo__image"
          />
          <p className="navBarTabs__right-section__logo__heading">
            {(() => {
              const origin = window.location.origin;
              if (origin.indexOf("https://catalyst.ey.com") > -1) {
                return "EY Catalyst Connect";
              } else if (origin.indexOf("https://catalyst-uat.ey.com") > -1) {
                return "EY Catalyst Connect (Beta)";
              } else if (origin.indexOf("https://catalyst-qa.ey.com") > -1) {
                return "EY Catalyst Connect (QA-Beta)";
              } else {
                return "EY Catalyst Connect (Dev)";
              }
            })()}
          </p>
        </div>

        {!loggedOut && (
          <div className="navBarTabs__right-section__tabs">
            {/* Home tab */}
            <Tab
              tabName="Home"
              onClick={() => {
                nav(Constant.REDIRECTION_PATH);
                handleSideNavbarDataChange("menu");
              }}
              active={activeTab == "Home"}
            />

            {/* settings tab */}
            <Tab
              tabName="Settings"
              onClick={() => {
                nav("/settings");
                handleSideNavbarDataChange("settings");
                ResetBreadCrumb3LevelTitle();
              }}
              active={activeTab == "Settings"}
            />

            {/* About tab */}
            {/* <Tab tabName="About" onClick={() => { }} /> */}
          </div>
        )}
      </div>
      {!loggedOut && (
        <div className="navBarTabs__left-section">
          <MenuButton
            trigger={
              <img
                className="navBarTabs__left-section__profile-image"
                src={ImageUtil(userInfo.displayName)}
                title={userInfo.displayName}
              />
            }
            menu={[
              {
                key: "Log out",
                onClick: () => {
                  //nav(`/logout`);
                  handleLogout();
                },
                content: (
                  <div className="logoutMenu">
                    <Image
                      alt=""
                      src={require("../../assets/logout.png")}
                      fit="cover"
                      className="navBarTabs__left-section__logout-image"
                    />
                    <p>Log out</p>
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default NavBar;
