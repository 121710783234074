import React, { useState, useEffect, useRef } from "react";
import { MdError } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { PiCaretDownBold } from "react-icons/pi";
import Multiselect from "multiselect-react-dropdown";
import DraggablePills from "../draggablepills/draggablePills";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import Constant from "../../../utility/constants";
import "./MultiSelectReactDropdown.css";

function MultiSelectReactDropdown(props) {
  const [selectedItems, setSelectedItems] = useState(
    props?.defaultCheckedValue
      ? props?.defaultCheckedValue?.length > 0
        ? props?.defaultCheckedValue
        : [props?.defaultCheckedValue]
      : []
  );
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const allValidations = props?.validations;
  const multiselectRef = useRef(null);

  useEffect(() => {
    // const handleScroll = (event) => {
    //   if (event.target == document) {
    //     if (
    //       multiselectRef?.current?.searchBox?.current?.id ==
    //       document.activeElement?.id
    //     ) {
    //       document.activeElement?.blur();
    //     }
    //   }
    // };

    // document.addEventListener("scroll", handleScroll, true);
    if (props?.isTileAction) {
      const divElement = document.querySelector(".searchBox ");
      if (divElement) {
        divElement.readOnly = true;
      }
    }
    blockBackspaces();
    // return () => {
    //   document.removeEventListener("scroll", handleScroll, true);
    // };
  }, []);

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    if (props.editMode == true) {
      setSelectedItems(props?.selectedValues);
      props.updateValidationState(
        props?.name,
        props?.selectedValues,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    } else if (
      props?.isPolls == true ||
      props?.isFromCreateReport == true ||
      props?.clearData == true ||
      props?.isLibrarySearch == true
    ) {
      //console.log(props?.selectedValues);
      setSelectedItems(props?.selectedValues);
    }
  }, [props?.selectedValues]);

  useEffect(() => {
    if (props?.defaultCheckedValue && props?.isLibrarySearch == true) {
      setSelectedItems(props?.defaultCheckedValue);
    }
  }, [props?.defaultCheckedValue]);

  const handleSelection = (items, fieldsName, name, selectedItem, action) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        items
      );
      setError(hasError);
      setErrorMessage(errMessage);
      setSelectedItems(items);
      props?.onSelect(
        items,
        fieldsName,
        name,
        hasAnyError,
        errMessage,
        props?.parentName,
        props?.isTileAction
      );
    } else {
      if (action == "remove") {
        if (selectedItem.value == Constant.SELECTALL) {
          setSelectedItems([]);
          props?.onSelect(
            [],
            fieldsName,
            name,
            false,
            "",
            props?.parentName,
            props?.isTileAction
          );
        } else {
          setSelectedItems(
            items.filter((item) => {
              return item.value != Constant.SELECTALL;
            })
          );
          props?.onSelect(
            items.filter((item) => {
              return item.value != Constant.SELECTALL;
            }),
            fieldsName,
            name,
            false,
            "",
            props?.parentName,
            props?.isTileAction,
            selectedItem
          );
        }
      } else {
        if (items.some((item) => item.value == Constant.SELECTALL)) {
          setSelectedItems(props?.options);
          props?.onSelect(
            props?.options,
            fieldsName,
            name,
            false,
            "",
            props?.parentName,
            props?.isTileAction
          );
        } else {
          if (props?.isTileAction == true) {
            if (items.some((item) => item.label?.toLowerCase() == "none")) {
              let filteredArr = items?.filter(
                (option) => option.label?.toLowerCase() == "none"
              );
              setSelectedItems(filteredArr);
              props?.onSelect(
                filteredArr,
                fieldsName,
                name,
                false,
                "",
                props?.parentName,
                props?.isTileAction
              );
            } else {
              setSelectedItems(items);
              props?.onSelect(
                items,
                fieldsName,
                name,
                false,
                "",
                props?.parentName,
                props?.isTileAction
              );
            }
          } else {
            setSelectedItems(items);
            props?.onSelect(
              items,
              fieldsName,
              name,
              false,
              "",
              props?.parentName,
              props?.isTileAction
            );
          }
        }
      }
    }
  };
  const onDragComplete = (result) => {
    document.activeElement?.blur();

    if (!result.destination) return;

    const arr = [...selectedItems];

    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    setSelectedItems(arr);
    if (props?.onSelect != undefined) {
      props?.onSelect(
        arr,
        props.fieldsName,
        props.name,
        false,
        "",
        props?.parentName,
        props?.isTileAction
      );
    }
  };
  const onClickRemoveIcon = (item) => {
    if (!props?.isTileAction) {
      document.activeElement?.blur();
    }
    let arr = selectedItems?.filter(
      (category) => category !== item && category.value != Constant.SELECTALL
    );
    setSelectedItems(arr);
    if (props?.onRemove != undefined) {
      props?.onRemove(
        arr.filter((item) => {
          return item.value != Constant.SELECTALL;
        }),
        props.fieldsName,
        props.name,
        false,
        "",
        props?.parentName,
        props?.isTileAction
      );
    }
  };

  const blockBackspaces = () => {
    try {
      const allSearchInputs = document.querySelectorAll("[id=search_input]");
      if (allSearchInputs.length === 0) {
        setTimeout(() => {
          blockBackspaces();
        }, 1000);

        return;
      }
      allSearchInputs.forEach((searchbox) => {
        searchbox.addEventListener("keydown", (event) => {
          if (searchbox.value.length === 0 && event.key === "Backspace") {
            event.stopPropagation();
            event.preventDefault();
            return false;
          }
        });
      });
    } catch {
      blockBackspaces();
    }
  };
  return (
    <>
      <div className="multiselectdivlabel" id={props?.id}>
        {props?.additionalMessage !== "" && (
          <span className="additionalMessage">{props?.additionalMessage}</span>
        )}
      </div>
      {props?.isContentCuration && (
        <DraggablePills
          list={
            selectedItems &&
            selectedItems?.filter((item) => {
              return item.value != Constant.SELECTALL;
            })
          }
          circular={true}
          color={"black"}
          removeItem={onClickRemoveIcon}
          onDragComplete={onDragComplete}
          isDragDisabled={props?.isDragDisabled}
          disablePills={props?.disable}
          className={
            props.className == "multiselectReportClass"
              ? "dragdropContainer-marginLeft"
              : ""
          }
        />
      )}

      <Multiselect
        showArrow={props?.showArrow}
        customCloseIcon={<MdClose />}
        customArrow={<PiCaretDownBold />}
        options={props?.options}
        selectedValues={selectedItems}
        displayValue={props?.displayValue}
        showCheckbox={props?.showCheckbox}
        groupBy={props?.groupBy}
        selectionLimit={props?.selectionLimit}
        placeholder={props?.placeholder}
        hideSelectedList={props?.hideSelectedList}
        emptyRecordMsg={props?.emptyRecordMsg}
        ref={multiselectRef}
        onSelect={(selectedList) => {
          handleSelection(selectedList, props.fieldsName, props.name);
        }}
        onRemove={(selectedList, selectedItem) => {
          handleSelection(
            selectedList,
            props.fieldsName,
            props.name,
            selectedItem,
            "remove"
          );
        }}
        disable={props?.disable}
        keepSearchTerm={true}
        className={`${props?.className} ${
          hasAnyError ? "multiselect_error" : "multiselectClass"
        } ${
          selectedItems?.filter((item) => item?.label?.toLowerCase() == "none")
            ?.length > 0
            ? "disableSelectOptions"
            : ""
        }`}
        closeOnSelect={props?.closeOnSelect}
      />
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
}

export default MultiSelectReactDropdown;
