import React, { useEffect, useState } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import "./dropdown.css";

function DropdownComponent(props) {
  const [curSelection, setCurrentSelection] = useState(
    props?.defaultCheckedValue
  );
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const allValidations = props?.validations;

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    if (props?.editMode) {
      setCurrentSelection(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    } else {
      setCurrentSelection(props?.value);
    }
  }, [props?.value, props?.editMode]);

  const handleDropDown = (ev, val, fieldName, isCustomField) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        val.value
      );
      setError(hasError);
      setErrorMessage(errMessage);
      setCurrentSelection(val.value);
      props?.onDropDownChange(
        val.value,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.parentName,
        hasError,
        errMessage
      );
    } else {
      if(props?.page == "LibrarySearchForm")
      {
        setCurrentSelection(val.value?.value || val.value?.text);
      }
      else
      {
        setCurrentSelection(val.value);
      }     
      props?.onDropDownChange(
        val.value,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.parentName,
        false,
        ""
      );
    }
  };
  
  useEffect(() => {
    if(props?.defaultCheckedValue && props?.isLibrarySearch == true){
      setCurrentSelection(props?.defaultCheckedValue);
    }
  }, [props?.defaultCheckedValue]);

  useEffect(() => {
    if (props?.initialLoad === "" || props?.initialLoad == null)
      props?.onDropDownChange(
        curSelection,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.parentName,
        false,
        ""
      );
  }, [props?.initialLoad]);

  return (
    <>
      <div className="formDropdownStyles">
        <Dropdown
          search={props?.search}
          checkable={props?.checkable}
          multiple={props?.multiple}
          defaultValue={props.defaultCheckedValue}
          items={props?.items}
          placeholder={props?.placeholder}
          disabled={props?.disabled}
          loading={props?.loading}
          loadingMessage={props?.loadingMessage}
          fluid={props?.fluid}
          className={`dropdownStyles ${hasAnyError ? "dropdown_error" : ""}`}
          value={curSelection}
          onChange={(ev, data) => {
            handleDropDown(ev, data);
          }}
          data-testid="dropdowntestid"
          id={props?.id}
        />
      </div>
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
}

export default DropdownComponent;
