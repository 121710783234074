import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { BreadCrumbContext } from "../../../context/breadcrumbcontext/BreadCrumbContext";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { useHamburgerMenuContext } from "../../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import Badge from "../badge/badge";
import "./subMenu.css";

const SubMenu = ({
  subMenu,
  menuState,
  handleMenuChange,
  menuId,
  menuTitle,
  setShowMenu,
  ChangeTabs,
  toggleSubMenu,
}) => {
  let location = useLocation();
  let [scrollHeight, setScrollHeight] = useState(-57);
  const { notificationMsgsData } = UseToasterNotificationsContext();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { cardTitle } = useContext(BreadCrumbContext);
  const { userInfo } = UseUserInfoContext();

  setShowMenu(true); // for content author changes
  useEffect(() => {
    try {
      setTimeout(() => {
        let topbarHeight = document
          ?.getElementById("topbar")
          ?.getBoundingClientRect()?.height;
        //let headerHeight = document.getElementById("common_container_header").getBoundingClientRect().height
        userInfo.loggedInWeb === true
          ? setScrollHeight(-topbarHeight - 50)
          : setScrollHeight(-topbarHeight);
      }, 1000);
    } catch {}
  }, []);

  useEffect(() => {
    try {
      // let topbarHeight = document.getElementById("adjustToaster").getBoundingClientRect().height
      //let headerHeight = document.getElementById("common_container_header").getBoundingClientRect().height
      let headerHeight = notificationMsgsData.showMsgBar ? 50 : 0;
      userInfo.loggedInWeb === true
        ? setScrollHeight(-(57 + headerHeight + 50))
        : setScrollHeight(-(57 + headerHeight));
    } catch {}
  }, [notificationMsgsData.showMsgBar]);

  const switchLink = () => {
    if (
      location.pathname.includes("settings") ||
      location.pathname.includes("contentCuration") ||
      location.pathname.includes("details") ||
      location.pathname.includes("search")
    ) {
      return true;
    } else if (cardTitle !== "") {
      return true;
    } else {
      return menuState.menu.name !== menuTitle;
    }
  };

  const handleTabKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleTabChange();
      event.preventDefault();
    }
  };

  const handleTabChange = () => {
    // if (location.pathname.includes("search")) {
    //   app.getContext().then((item) => {
    //     let pageState = {
    //       from: "SearchTabSidebar",
    //       to: subMenu.to,
    //     };
    //     let parms = {
    //       // @ts-ignore
    //       tabName: "Home",
    //       appId: Constant.GetUserAppId(item.user.userPrincipalName),
    //       pageId: Constant.HomeTab,
    //       subPageId: pageState,
    //     };
    //     pages.navigateToApp(parms);
    //   });
    // } else
    handleMenuChange(
      menuId,
      menuTitle,
      subMenu.id,
      subMenu.title,
      "",
      subMenu.to
    );
  };

  useEffect(() => {
    if (toggleSubMenu) {
      var div = document.getElementById(subMenu?.id);
      //console.log(div.offsetTop);
      if (div.offsetTop > window.innerHeight) {
        div.scrollIntoView({ block: "end" });
      }
    }
  }, [toggleSubMenu]);

  return (
    <>
      {switchLink() ? (
        <Link
          tabIndex={toggleSubMenu ? 0 : -1}
          className={`submenu_name add_padding  ${
            subMenu?.id === menuState.submenu.id ? "active_submenu" : ""
          }`}
          id={subMenu?.id}
          to={subMenu.to}
          onClick={handleTabChange}
        >
          <span>{subMenu?.title}</span>
          {subMenu?.badge && <Badge badge={subMenu.badge} />}
        </Link>
      ) : (
        <ScrollLink
          tabIndex={toggleSubMenu ? 0 : -1}
          className="submenu_name add_padding"
          activeClass="active_submenu"
          spy={true}
          id={subMenu?.id}
          // isDynamic={true}
          to={subMenu.to}
          href={subMenu.to}
          offset={scrollHeight}
          onSetActive={() => {
            //check for mobile device
            // if (window.innerWidth <= 900) {
            //   if(toggleHamburger){
            //     handleHamburgerChange();
            //   }
            // }
            handleMenuChange(
              menuId,
              menuTitle,
              subMenu.id,
              subMenu.title,
              "",
              subMenu.to
            );
          }}
        >
          <span>{subMenu?.title}</span>
          {subMenu?.badge && <Badge badge={subMenu.badge} />}
        </ScrollLink>
      )}
    </>
  );
};

export default SubMenu;
