import React, { useState, useEffect } from "react";
import { app, pages } from "@microsoft/teams-js";
import DialogBox from "../dialogBox/DialogBox";
import Constant from "../../utility/constants";

const ResponseStatusHandler = (props) => {
  const [showAlertMessage, setAlertMessageisibility] = useState(true);

  useEffect(() => {
    app.initialize();
  }, []);

  return (
    <DialogBox
      closeOnOutsideClick={false}
      confirmButton={"Ok"}
      onConfirmMethod={() => {
        app.getContext().then((item) => {
          if (item.page.id != Constant.HomeTab) {
            let parms = {
              // @ts-ignore
              appId: Constant.GetUserAppId(item.user.userPrincipalName),
              pageId: Constant.HomeTab,
            };
            pages.navigateToApp(parms);
          } else {
            window.location.href = Constant.REDIRECTION_PATH;
          }
        });
      }}
      content={
        <p>
          <br />
          Permissions have been revised, please click Ok to refresh the
          application.
          <br />
        </p>
      }
      headerName={"Some changes occurred!"}
      backdrop={true}
      openDialog={showAlertMessage}
      className={"dialogStyles yelloBtn"}
    />
  );
};

export default ResponseStatusHandler;
