import { useState } from "react";
import axios from "axios";
import { createRoot } from "react-dom/client";
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import APIEndPoints from "../utility/apiendpoints";
import { getData } from "./customApis";
import UnauthorizedPage from "../commonui/unauthorizedpage/unauthorized";

function PostWithProgressEvent() {
  const [progress, setProgress] = useState(0);

  const uploadForm = async (formData, response, url, contentype) => {
    setProgress(0);

    try {
      let _requestToken = await getData(APIEndPoints.REQUEST_TOKEN);
      let header: Partial<any> = {
        common: {
          Authorization: response.headers.common.Authorization,
        },
        "Content-Type": "",
        "X-RequestToken":
          _requestToken.data.requestToken + "_" + _requestToken.data.requestId,
      };
      if (contentype) {
        header["Content-Type"] = "multipart/form-data";
      } else {
        if (header["Content-Type"]) {
          delete header["Content-Type"];
        }
        header["Content-Type"] = "application/json";
      }
      return await axios.post(url, formData, {
        headers: header,
        // maxBodyLength   : Infinity,
        // maxContentLength: Infinity,
        // maxRedirects:0,
        onUploadProgress: (progressEvent) => {
          const total =
            progressEvent?.total === undefined ? 100 : progressEvent?.total;
          const progress = (progressEvent.loaded / total) * 100;
          // console.log("Progress", progress);
          setProgress(progress);
        },
      });
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        const rootElement = document.getElementById("root")!;
        const root = createRoot(rootElement);
        root.render(
          <Provider theme={teamsTheme}>
            <UnauthorizedPage />
          </Provider>
        );
      } else {
        throw error;
      }
    }
  };
  return { uploadForm, progress };
}

export default PostWithProgressEvent;
