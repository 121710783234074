import React, { useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { MdClose } from "react-icons/md";
import { formatDecodedURLs } from "../../../utility/utility";
import { UseformDataContext } from "../../../context/genericdatacontext/formdatacontext";
import "./linkPreview.css";

const LinkPreviewComponent = (props) => {
  // const { /*img,*/ title, link } = props;
  const title =
    props?.contentType == 7 ? formatDecodedURLs(props?.title) : props?.title;
  const link = props?.link;
  const [showLinkPreview, SetShowLinkPreview] = useState(true);
  const { handleFormDataIuput } = UseformDataContext();
  const [editor] = useLexicalComposerContext();

  const handleShowLinkPreview = () => {
    SetShowLinkPreview(!showLinkPreview);
    editor.update(() => {
      props?.linkPreviewNode?.remove();
    });
    handleFormDataIuput({
      isImageClosed: true,
    });
  };
  return (
    <>
      {showLinkPreview ? (
        <div className="linkPreviewWrapper">
          <img
            className="linkImage"
            src={`${
              props.img !== ""
                ? props.img
                : require("../../../assets/EY_Card_Banner.png")
            }`}
            alt=""
          />

          <div className="linkTitleUrl">
            <div className="linkTitle">
              {title != undefined && title.length >= 20
                ? title.slice(0, 19) + "..."
                : title != undefined && title != ""
                ? title.replaceAll("_", " ")
                : ""}
            </div>
            <div className="linkUrl">{link}</div>
          </div>
          <div
            tabIndex={0}
            className="linkPreviewCloseBtn"
            onClick={handleShowLinkPreview}
            title="Clear"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleShowLinkPreview();
              }
            }}
          >
            <MdClose data-testid="linkPrevCloseIcon" />
          </div>
        </div>
      ) : (
        ""
        // <div className="toolTip" onClick={handleShowLinkPreview}>
        //   <MdKeyboardArrowUp className="showLinkPreview" />
        //   <p>Show Link Preview</p>
        // </div>
      )}
    </>
  );
};

export default LinkPreviewComponent;
