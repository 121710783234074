import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import {
  models,
  Report,
  Embed,
  service,
  Page,
  IReportEmbedConfiguration,
} from "powerbi-client";
import { useHamburgerMenuContext } from "../../../../../context/menucontext/menuContext";
import "./pbiReport.css";

const PowerBiReport = (props) => {
  const { toggleHamburger } = useHamburgerMenuContext();
  const [isReportLoaded, setReportLoaded] = useState(false);

  const [repConfig, setConfig] = useState({
    type: "report",
    embedUrl: undefined,
    id: undefined,
    hostname: "https://app.powerbi.com",
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      layoutType:
        window.innerWidth > 768
          ? models.LayoutType.Custom
          : models.LayoutType.MobilePortrait,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth,
      },
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    if (props.item) {
      setConfig({
        ...repConfig,
        embedUrl: props.item.embedUrl,
        accessToken: props.item.accessToken,
        id: props.item.id,
      });
    }
  }, [props.item]);

  useEffect(() => {
    if (repConfig.embedUrl) {
      const handleOrientationChange = async (orientation) => {
        try {
          if (orientation.currentTarget.type.indexOf("portrait") > -1) {
            const newSettings = {
              layoutType: models.LayoutType.MobilePortrait,
            };
            const response = await window.report?.updateSettings(newSettings);
            return response;
          } else {
            const settings = {
              panes: {
                filters: {
                  expanded: true,
                  visible: true,
                },
              },
            };
            const response = await window.report?.updateSettings(settings);

            return response;
          }
        } catch (err) {
          console.log(err);
        }
      };

      window.screen.orientation.addEventListener(
        "change",
        handleOrientationChange
      );

      return () => {
        window.screen.orientation.removeEventListener(
          "change",
          handleOrientationChange
        );
      };
    }
  }, [repConfig.embedUrl]);

  return (
    <div className="App-Report">
      <header className="App-header">
        <PowerBIEmbed
          embedConfig={repConfig}
          eventHandlers={
            new Map([
              [
                "loaded",
                () => {
                  console.log("Report loaded");
                  props.closeLoader();
                  setReportLoaded(true);
                },
              ],
              [
                "rendered",
                async () => {
                  console.log("Report rendered");
                  // const dataSource = props.pinLibraryData.description;

                  // if (dataSource.toLowerCase().includes("xml")) {
                  //   //hide this 7bfe5b8bd8815e7d4120 visual, if data source is ITTS engine
                  //   const pages = await window.report.getPages();
                  //   const activePage = pages.filter((page) => page.isActive)[0];
                  //   const visuals = await activePage.getVisuals();
                  //   const visual = visuals.find(
                  //     (visual) => visual.name === "7bfe5b8bd8815e7d4120"
                  //   );
                  //   if (visual) {
                  //     await visual.setVisualDisplayState(
                  //       models.VisualContainerDisplayMode.Hidden
                  //     );
                  //     console.log("Target visual hidden:", visual);
                  //   } else {
                  //     console.log("Target visual not found");
                  //   }
                  // }
                },
              ],
              [
                "visualClicked",
                (event) => {
                  console.log("visualClickedEvent", event.detail);
                },
              ],
              [
                "visualRendered",
                (event) => {
                  console.log("visualRenderedEvent", event);
                },
              ],
              [
                "selectionChanged",
                (event) => {
                  console.log("selectionChangedEvent", event.detail);
                },
              ],
              [
                "pageChanged",
                (event) => {
                  console.log("pageChangedEvent", event.detail.newPage);
                },
              ],
              [
                "buttonClicked",
                (event) => {
                  console.log("buttonClickedEvent", event.detail);
                },
              ],
              [
                "commandTriggered",
                (event) => {
                  console.log("commandTriggeredEvent", event);
                },
              ],
              [
                "dataHyperlinkClicked",
                (event) => {
                  console.log("dataHyperlinkClickedEvent", event);
                },
              ],
              [
                "dataSelected",
                (event) => {
                  console.log("dataSelectedEvent", event.detail);
                },
              ],
              [
                "error",
                (event) => {
                  console.log("error", event.detail);
                },
              ],
            ])
          }
          cssClassName={`Embed-container ${
            toggleHamburger == false &&
            isReportLoaded == true &&
            "hamburgerclosed"
          }`}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </header>
    </div>
  );
};

export default PowerBiReport;
