import React, { useState, useEffect } from "react";
import { AiOutlineExclamationCircle, AiFillLock } from "react-icons/ai";
import { ImUser, ImUserPlus } from "react-icons/im";
import { HiUserGroup } from "react-icons/hi2";
import { RadioGroup } from "@fluentui/react-northstar";
import Constant from "../../utility/constants";
import Content from "../../commonui/content/Content";
import PeoplePicker from "../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import ButtonPrimary from "../../commonui/button/buttonPrimary";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseManageAccessContext } from "../../context/manageaccesscontext/manageAccessContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { ImageUtil } from "../../utility/utility";
import APIEndPoints from "../../utility/apiendpoints";
import { getData, postData } from "../../services/customApis";
import CustomizedToolTip from "../../commonui/tooltip/toolTip";
import "./manageAccesscard.css";

function ManageAccesscard(props) {
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { handleAccessData } = UseManageAccessContext();
  const editPopup = props?.isReportEditAccess || props?.isDataFileEditAccess || props?.isVizualizationLibrary;  
  const fileId = props?.fileIDManageAccess;
  const openManageAccessDialog = props?.setOpenManageAccessDialog;
  const shouldHideOption = userInfo?.teams?.isInternalCompany;
  const arrMangeAccessAttention = [
    !shouldHideOption &&
      "File access is not included for client users under free subscription.",
    "File access must be manually granted for users newly added to channels and groups.",
  ].filter(Boolean);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
  const [userEmails, setUserEmailArray] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("1");
  const [loader, setLoader] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [initialUsers, setInitialUsers] = useState([]);
  const [createdByUserArray, setCreatedByUserArray] = useState([]);
  useEffect(() => {
    if (props.onSelectedUsersChange) {
      props.onSelectedUsersChange(selectedUsers);
    }
  }, [selectedUsers]);
  useEffect(() => {
    if (props.onLoadingChange) {
      props.onLoadingChange(props.loaderManageAccess || loader);
    }
  }, [props.loaderManageAccess, loader]);

  useEffect(() => {
    const getManageAccessData = async () => {
      setLoader(true);
      let endpoint = "";
      if (props?.isDataFileEditAccess) {
        endpoint = APIEndPoints.DATA_LIBRARY(
          userInfo?.teams?.companyId,
          fileId
        );
      } else if (props?.isReportEditAccess) {
        endpoint = APIEndPoints.GET_EXCEL_REPORTS(
          userInfo?.teams?.companyId,
          fileId
        );
      } else if (props?.isVizualizationLibrary) {
        endpoint = APIEndPoints.GET_VISUALIZATION_FILE_ACCESS_INFO(
          4,
          false,
          userInfo?.teams?.companyId
        );
      }

      try {
        const res = await postData(props.isVizualizationLibrary? {metaDataId: props.metaDataId}: {}, endpoint);
        let data;
        if (props?.isDataFileEditAccess) {
          data = res?.data?.dataLibraryFilesDto[0];
        } else if (props?.isReportEditAccess) {
          data = res?.data?.reportLibraryDetails[0];
        } else if (props?.isVizualizationLibrary) {
          data = res?.data;
        }

        if (data) {
          setCreatedBy(data?.createdBy.toLowerCase());
          const createdByUserIndex = data?.userDetails?.findIndex(user => user.email.toLowerCase() === data?.createdBy.toLowerCase());
          const createdByArray = createdByUserIndex !== -1 ? [data.userDetails[createdByUserIndex]] : [];
          setCreatedByUserArray(createdByArray);
          const fileCreatedBy = [
            {
              name: createdByArray?.[0]?.name ?? "",
              email: createdByArray?.[0]?.email ?? "",
              userProfileId: createdByArray?.[0]?.userProfileId ?? null,
            }
          ];
          const userDetails =
            data?.manageAccessType === 4 ? data?.userDetails : fileCreatedBy;
          setSelectedUsers(userDetails);

          setInitialUsers(data?.userDetails);
          setSelectedRadio(`${data?.manageAccessType}`);

          handleAccessData({
            userDetails,
            manageAccessType: parseInt(data?.manageAccessType),
            dataTypeId: parseInt(data?.dataTypeId),
            fileTypeId: parseInt(data?.fileTypeId),
          });
        }
      } catch (err) {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
        openManageAccessDialog(false);
      } finally {
        setLoader(false);
      }
    };
    if (editPopup) {
      getManageAccessData();
    }
  }, [editPopup]);

  useEffect(() => {
    if (!editPopup && selectedRadio === "1") {
      const currentUser = {
        name: userInfo?.displayName,
        email: userInfo?.email,
        userProfileId: 0,
      };
      setSelectedUsers([currentUser]);

      handleAccessData({
        userDetails: [currentUser],
        manageAccessType: parseInt(selectedRadio),
      });
    }
  }, [userInfo, selectedRadio]);

  const onUserChange = (item) => {
    setUserEmailArray(item);
    setIsAddButtonDisable(item.length === 0);
  };

  const addToUsersList = async () => {
    setIsLoading(true);
    try {
      const newUsers = [...selectedUsers];
      const newUserEmails = [...userEmails];
      let allUsersToAdd = [];

      for (const user of newUserEmails) {
        if (user?.content === "User Group") {
          const response = await getData(
            APIEndPoints.GET_CUSTOM_USER_GROUP_BY_ID(
              userInfo.teams.companyId,
              user.customUserGroupId ?? user.id
            )
          );

          const groupUsers = response?.data?.userDetails?.map((item) => ({
            name: item?.name,
            email: item?.emailId,
            userProfileId: 0,
          }));

          allUsersToAdd.push(...groupUsers);
        } else {
          allUsersToAdd.push({
            name: user?.name,
            email: user?.email,
            userProfileId: user?.id ? parseInt(user?.id) : 0,
          });
        }
      }

      const emailSet = new Set();
      const uniqueUsersToAdd = allUsersToAdd.filter((user) => {
        const email = user.email;
        if (emailSet.has(email)) {
          return false;
        } else {
          emailSet.add(email);
          return true;
        }
      });

      const finalUsersToAdd = uniqueUsersToAdd.filter((newUser) => {
        return !newUsers.some(
          (existingUser) => existingUser.email === newUser.email
        );
      });

      if (finalUsersToAdd.length < uniqueUsersToAdd.length) {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: "Already existing users are not allowed.",
          type: "warning",
          isFailed: false,
        });
      }

      const fileOwnerUser = finalUsersToAdd.filter(
        (user) => user.email.toLowerCase() === userInfo?.email.toLowerCase()
      );
      const nonFileOwnerUsers = finalUsersToAdd.filter(
        (user) => user.email.toLowerCase() !== userInfo?.email.toLowerCase()
      );

      if (fileOwnerUser.length > 0) {
        newUsers.unshift(...fileOwnerUser);
      }
      if (nonFileOwnerUsers.length > 0) {
        newUsers.push(...nonFileOwnerUsers);
      }

      const [firstUser, ...restUsers] = newUsers;
      restUsers.sort((a, b) => a.name.localeCompare(b.name));
      newUsers.splice(0, newUsers.length, firstUser, ...restUsers);

      setSelectedUsers(newUsers);
      handleAccessData({ userDetails: newUsers });
      setUserEmailArray([]);
      setIsAddButtonDisable(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: Constant.SOMETHING_WENTWRONG,
        type: "fail",
        isFailed: false,
      });
    }
  };

  const handleRadioChange = (e, { value }) => {
    setSelectedRadio(value);
    let updatedUsers = [];
    const currentUser = [
      {
        name: userInfo?.displayName,
        email: userInfo?.email,
        userProfileId: 0,
      },
    ];
  
    const fileCreatedBy = [
        {
          name: createdByUserArray?.[0]?.name ?? "",
          email: createdByUserArray?.[0]?.email ?? "",
          userProfileId: createdByUserArray?.[0]?.userProfileId ?? null,
        }
      ];
    if (value === "1" || value === "2" || value === "3") {
      if((!editPopup)){
        updatedUsers = currentUser;
      }
      else{
        updatedUsers = fileCreatedBy;
      }
    } else if (editPopup && value === "4") {
      updatedUsers = initialUsers;
    } else {
      updatedUsers = selectedUsers;
    }

    const uniqueUsers = Array.from(
      new Set(updatedUsers.map((user) => user.email))
    ).map((email) => {
      return updatedUsers.find((user) => user.email === email);
    });
    console.log("setSelectedUsers data",uniqueUsers);
    setSelectedUsers(uniqueUsers);
    handleAccessData({
      manageAccessType: parseInt(value),
      userDetails: uniqueUsers,
    });
  };

  const removeUser = (index) => {
    const newUsers = [...selectedUsers];
    newUsers.splice(index, 1);
    setSelectedUsers(newUsers);
    handleAccessData({ userDetails: newUsers });
  };

  const radioItems = [
    {
      key: "1",
      label: (
        <div className="accessFileContainer">
          <div className="accessFileIcon green">
            <ImUser />
          </div>
          <div className="accessFileName" title="Only Me">Only Me</div>
        </div>
      ),
      value: "1",
      disabled: editPopup && userInfo?.email.toLowerCase() !== createdBy.toLowerCase(),
    },
    !shouldHideOption && {
      key: "2",
      label: (
        <div className="accessFileContainer">
          <div className="accessFileIcon blue">
            <AiFillLock />
          </div>
          <div className="accessFileName" title="All EY users of this channel">All EY users of this channel</div>
        </div>
      ),
      value: "2",
    },
    !shouldHideOption && {
      key: "3",
      label: (
        <div className="accessFileContainer">
          <div className="accessFileIcon violet">
            <HiUserGroup />
          </div>
          <div className="accessFileName" title="All users of this channel">All users of this channel</div>
        </div>
      ),
      value: "3",
    },
    {
      key: "4",
      label: (
        <div className="accessFileContainer">
          <div className="accessFileIcon grey">
            <ImUserPlus />
          </div>
          <div className="accessFileName" title="People and groups you choose">People and groups you choose</div>
        </div>
      ),
      value: "4",
    },
  ].filter(Boolean);

  return (
    <div className={`${editPopup ? "popUpCard" : ""}`}>
      <div
          className={`manageAccesscard 
            ${editPopup ? ((loader) ? "hideManageAccessCard" : "showManageAccessCard") : ""}
            ${
            shouldHideOption ? "internalUserCardHeight" : "clientUserCardHeight"
          }`}
        >
        <div className="manageAccessCardLeft">
          {!editPopup && (
            <div className="manageAccesscardHeader">Manage Access</div>
          )}

          <div className="shareFileAccessMsg">
            For whom would you like to share this file?
          </div>
          <div
            className="common_container_subtitle"
            style={{ border: 0, height: "auto", margin: "0px" }}
          >
            <div
              className="manageAccessAttention"
              style={{ display: "flex", alignItems: "center" }}
            >
              <AiOutlineExclamationCircle size={20} color="rgb(247, 99, 12)" />
              <span className="commonTitleEllipses showEllipses">
                {Constant.MANAGE_ACCESS.ATTENTION}
              </span>
            </div>
          </div>
          <div className="attentionMessageHeader">
            <Content listItems={arrMangeAccessAttention} />
          </div>

          <div className="manageAccessCheckboxs">
            <RadioGroup
              vertical
              defaultCheckedValue="1"
              items={radioItems}
              checkedValue={selectedRadio}
              onCheckedValueChange={handleRadioChange}
            />
          </div>

          {selectedRadio === "4" && (
            <div className="sendLinkPeoplePickerStyles">
              <PeoplePicker
                about="OK"
                search={true}
                multiple={true}
                placeholder={"Type the name of person, email id or user group"}
                checkable={true}
                fluid={true}
                userType={0}
                searchAd={false}
                searchUserGroup={true}
                onUserChange={onUserChange}
                fromCustomUserGroup={true}
                value={userEmails}
              />
              <ButtonPrimary
                className="addUsersButton"
                text={"Add"}
                onClick={addToUsersList}
                disabled={isAddButtonDisable || !(userEmails?.length > 0)}
              />
            </div>
          )}
        </div>
        <div className="manageAccessCardRight">
        {(!editPopup || selectedRadio === "4") && selectedUsers?.length > 0 &&
            selectedUsers.map((user, index) => (
              <div key={index} className="AddedUserList">
                <div className="usersList">
                  <div className="userAvatar">
                    <img
                      className="users-avatar"
                      src={ImageUtil(user.name ?? "N/A")}
                      alt=""
                    />
                  </div>
                  <div className="userDetails">
                  <CustomizedToolTip content={user.name ?? "N/A"} position="above" align="center">
                      <div className="userName">{user.name ?? "N/A"}</div>
                  </CustomizedToolTip>
                  <CustomizedToolTip content={user.email ?? "N/A"} position="below" align="center">
                      <div className="userMailId">{user.email ?? "N/A"}</div>
                  </CustomizedToolTip>
                  </div>
                </div>
                {selectedRadio === "4" &&
                  user.email.toLowerCase() !== userInfo?.email.toLowerCase() &&
                  user.email.toLowerCase() !== createdBy.toLowerCase() && (
                    <div
                      className="removeUser"
                      onClick={() => removeUser(index)}
                    >
                      &#10005;
                    </div>
                  )}
              </div>
            ))}
          {isLoading && <ReusableLoader />}
        </div>
      </div>
      {(props?.loaderManageAccess || loader) && (
        <div className={"manageAccessLoader"}>
          <ReusableLoader isOverlay={true} />
        </div>
      )}
    </div>
  );
}

export default ManageAccesscard;
