import React, { useEffect, useState } from "react";
import { Input, Dropdown } from "@fluentui/react-northstar";
import { Image } from "@fluentui/react-northstar";
import ConfirmationPage from "../../commonui/confirmationpage/confirmationpage";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { getData, postData } from "../../services/customApis";
import { TrackEvent } from "../../services/appInsights";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import TextArea from "../../commonui/commonformcomponents/textarea/TextArea";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { utf8ToBase64 } from "../../utility/utility";
import "./welcomepage.css";

const WelcomePage = () => {
  let { userInfo } = UseUserInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [incorrectState, setIncorrectState] = useState(false);
  const [issueDescription, setIssueDescription] = useState("");
  const [formState, setFormState] = useState({
    LoaderScreen: true,
    WelcomeScreen: false,
    MessageScreen: false,
  });
  const [messageState, setMessageState] = useState({
    Header: "",
    Image: "",
    Content: "",
    Content2: "",
  });
  const [inputFormState, setInputFormState] = useState({
    Name: "",
    Email: "",
    Country: "",
    Entity: "",
  });

  const [countries, setCountries] = useState(["United States"]);

  const [userInviteObj, setUserInviteObj] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: countries[0],
  });
  const [errorState, setErrorState] = useState({
    Description: {},
  });

  let isConfirmButtondisabled =
    userInfo?.isAdminConsentPresent === false
      ? userInviteObj.firstName === "" ||
        userInviteObj.lastName === "" ||
        userInviteObj.location === ""
      : false;

  useEffect(() => {
    if (userInfo.newUser) {
      if (
        userInfo?.isAdminConsentPresent === false &&
        (userInfo.user.entity == null || userInfo.user.entity == "")
      ) {
        setFormState({
          LoaderScreen: false,
          WelcomeScreen: false,
          MessageScreen: true,
        });
        adminConsentNotPresentAndDominIsNotMapped();
      } else {
        if (userInfo.userResponse == null || userInfo.userResponse == "") {
          if (userInfo.user.entity == null || userInfo.user.entity == "") {
            confirmMessage();
          } else {
            setFormState({
              LoaderScreen: false,
              WelcomeScreen: true,
              MessageScreen: false,
            });
            setInputFormState({
              Name: userInfo.user.name,
              Email: userInfo.user.email,
              Country: userInfo.user.country,
              Entity: userInfo.user.entity,
            });
          }
        } else {
          setFormState({
            LoaderScreen: false,
            WelcomeScreen: false,
            MessageScreen: true,
          });
          if (userInfo.userResponse == "CompanyNotFound") {
            organizationNotFound();
          } else if (userInfo.userResponse == "UserDenied") {
            incorrectwithDiscription();
          } else if (userInfo.userResponse == "AccessRequest") {
            waitingForApproval();
          }
        }
      }
    } else {
      setInputFormState({
        Name: "",
        Email: "",
        Country: "",
        Entity: "",
      });
    }
  }, [userInfo.newUser, userInfo.user.entity]);

  const confirmMessage = async () => {
    setIsLoading(true);
    let flagValue = true;
    if (userInfo?.loggedInWeb === null || userInfo?.loggedInWeb === undefined) {
      flagValue = true;
    } else {
      flagValue = userInfo?.loggedInWeb;
    }
    let payload = {
      userActionTypeId: 1,
      description: "",
      clientId: userInfo.user.clientId,
      isWebApp: flagValue,
    };
    if (
      userInfo.isAdminConsentPresent != null &&
      userInfo.isAdminConsentPresent === false
    ) {
      payload = {
        ...payload,
        firstName: userInviteObj.firstName,
        lastName: userInviteObj.lastName,
        country: userInviteObj.location,
      };
    }
    //console.log(payload);
    await postData(payload, APIEndPoints.SAVE_USERDETAILS)
      .then((res) => {
        setIsLoading(false);
        setFormState({
          LoaderScreen: false,
          WelcomeScreen: false,
          MessageScreen: true,
        });
        if (res.status === 200) {
          if (userInfo?.user?.entity !== null) {
            confirmOrganizationFound();

            TrackEvent(TelemetryEventNames.UCC_ONBOARD_CONFIRM, userInfo, {
              ExternalUser: userInfo?.newUser?.toString() ?? "",
              OnboardedUserName: userInfo?.user?.name?.toString() ?? "",
              OnboardedUserEmail: userInfo?.user?.email?.toString() ?? "",
              UPEOrganization: userInfo?.user?.entity?.toString() ?? "",
              Country: userInfo?.user?.country?.toString() ?? "",
            });
          } else {
            organizationNotFound();

            TrackEvent(TelemetryEventNames.UCC_ONBOARD_CMP_NOTFOUND, userInfo, {
              ExternalUser: userInfo?.newUser?.toString() ?? "",
              OnboardedUserName: userInfo?.user?.name?.toString() ?? "",
              OnboardedUserEmail: userInfo?.user?.email?.toString() ?? "",
              UPEOrganization: userInfo?.user?.entity?.toString() ?? "",
              Country: userInfo?.user?.country?.toString() ?? "",
            });
          }
        } else {
          genericError();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        catchError(err?.response?.data?.Errors[0]);
      });
  };

  const incorrectMessage = async () => {
    setIncorrectState(true);
  };

  const sendIncorrectMessage = async () => {
    let isValidated = true;
    let currentErrorState = { ...errorState };
    Object.keys(currentErrorState).forEach((key) => {
      if (currentErrorState[key]["hasAnyError"] === true) {
        isValidated = false;
      }
      if (
        currentErrorState[key]["currentValue"] == null ||
        currentErrorState[key]["currentValue"] == undefined ||
        currentErrorState[key]["currentValue"] == ""
      ) {
        isValidated = false;
        currentErrorState[key]["hasAnyError"] = true;
        currentErrorState[key]["errorMessage"] = "This information is required";
      }
    });
    if (isValidated === false) {
      setErrorState({ ...currentErrorState });
    } else {
      setIsLoading(true);
      let flagValue = true;
      if (
        userInfo?.loggedInWeb === null ||
        userInfo?.loggedInWeb === undefined
      ) {
        flagValue = true;
      } else {
        flagValue = userInfo?.loggedInWeb;
      }
      let payload = {
        userActionTypeId: 2,
        clientId: 0,
        description: utf8ToBase64(issueDescription?.toString()),
        isWebApp: flagValue,
      };
      await postData(payload, APIEndPoints.SAVE_USERDETAILS)
        .then((res) => {
          setIsLoading(false);
          setFormState({
            LoaderScreen: false,
            WelcomeScreen: false,
            MessageScreen: true,
          });
          if (res.status == 200) {
            incorrectwithDiscription();

            TrackEvent(TelemetryEventNames.UCC_ONBOARD_INCORRECT, userInfo, {
              ExternalUser: userInfo?.newUser?.toString() ?? "",
              OnboardedUserName: userInfo?.user?.name?.toString() ?? "",
              OnboardedUserEmail: userInfo?.user?.email?.toString() ?? "",
              UPEOrganization: userInfo?.user?.entity?.toString() ?? "",
              Country: userInfo?.user?.country?.toString() ?? "",
            });
          } else {
            genericError();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err?.response?.data?.Errors);
          catchError(err?.response?.data?.Errors[0]);
        });
    }
  };

  const onDescriptionChange = (
    e,
    name,
    controlName,
    cf,
    cof,
    pn,
    hasError,
    errMessage
  ) => {
    setIssueDescription(e.target.value);
    updateValidationState(controlName, e.target.value, hasError, errMessage);
  };

  const updateValidationState = (name, value, hasAnyError, errMessage) => {
    let updatedObj = {};
    let obj = {
      currentValue: value,
      hasAnyError: hasAnyError,
      errorMessage: errMessage,
    };
    updatedObj = {
      ...errorState,
      [name]: obj,
    };
    setErrorState({ ...updatedObj });
  };

  const backtoWelcomePage = async () => {
    setIncorrectState(false);
    setErrorState({ Description: {} });
  };

  const confirmOrganizationFound = async () => {
    setMessageState({
      Header: "Thanks for confirming your information!",
      Image: require("../../assets/ConfirmImage.png"),
      Content:
        "We will send you an email with next steps. We look forward to seeing you on EY Catalyst Connect!",
    });
  };

  const organizationNotFound = async () => {
    setMessageState({
      Header: "Thank you for your interest!",
      Image: require("../../assets/CompNotFound.png"),
      Content:
        "We will send you an email with next steps. We look forward to seeing you on EY Catalyst Connect!",
    });
  };

  const incorrectwithDiscription = async () => {
    setMessageState({
      Header: "Thank you!",
      Image: require("../../assets/IncorrectImage.png"),
      Content:
        "Someone will be reaching out to you by email to further assist. If you need assistance, contact us at eytaxcatalystsupport@ey.com",
    });
  };

  const catchError = async (err) => {
    setFormState({
      LoaderScreen: false,
      WelcomeScreen: false,
      MessageScreen: true,
    });
    setMessageState({
      Header: "Something went wrong!",
      Image: require("../../assets/GenericError.png"),
      Content: err,
    });
  };

  const genericError = async () => {
    setMessageState({
      Header: "Oops..",
      Image: require("../../assets/pagenotfoundturtle.png"),
      Content:
        "Something went wrong. Please try again later. For further assistance please reach out to us at eytaxcatalystsupport@ey.com",
    });
  };

  const handleChange = (e) => {
    setUserInviteObj({
      ...userInviteObj,
      [e.target.name]: e.target.value,
    });
  };
  const handleDropdownChange = (e, data) => {
    setUserInviteObj({
      ...userInviteObj,
      location: data.value,
    });
  };

  const waitingForApproval = async () => {
    setMessageState({
      Header: "Almost there!",
      Image: require("../../assets/ApprovaImage.png"),
      Content:
        "Your access is pending approval. Thank you for your patience while we review your request!",
      Content2: "Need help now? Contact us at eytaxcatalystsupport@ey.com",
    });
  };

  const adminConsentNotPresentAndDominIsNotMapped = async () => {
    setMessageState({
      Header: "Oops...",
      Image: require("../../assets/unauthorizedAccess.PNG"),
      Content:
        "We did not receive your consent to proceed with application usage. For further assistance please reach out to us at eytaxcatalystsupport@ey.com",
    });
  };

  return formState.LoaderScreen ? (
    <>{isLoading && <ReusableLoader isOverlay={true} isAppBody={true} />}</>
  ) : formState.WelcomeScreen ? (
    <>
      {isLoading && <ReusableLoader isOverlay={true} isAppBody={true} />}
      {/* <div className="useronboarding-welcomepage">
        <div className="onboarding-form">
          <div className="onboarding-form__heading">
            Welcome to
            <br /> EY Tax Catalyst
          </div>
          <br />
          <div className="onboarding-form__label">
            Confirm your information.
          </div>
          <div className="onboarding-form__input-container">
            <div className="onboarding-form__input">
              <Input label="Name" readOnly value={inputFormState.Name} />
              <Input label="Email" readOnly value={inputFormState.Email} />
              <Input label="Country" readOnly value={inputFormState.Country} />
              <Input
                label="Ultimate Parent Entity Organization"
                readOnly
                value={inputFormState.Entity}
              />

              <button
                className="onboarding-form__btn yellow-btn"
                onClick={() => confirmMessage()}
              >
                Confirm
              </button>
              <button
                className="onboarding-form__btn white-btn"
                onClick={() => incorrectMessage()}
              >
                Incorrect
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div class="login-container">
        <div class="login-form">
          <div class="form-header">
            <div class="form-header_image">
              <Image
                alt=""
                class="header-image"
                src={require("../../assets/EY_Disc_Logo.png")}
              />
            </div>
            <div className="form-header_heading">
              Welcome to
              <br /> EY Catalyst Connect
            </div>
          </div>

          {!incorrectState ? (
            <>
              <div className="form-group_label">Confirm your information.</div>
              <div class="form-group">
                <Input
                  label="Email"
                  class="form-input"
                  readOnly
                  value={inputFormState.Email}
                />
              </div>
              <div class="form-group">
                <Input
                  label="Company Name"
                  class="form-input"
                  readOnly
                  value={inputFormState.Entity}
                />
              </div>
              {userInfo?.isAdminConsentPresent !== null &&
              userInfo?.isAdminConsentPresent === false ? (
                <>
                  <div class="form-group">
                    <Input
                      label="First Name"
                      class="form-input additional-class"
                      onChange={handleChange}
                      name="firstName"
                      value={userInviteObj.firstName}
                    />
                  </div>
                  <div class="form-group">
                    <Input
                      label="Last Name"
                      class="form-input additional-class"
                      value={userInviteObj.lastName}
                      onChange={handleChange}
                      name="lastName"
                    />
                  </div>
                  <div class="form-group">
                    <Dropdown
                      items={countries}
                      multiple={false}
                      className="form-input additional-class"
                      fluid
                      placeholder="Select location"
                      checkable
                      onChange={handleDropdownChange}
                      getA11ySelectionMessage={{
                        onAdd: (item) => `${item} has been selected.`,
                      }}
                      value={userInviteObj.location}
                    />
                  </div>
                </>
              ) : (
                <div class="form-group">
                  <Input
                    label="Country"
                    class="form-input"
                    readOnly
                    value={inputFormState?.Country}
                  />
                </div>
              )}
              <div class="form-group">
                <button
                  class={`form-button ${
                    isConfirmButtondisabled ? "disabled-btn" : "yellow-btn"
                  }`}
                  disabled={isConfirmButtondisabled}
                  onClick={() => confirmMessage()}
                >
                  Confirm
                </button>
                <button
                  class="form-button white-btn"
                  onClick={() => incorrectMessage()}
                >
                  Incorrect
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="form-group_label2">
                Please provide a short description of <br /> what was incorrect.
              </div>
              <div class="form-group">
                <TextArea
                  placeholder={"Enter description here.."}
                  required={true}
                  height={"120px"}
                  onChange={onDescriptionChange}
                  value={issueDescription}
                  validations={[
                    {
                      name: "MaxLength",
                      maxChar: 2000,
                      errorMessage: "Length cannot be more than 2000",
                    },
                  ]}
                  name={"Description"}
                  hasAnyError={errorState?.["Description"]?.hasAnyError}
                  errMessage={errorState?.["Description"]?.errorMessage}
                />
              </div>
              <div class="form-group">
                <button
                  class="form-button yellow-btn"
                  onClick={() => sendIncorrectMessage()}
                >
                  Send
                </button>
                <button
                  class="form-button white-btn"
                  onClick={() => backtoWelcomePage()}
                >
                  Back
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <ConfirmationPage
      heading={messageState.Header}
      image={messageState.Image}
      content={messageState.Content}
      content2={messageState.Content2}
    />
  );
};

export default WelcomePage;
