import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./draggablePills.css";

function DraggablePills(props) {
  return (
    props?.list?.length > 0 && (
      <div>
        <DragDropContext onDragEnd={props?.onDragComplete}>
          <Droppable
            droppableId="drag-drop-list"
            direction="horizontal"
            className="selected-option-container"
          >
            {(provided, snapshot) => (
              <div
                className={`${props?.className} dragdropContainer ${
                  props?.disablePills ? "isPillDisabled" : ""
                }`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {props?.list?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.label}
                    index={index}
                    isDragDisabled={props?.isDragDisabled}
                  >
                    {(provided) => (
                      <div
                        className="selected-option-pill pillsClass"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>{item.label}</span>
                        <button
                          className={`remove-button`}
                          onClick={() => props?.removeItem(item)}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  );
}

export default DraggablePills;
