/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { UseformDataContext } from "../../context/genericdatacontext/formdatacontext";
import { UseDeeplinkContext } from "../../context/deeplincontext/deeplinkContext";
import emailTemplate from "../../data/emailtemplate/emailTemplate";
import { formatTextForADCard, buildDeepLink } from "../../utility/utility";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { resizeImage, formatEncodedURLs } from "../../utility/utility";
import Constant from "../../utility/constants";
import {
  getInitAdaptiveCard,
  setCardUpperText,
  setCardImageLink,
  setCardPreviewLink,
  setCardPreviewTitle,
} from "../../commonui/adaptivecards/adaptiveCard";
import * as AdaptiveCards from "adaptivecards";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { LinkPreviewNode } from "../../commonui/editor/nodes/LinkPreviewNode";
import { LinkNode } from "@lexical/link";
import {
  LinkPreviewPlugin,
  INSERT_LINKPREVIEW_COMMAND,
  BLUR_COMMAND,
} from "../../commonui/editor/plugins/LinkPreviewPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import "./textArea.css";

var md = require("markdown-it")({
  html: true,
  linkify: true,
});

let adCard = new AdaptiveCards.AdaptiveCard();
AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
  let updatedText = md.render(text);
  result.outputHtml = updatedText.replace(/\r/g, "").replace(/\n/g, "");
  result.didProcess = true;
};

// Change the props.item.uniqueid to props.metaDataId
const TextArea = (props) => {
  let { deeplinkUrl, teamslinkUrl, weblinkUrl } = UseDeeplinkContext();
  let { userInfo } = UseUserInfoContext();
  const ids = Math.random().toString(36);
  const [intialAdaptiveCard, setAdaptiveCard] = useState(getInitAdaptiveCard());
  const { formInput, handleFormDataIuput } = UseformDataContext();
  const [isFirstTime, setTriggerOnChangeRender] = useState(true);

  let url = "";
  if (props.contentType !== 4) {
    if (props?.item?.articleUrl !== undefined) {
      url = props?.item?.articleUrl;
    } else if (props?.item?.contentInfo[0]?.url !== "") {
      try {
        url = atob(props?.item?.contentInfo[0]?.url);
      } catch {
        url = props?.item?.contentInfo[0]?.url;
      }
    }
  }
  //console.log("final url " + url);
  const createLinks = (intialLink) => {
    let tempUrl = "";
    try {
      tempUrl = buildDeepLink(
        intialLink,
        props.contentType,
        userInfo.teams.companyId,
        props.item.metaDataId,
        url,
        props?.item?.contentInfo?.[0] != undefined
          ? props?.item?.contentInfo?.[0].contentSourceType
          : undefined,
        props.contentType == 4
          ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId !=
            null
            ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId
            : ""
          : "",
        props.contentType == 4
          ? props.item.contentInfo[0].content.requestJson.template != null
            ? props.item.contentInfo[0].content.requestJson.template.id
            : ""
          : "",
        props?.item?.pinDetail?.pinMetaDataId || props?.pinMetadataId,
        props?.isBulletinBoard,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : props?.item?.contentSecurity?.availability?.domainLevel ==
            Constant.LOCAL
          ? false
          : null
      );
      if (!userInfo?.teams?.isClientTenant) {
        deeplinkUrl = deeplinkUrl + "&tenantId=" + Constant.TENANT_ID;
      }
    } catch (e) {
      console.log(e);
    }
    return tempUrl;
  };

  deeplinkUrl = createLinks(deeplinkUrl);

  const editorConfig = {
    namespace: "linkpreview",
    editorState:
      '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1},{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"{REPLACE_DEEPLINK}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"link","version":1,"rel":"noopener noreferrer","target":"_blank","title":null,"url":"{ACTUAL_DEEPLINK}"}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1},{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1},{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}'
        .replace("{REPLACE_DEEPLINK}", deeplinkUrl)
        .replace("{ACTUAL_DEEPLINK}", deeplinkUrl),
    theme: {
      linkpreview: "linkpreview-container",
    },
    onError(error) {
      throw error;
    },
    nodes: [LinkPreviewNode, LinkNode],
  };
  //const deeplink = `https://teams.microsoft.com/l/entity/${env.TEAMS_APP_ID}/Orders?&context=${encodedContext}`;

  useEffect(() => {
    resizeImage(
      Constant.DEFAULT_IMAGE,
      props.img !== "" ? false : true,
      props?.compressionNeeded == undefined ? false : true,
      (img) => {
        const htmlContent = emailTemplate
          .replace(
            "{title}",
            props.contentType == 7
              ? decodeURIComponent(escape(window.atob(props.title)))
              : props.title
          )
          .replace("{image}", img)
          .replace("{summery}", "");

        handleFormDataIuput({
          catalystTextwithUrl: htmlContent,
          catalystImage: img,
          catalystTitle: props.title,
          catalystTextWithDeepLink: formatTextForADCard(deeplinkUrl, ""),
          catalystDetails: props.description,
          catalystContentDeepLink: formatEncodedURLs(createLinks(teamslinkUrl)),
          catalystContentWebLink: formatEncodedURLs(createLinks(weblinkUrl)),
          body: {
            contentType: "html",
            //content: htmlContent,
            content: updateAdaptiveCardContent(
              intialAdaptiveCard,
              formatTextForADCard(deeplinkUrl),
              true,
              img
            ),
          },
        });
      }
    );
    //setTriggerOnChangeRender(!isFirstTime);
  }, []);

  const handleOnChange = (messageText) => {
    let deeplinkWithMsg = formatTextForADCard(messageText);
    //console.log(formInput);
    let isClosed = messageText.includes(deeplinkUrl) ? false : true;
    handleFormDataIuput({
      catalystUserText: formatText(messageText),
      catalystTextWithDeepLink: deeplinkWithMsg,
      catalystContentDeepLink: formatEncodedURLs(createLinks(teamslinkUrl)),
      catalystContentWebLink: formatEncodedURLs(createLinks(weblinkUrl)),
      linkViewClosed: isClosed,
      body: {
        contentType: "html",
        content: updateAdaptiveCardContent(
          intialAdaptiveCard,
          deeplinkWithMsg,
          false
        ),
      },
    });
  };

  function onChange(editorState, editor) {
    editorState.read(() => {
      if (isFirstTime) {
        setTriggerOnChangeRender(!isFirstTime);
        editor.blur();
      } else {
        let textWithNewLineChar = editorState._nodeMap.get("root").__cachedText;
        handleOnChange(textWithNewLineChar);
      }
    });
  }

  const formatText = (text, message) => {
    let msg = "";
    let newString = text.replaceAll("\n\n", "<br/> ");
    if (newString.indexOf("https://") > -1) {
      let splitTheText = newString.split(" ");
      splitTheText.map((item) => {
        if (item.indexOf("https://") == -1) {
          msg = msg + " " + item;
        }
      });
    }
    return msg;
  };

  const updateAdaptiveCardContent = (card, text, initialLoad, img) => {
    if (initialLoad) {
      setCardUpperText(card, text);
      setCardImageLink(card, img);
      setCardPreviewTitle(card, props.item.title);
      setCardPreviewLink(card, text);
    } else {
      setCardUpperText(card, text);
    }
    adCard.parse(card);
    let renderedCard = adCard.render();
    return renderedCard.innerHTML;
    //return JSON.stringify(card);
  };

  return (
    <>
      <LexicalComposer initialConfig={editorConfig}>
        <LinkPreviewPlugin {...props} />
        <LexicalClickableLinkPlugin />
        <Toolbar />
        <div className="editor-container">
          <div className="editor-container__wrapper">
            <div
              className="editor-inner"
              style={{
                background: "#f3f2f1",
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    id={ids}
                    className="editor-input linkprev-controller"
                    style={{ minHeight: "200px" }}
                    spellCheck={false}
                    autoFocus={false}
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />

              <div className="font-mono text-xs whitespace-pre">
                <OnChangePlugin onChange={onChange} />
              </div>
            </div>
          </div>
        </div>
      </LexicalComposer>
    </>
  );
};
function Toolbar() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.dispatchCommand(INSERT_LINKPREVIEW_COMMAND, undefined);
    editor.dispatchCommand(BLUR_COMMAND, undefined);
  }, []);

  return <div className="my-2"></div>;
}

export default TextArea;
