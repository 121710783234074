import React, { useEffect } from "react";
import ComingSoon from "../../commonui/comminsoon/comingSoon";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import { useMenuContext } from "../../context/menucontext/menuContext";

const BillingDetails = (props) => {
  const { handleMenuInfo } = UseContactUsContext();
  const { menuState } = useMenuContext();
  useEffect(() => {
    handleMenuInfo({
      menuName: "Settings",
      subMenuName: menuState?.menu?.name,
      sourceComponent: "",
    });
  }, []);

  return <ComingSoon />;
};

export default BillingDetails;
