import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import MentionsPlugin from "./plugins/MentionsPlugin";
import React, { useEffect, useRef, useState } from "react";
import { MentionNode } from "./nodes/MentionNode";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import MenuButton from "../menubutton/menuButton";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import { HiOutlineCheck, HiOutlineX } from "react-icons/hi";
import { FormatIcon, SendIcon } from "@fluentui/react-northstar";
import { FaRegSurprise } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import Constant from "../../utility/constants";
import "./Editor.css";

function Placeholder(props) {
  return <div className="editor-placeholder">{props?.placeholder}</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    MentionNode,
    // EmojiNode
  ],
};

export default function Editor(props) {
  const {
    placeholder,
    showToolBar,
    getHTMLstring,
    setHTMLstring,
    editorBackgroundColor,
    edit,
  } = props;

  const [showToolbarTop, setShowToolbarTop] = useState(false);
  const [emojiHover, setEmojiHover] = useState(false);
  const [htmlString, setHtmlStrings] = useState("");
  const [clearEditor, setClearEditor] = useState(false);
  const [showMsgHint, setShowMsgHint] = useState(false);

  const ids = Math.random().toString(36);

  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13 && !showToolbarTop) {
      event.preventDefault();
      props?.edit ? editComment() : sendComment();
    }
  };
  const uniqueId = "emoji_" + uuidv4();
  const getHtmlString = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const anchorTags = doc.querySelectorAll("a");

    anchorTags.forEach((tag) => {
      if (!tag.hasAttribute("target")) {
        tag.setAttribute("target", "_blank");
      }

      if (!tag.hasAttribute("rel")) {
        tag.setAttribute("rel", "noopener noreferrer");
      } else {
        const currentRel = tag.getAttribute("rel");
        if (!currentRel.includes("noopener")) {
          tag.setAttribute("rel", `${currentRel} noopener`);
        }
        if (!currentRel.includes("noreferrer")) {
          tag.setAttribute("rel", `${currentRel} noreferrer`);
        }
      }
    });

    const modifiedHtmlString = doc.body.innerHTML;
    setHtmlStrings(modifiedHtmlString);

    showMsgHint &&
      setShowMsgHint(
        modifiedHtmlString ===
          `<p class="editor-paragraph"></p><p class="editor-paragraph"><br></p>` ||
          modifiedHtmlString === `<p class="editor-paragraph"></p>` ||
          modifiedHtmlString === `<p class="editor-paragraph"><br></p>`
      );
  };
  const sendComment = () => {
    let tempHtmlString = showToolbarTop
      ? htmlString
      : htmlString.replace("<br></p>", "</p>");
    props.getHTMLstring(tempHtmlString);
    setClearEditor((prev) => !prev);
    setShowToolbarTop(false);
    setShowMsgHint(
      tempHtmlString ===
        `<p class="editor-paragraph"></p><p class="editor-paragraph"><br></p>` ||
        tempHtmlString === `<p class="editor-paragraph"></p>` ||
        tempHtmlString === `<p class="editor-paragraph"><br></p>`
    );
  };

  const editComment = () => {
    let tempHtmlString = showToolbarTop
      ? htmlString
      : htmlString.replace("<br></p>", "</p>");
    props.getHTMLstring(tempHtmlString, props?.item);
    setClearEditor((prev) => !prev);
    setShowToolbarTop(false);
    setShowMsgHint(
      tempHtmlString ===
        `<p class="editor-paragraph"></p><p class="editor-paragraph"><br></p>` ||
        tempHtmlString === `<p class="editor-paragraph"></p>` ||
        tempHtmlString === `<p class="editor-paragraph"><br></p>`
    );
  };

  return (
    <>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container">
          {showMsgHint && (
            <div className="type_msg">
              <p>{Constant.TYPE_MESSAGE}</p>
            </div>
          )}
          <div className="editor-container__wrapper">
            <ToolbarPlugin
              {...props}
              showToolbarTop={showToolbarTop}
              getHtmlString={getHtmlString}
              setClearEditor={setClearEditor}
              clearEditor={clearEditor}
            />

            <div
              className="editor-inner"
              style={{
                background:
                  editorBackgroundColor != undefined
                    ? editorBackgroundColor
                    : "#f3f2f1",
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    id={ids}
                    className="editor-input"
                    style={showToolbarTop ? { minHeight: "110px" } : {}}
                    onKeyDown={handleEnterKeyPress}
                  />
                }
                placeholder={<Placeholder placeholder={placeholder} />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <MentionsPlugin />
              <AutoFocusPlugin />
              <CodeHighlightPlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <TablePlugin />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            </div>
          </div>
          {showToolBar && (
            <div className="toolbar-bottom">
              <div className="toolbar-bottom__left">
                <div>
                  <FormatIcon
                    tabIndex={0}
                    className={`toolbar-bottom__icon ${
                      showToolbarTop ? "toolbar-bottom__icon--active" : ""
                    }`}
                    onClick={() => setShowToolbarTop((prev) => !prev)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setShowToolbarTop((prev) => !prev);
                      }
                    }}
                  />
                </div>
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      const event = new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                      });
                      document.getElementById(uniqueId).dispatchEvent(event);
                    }
                  }}
                >
                  <MenuButton
                    trigger={
                      emojiHover ? (
                        <FaRegSurprise
                          className="toolbar-bottom__icon"
                          onMouseLeave={() => setEmojiHover(false)}
                        />
                      ) : (
                        <HiOutlineFaceSmile
                          className="toolbar-bottom__icon"
                          onMouseEnter={() => setEmojiHover(true)}
                          id={uniqueId}
                        />
                      )
                    }
                    menu={[<EmojiPickerPlugin />]}
                  />
                </div>
              </div>
              <div className="toolbar-bottom__right">
                {edit ? (
                  <>
                    <div className="edit-cursor">
                      <HiOutlineX onClick={props?.onChangeToggleEdit} />
                    </div>
                    <div className="edit-cursor">
                      <HiOutlineCheck
                        onClick={editComment}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            editComment();
                          }
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <SendIcon
                    tabIndex={0}
                    className="toolbar-bottom__icon"
                    onClick={sendComment}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendComment();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </LexicalComposer>
    </>
  );
}
