import React from "react";
import { Alert as AlertComponent } from "@fluentui/react-northstar";
import "./alert.css";

function Alert(props) {
  return props?.visible ? (
    <div className={`alertStyles ${props?.className ? props?.className : ""}`}>
      <AlertComponent
        content={props?.content}
        visible={props?.visible}
        danger={props?.danger}
        warning={props?.warning}
        info={props?.info}
        dismissible={props?.dismissible}
        dismissAction={{
          onClick: props?.onClickDismiss,
        }}
      />
    </div>
  ) : null;
}

export default Alert;
