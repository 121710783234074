import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  useHamburgerMenuContext,
  useMenuContext,
} from "../../context/menucontext/menuContext";
import { CompanySelectorThemeProvider } from "../companyselector/CompanySelectorThemeProvider";
import BreadCrumb from "../breadcrumb/breadCrumb";
import { GrClose } from "react-icons/gr";
import NotificationIcon from "../notification/notificationIcon";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import ContactUsIcon from "../../components/contactus/contactUsIcon";
import { MdClose, MdGroup } from "react-icons/md";
import { FaShareSquare } from "react-icons/fa";
import {
  checkIfDeviceIsMob,
  getContentTypeNamesById2,
  getUrlParams,
  getMenuNamesBySubmenuId,
} from "../../utility/utility";
import { useNavigate } from "react-router-dom";
import TakeaTour from "../../components/takeatour/takeatour";
import UploadChanneLogo from "../../components/uploadchannelogo/uploadchannelogo";
import { deleteDataByPayload, getData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import { app } from "@microsoft/teams-js";
import { Image } from "@fluentui/react-northstar";
import Constant from "../../utility/constants";
import { sharing } from "@microsoft/teams-js";
import "../polls/pollsCurationForm/pollPopup.css";
import CustomizedToolTip from "../../commonui/tooltip/toolTip";
import { Web_Version_Message } from "../../commonui/staticDialogMessage/staticDialogMessage";
import { UseMyShortcutsContext } from "../../context/myshortcutcontext/myShortcutsContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { FaTrash } from "react-icons/fa";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import "./topBar.css";

const TopBar = (props) => {
  const handleHamburgerKeyDown = (event) => {
    if (event.keyCode === 13 || 32) {
      handleHamburgerChange();
    }
  };
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);
  const { handleHamburgerChange } = useHamburgerMenuContext();
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const navigate = useNavigate();
  const [webVersionDisable, setWebVersionDisable] = useState(false);
  const [showMyShortcuts, setshowMyShortcuts] = useState(true);
  const { myShortcutsData, handleMyShortcutsData } = UseMyShortcutsContext();
  const [isFetching, setIsFetching] = useState(true);
  const [removeShortcut, setRemoveShortcut] = useState([]);
  const [removeShortcutDialog, setRemoveShortcutDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { handleChangeNav, menuState } = useMenuContext();

  //console.log(myShortcutsData, "myShortcutsData");
  let windowloc = window.location.pathname.split("/")[1].toLowerCase();
  useEffect(() => {
    if (window.innerWidth > 900) {
      if (windowloc == "eycatalyst") {
        handleHamburgerChange(false);
      }
    }
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  useEffect(() => {
    if (getUrlParams("showTaketheTour")) {
      setTimeout(() => {
        handleUserInfoChange({ takethetour: true });
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (userInfo?.teams?.companyId !== null) {
      getData(APIEndPoints.GET_COMPANY_LOGO(userInfo?.teams?.companyId))
        .then((res) => {
          res.data.isSuccess == true
            ? handleUserInfoChange({ channelLogo: res.data.base64Image })
            : handleUserInfoChange({ channelLogo: "" });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    !window.location.pathname.includes("settings") && getShortcutsFromAPI();
  }, [userInfo?.teams?.companyId]);

  const handleManageTeam = () => {
    navigate(`/yourchannel?prevPath=${window.location.pathname}`);
  };

  const handleTakeaTourDialogToggle = () => {
    handleUserInfoChange({ takethetour: !userInfo.takethetour });
  };

  const handleChannelLogoDialogToggle = () => {
    handleUserInfoChange({ uploadchannellogo: !userInfo.uploadchannellogo });
  };

  useEffect(() => {
    if (
      userInfo.allCompanies.length > 0 &&
      userInfo.teams.companyId !== null &&
      !userInfo.noAccessToMenuDone
    ) {
      app.getContext().then((item) => {
        let contentTypeName = "";
        let subPageId = item?.page?.subPageId;
        let splitPage = subPageId?.split(";");
        if (item?.page?.subPageId) {
          if (splitPage?.[4]?.toLowerCase() === "false") {
            if (splitPage?.[8]?.toLowerCase() == "reportlibrary") {
              contentTypeName = "Report Library";
            } else if (splitPage?.[8]?.toLowerCase() == "datalibrary") {
              contentTypeName = "Data Library";
            } else {
              if (parseInt(splitPage[2]) > 0) {
                contentTypeName =
                  parseInt(splitPage[2]) === 4
                    ? "Visualization Library"
                    : getContentTypeNamesById2(parseInt(splitPage[2]));
              }
            }
          }
        }
        if (contentTypeName !== "") {
          if (splitPage[2] == 17) {
            let featureFlagName =
              Constant.INTERNALAPP_CONSTANTS.FOOTNOTES_ANALYZER;
            if (!userInfo?.teams?.featureFlagsObj[featureFlagName]?.isEnabled) {
              handleUserInfoChange({
                noAccessToMenu: true,
                noAccessToMenuName: contentTypeName,
              });
            }
          } else if (
            !userInfo?.teams?.featureFlagsObj[contentTypeName]?.isEnabled
          ) {
            handleUserInfoChange({
              noAccessToMenu: true,
              noAccessToMenuName: contentTypeName,
            });
          }
        }
      });
    }
  }, [userInfo.teams.featureFlagsObj]);

  const shareTheApp = async () => {
    if (userInfo.loggedInWeb || loggedInM365Disable) {
      setWebVersionDisable(true);
    } else {
      if (sharing.isSupported) {
        await app.getContext().then(async (item) => {
          let shareUrl = `https://teams.microsoft.com/l/entity/${Constant.GetUserAppId(
            item.user.userPrincipalName
          )}/Home`;
          if (!userInfo?.teams?.isClientTenant) {
            shareUrl = shareUrl + `?tenantId=${Constant.TENANT_ID}`;
          }
          await sharing
            .shareWebContent({
              content: [
                {
                  type: "URL",
                  url: shareUrl,
                  preview: true,
                },
              ],
            })
            .then(() => {
              // console.log("app shared");
            })
            .catch((err) => {
              console.log("some error occurred", err);
            });
        });
      } else {
        // console.log("platform not supported for this action");
      }
    }
  };

  const getShortcutsFromAPI = () => {
    getData(APIEndPoints.GET_USER_SHORTCUTS)
      .then((res) => {
        //console.log(res);
        handleMyShortcutsData({
          shortcutsList: res.data,
        });
        setshowMyShortcuts(true);
      })
      .catch((error) => {
        console.log(error);
        if (
          error?.response?.data?.Errors[0]?.toLowerCase() == "access denied."
        ) {
          setshowMyShortcuts(false);
        }
        setIsFetching(false);
        //setHasMoreData(false);
      });
  };

  const onClickRemoveShortcut = async () => {
    setLoader(true);
    await deleteDataByPayload(
      APIEndPoints.REMOVE_USER_SHORTCUTS(removeShortcut.userShortcutId)
    )
      .then((response) => {
        setRemoveShortcutDialog(false);
        setLoader(false);

        if (response.status === 200) {
          const updatedShortcuts = myShortcutsData.shortcutsList?.filter(
            (shortcut) =>
              shortcut.userShortcutId !== removeShortcut.userShortcutId
          );
          handleMyShortcutsData({
            ...myShortcutsData,
            shortcutsList: updatedShortcuts,
          });

          setTimeout(() => {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.MY_SHORTCUTS_REMOVAL_MESSAGE(
                removeShortcut.subMenuItemName
              ),
              type: "Success",
              isFailed: false,
            });
          }, 1000);
        } else {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((error) => {
        let errorText = error?.response?.data?.Errors?.[0];
        setRemoveShortcutDialog(false);
        setLoader(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.MY_SHORTCUTS_REMOVE_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };
  //console.log(myShortcutsData, "myShortcutsData - topbar");

  const closePopup = () => {
    handleUserInfoChange({
      noAccessToMenu: false,
      noAccessToMenuName: "",
      noAccessToMenuDone: true,
    });
    window.scroll(0, 15);
  };
  const showHamburger = true;
  return (
    <div
      style={{ top: props?.unauthorize && "0px" }}
      className={`${userInfo.loggedInWeb ? "topbarDiv-web" : "topbarDiv"}`}
      id="topbar"
    >
      {userInfo.teams?.privacyAgreementAccepted && (
        <>
          {" "}
          {showHamburger ? (
            <GiHamburgerMenu
              tabIndex={0}
              aria-label="Expand/Collapse Menu"
              role="button"
              className="hamburgerMenu"
              size={25}
              onKeyDown={handleHamburgerKeyDown}
              onClick={handleHamburgerChange}
            />
          ) : (
            <div style={{ marginLeft: "25px" }}></div>
          )}
          <BreadCrumb />
        </>
      )}
      <div className="topbar_notification_container">
        <CompanySelectorThemeProvider />
        {showMyShortcuts && !window.location.pathname.includes("settings") && (
          <CustomizedToolTip
            tabIndex={0}
            normalTooltip={true}
            position="below"
            align="center"
            content={"My Shortcuts"}
          >
            <div className="BB-MyShortcutsHeader">
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <img
                    className="addTopBarRemoveShortcutIcon"
                    src={require("../../assets/TopBarShortcutIcon.svg").default}
                  />
                </MenuTrigger>

                <MenuPopover className="BB-MyShortcutsMenuList">
                  <MenuList>
                    {myShortcutsData?.shortcutsList?.length > 0 ? (
                      myShortcutsData?.shortcutsList?.map((option) => (
                        <MenuItem key={option.subMenuItemId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ flex: "85%" }}
                              onClick={() => {
                                const newMenuName = getMenuNamesBySubmenuId(
                                  option.subMenuItemId
                                );
                                const newSubmenuName =
                                  newMenuName !== "EY App Catalog" &&
                                  newMenuName !== "Apps"
                                    ? option.subMenuItemName
                                    : "";

                                // Update the navigation state only if necessary
                                if (
                                  menuState?.menu?.name !== newMenuName ||
                                  menuState?.submenu?.name !== newSubmenuName
                                ) {
                                  handleChangeNav({
                                    section: {
                                      id: "",
                                      name: "",
                                    },
                                    menu: {
                                      id: "",
                                      name: newMenuName,
                                    },
                                    submenu: {
                                      id: option.subMenuItemId,
                                      name: newSubmenuName,
                                    },
                                    data: "",
                                  });
                                }

                                // Always navigate to the new route
                                navigate(
                                  `/${option.subMenuItemName
                                    .replace(/\s/g, "")
                                    .toLowerCase()}`
                                );
                              }}
                            >
                              <p>{option.subMenuItemName}</p>
                            </div>
                            <div style={{ flex: "15%" }}>
                              <FaTrash
                                className="BB-MyShortcutsDeleteIcon"
                                onClick={() => {
                                  setRemoveShortcut(option);
                                  setRemoveShortcutDialog(true);
                                }}
                              />
                            </div>
                          </div>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Shortcuts Added</MenuItem>
                    )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          </CustomizedToolTip>
        )}
        {userInfo.teams?.privacyAgreementAccepted && (
          <>
            {userInfo?.teams?.featureFlagsObj["User Management"]?.isEnabled &&
              !checkIfDeviceIsMob() && (
                <div onClick={handleManageTeam} className="topbar-usermanagement">
                  <CustomizedToolTip
                    tabIndex={0}
                    normalTooltip={true}
                    position="below"
                    align="center"
                    content={"User Management"}
                  >
                    <MdGroup size={24} className="notification_icon" />
                  </CustomizedToolTip>
                </div>
              )}
            {!checkIfDeviceIsMob() && (
              <div
                className={`shareIcon-container ${
                  (userInfo.loggedInWeb || loggedInM365Disable) &&
                  "disable-web-version"
                }`}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Share Catalyst"}
                >
                  <FaShareSquare size={18} onClick={shareTheApp} />
                </CustomizedToolTip>
              </div>
            )}
            {!checkIfDeviceIsMob() && <NotificationIcon />}
            <ContactUsIcon />
            {userInfo?.takethetour && (
              <TakeaTour
                handleTakeaTourDialogToggle={handleTakeaTourDialogToggle}
              />
            )}
            {userInfo?.uploadchannellogo && (
              <UploadChanneLogo
                handleChannelLogoDialogToggle={handleChannelLogoDialogToggle}
              />
            )}
          </>
        )}
      </div>
      <DialogBox
        closeOnOutsideClick={false}
        content={
          <div style={{ padding: "15px" }}>
            <div className="pollPopupHeader">
              <div className="popupLeftHeader">
                <Image
                  alt=""
                  src={require("../../assets/EY_Logo.png")}
                  fit="cover"
                  id={"popupLeftHeaderImg"}
                />
                <div>
                  <p className="popupHeaderTitle">
                    {Constant.APPLICATION_NAME}
                  </p>
                  <p>{userInfo.noAccessToMenuName}</p>
                </div>
              </div>
              <div>
                <span tabIndex={0} className="closeIconPopup" title="Close">
                  <MdClose
                    onClick={closePopup}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        closePopup();
                      }
                    }}
                  />
                </span>
              </div>
            </div>
            <UnauthorizedPage />
            <br />
            <br />
          </div>
        }
        backdrop={true}
        openDialog={userInfo.noAccessToMenu}
        className={"deepLinkPopupStyles"}
      />
      <DialogBox
        closeOnOutsideClick={false}
        content={<Web_Version_Message />}
        headerName={
          <div className="dialogBoxHeader">
            <Image
              alt=""
              src={require("../../assets/EY_Logo.png")}
              fit="cover"
              id={"dialogBoxHeaderImg"}
            />
            <div>
              <strong className="dialogBoxHeaderTitle">
                EY Catalyst Connect - Install App
              </strong>
            </div>
          </div>
        }
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          className: "dialogBoxCloseIcon",
          onClick: () => setWebVersionDisable(false),
        }}
        backdrop={true}
        openDialog={webVersionDisable}
        className={"dialogStyles"}
        cancelButton={"Close"}
        onCancelMethod={() => setWebVersionDisable(false)}
      />
      {
        <DialogBox
          cancelButton={"Cancel"}
          onCancelMethod={() => {
            setRemoveShortcutDialog(false);
          }}
          closeOnOutsideClick={false}
          confirmButton={"Remove"}
          onConfirmMethod={onClickRemoveShortcut}
          content={
            loader === true ? (
              <div className={loader && "loaderLayover"}>
                <ReusableLoader
                  isOverlay={true}
                  label={
                    <p>
                      Do you want to remove {removeShortcut?.subMenuItemName}{" "}
                      page from My Shortcuts?
                    </p>
                  }
                  labelPosition={"start"}
                />
              </div>
            ) : (
              <p>
                Do you want to remove {removeShortcut?.subMenuItemName} page
                from My Shortcuts?
              </p>
            )
          }
          headerName={"Remove Shortcut"}
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            onClick: () => {
              setRemoveShortcutDialog(false);
            },
          }}
          backdrop={true}
          openDialog={removeShortcutDialog}
          className={`dialogDeleteStyles headerStyles ${
            loader && "dialogLayover"
          }`}
        />
      }
    </div>
  );
};

export default TopBar;
