import * as React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "@fluentui/react-northstar";
import { IoIosArrowForward } from "react-icons/io";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import "./breadCrumb.css";

const BreadCrumb = () => {
  const { menuState } = useMenuContext();
  const { notifications } = UseNotificationContext();
  const { ResetBreadCrumb3LevelTitle, isDetailOpen, cardTitle } =
    useContext(BreadCrumbContext);
  let { userInfo } = UseUserInfoContext();
  let path = window.location.pathname.split("/")[1].toLowerCase();

  let menu =
    path !== "eycatalyst" && path !== "settings"
      ? menuState?.menu?.name
      : path === "settings"
      ? "Settings"
      : "What's Catalyst";

  let showSubMenu =
    menuState?.submenu?.name !== "" ||
    path === "eycatalyst" ||
    path === "settings";

  let submenu =
    path !== "eycatalyst" && path !== "settings"
      ? menuState?.submenu?.name
      : menuState?.menu?.name;

  let showBreadCrumb =
    path !== "search" &&
    path !== "eycatalyst" &&
    !notifications.isFullPageNotification &&
    !userInfo.loading;

  // let to =
  //menuState.submenu.name !== "" ? menuState.submenu.to : menuState.menu.to;

  const handleClick = () => {
    ResetBreadCrumb3LevelTitle();
  };

  return (
    <>
      {showBreadCrumb && (
        <Breadcrumb>
          <Breadcrumb.Item
            className="breadcrumbItem linkPaddingRightMedium"
            data-testid="breadcrumb-first-level"
          >
            {menu}
          </Breadcrumb.Item>
          {showSubMenu && (
            <>
              {" "}
              <Breadcrumb.Divider style={{ margin: "0px" }}>
                <IoIosArrowForward />
              </Breadcrumb.Divider>
              <Breadcrumb.Item
                className="breadcrumbItem linkPaddingRightMedium"
                active
                onClick={handleClick}
              >
                {/* <Breadcrumb.Link
                  href={menuState.to}
                  style={{ textDecoration: "none" }}
                  data-testid="breadcrumb-second-level"
                >
                  <p className={isDetailOpen ? "breadcrumbItem" : "lastItem"}>
                    {submenu}
                  </p>
                </Breadcrumb.Link> */}

                <Link
                  to={
                    menuState?.to === undefined
                      ? "#"
                      : `${menuState?.to + "?loadFromCache=true"}`
                  }
                  style={{
                    textDecoration: "none",
                    cursor: isDetailOpen ? "pointer" : "text",
                  }}
                >
                  <p className={isDetailOpen ? "breadcrumbItem" : "lastItem"}>
                    {submenu}
                  </p>
                </Link>
              </Breadcrumb.Item>
            </>
          )}
          {isDetailOpen ? (
            <>
              <Breadcrumb.Divider style={{ margin: "0px" }}>
                <IoIosArrowForward />
              </Breadcrumb.Divider>
              <Breadcrumb.Item
                className="breadcrumbItem linkPaddingRightMedium"
                active
                data-testid="breadcrumb-third-level"
              >
                <p className="lastItem">{cardTitle}</p>
              </Breadcrumb.Item>
            </>
          ) : (
            " "
          )}
        </Breadcrumb>
      )}
    </>
  );
};

export default BreadCrumb;
