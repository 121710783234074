import React, { useState, useEffect } from "react";
import CardExampleSize from "../../commonui/card/Card";
import APIEndPoints from "../../utility/apiendpoints";
import { getDataById, postData } from "../../services/customApis";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import LoadMore from "../../commonui/loadmorebtn/loadmore";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { scrollIntoViewWithOffset } from "../../../src/utility/menuscroll";
import NoUserGroupsAvailable from "../nousergroupsavailable/noUserGroupsAvailable";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import { disable } from "workbox-navigation-preload";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";

export const UserGroupContainer = (props) => {
  const { menuState } = useMenuContext();
  const { handleMenuInfo } = UseContactUsContext();
  const { userInfo } = UseUserInfoContext();
  const [viewSelfCustomUserGroup, setViewSelfCustomUserGroup] = useState(false);

  const [cardsData, setCardsData] = useState([]);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [cardConfigData, setCardConfigData] = useState([]);
  const [tilesFetchDetails, setTilesFetchDetails] = useState(
    props.cardFetchObj
  );
  useEffect(() => {
    handleMenuInfo({
      menuName: "Settings",
      subMenuName: menuState?.menu?.name,
      sourceComponent: "",
    });
  }, []);
  useEffect(() => {
    if (
      //!isApiCalled &&
      menuState.menu.name === props.name
    ) {
      //setIsLoading(true);
      let subMenuItemId =
        userInfo?.teams?.featureFlagsObj[props.name]?.subMenuItemId;
      getDataById(
        "",
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          subMenuItemId
        )
      )
        .then(async (res) => {
          //console.log("res", res?.data?.contentType);
          getFeatureFlags(res?.data?.contentType);
          getCardData();
        })
        .catch(async (error) => {
          if (
            error?.response?.data?.Errors?.[0]?.toLowerCase() ==
            "access denied."
          ) {
            setAccessDenied(true);
          } else {
            //setErrorApiCall(true);
          }
          // setIsLoading(false);
        });
    }
  }, [menuState.menu.name]);

  const getFeatureFlags = (configData) => {
    if (
      configData !== undefined &&
      configData[0]?.subMenuItemId ===
        userInfo?.teams?.featureFlagsObj[props.name]?.subMenuItemId
    ) {
      props.setNewButtonConfig(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
          ?.addNewCustomUserGroup
      );
      setCardConfigData(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
      );
      setViewSelfCustomUserGroup(
        configData[0]?.contentCategories?.[1]?.featureFlagsDetails
          ?.viewSelfCustomUserGroup.viewSelfCustomUserGroupDisplay
      );
    }
  };

  const getCardData = async (isLoadMore, cardFetch) => {
    !isLoadMore && setIsCardLoading(true);
    //console.log("userinfoacá", userInfo.teams.companyId);

    let param = {
      pageSize: isNaN(tilesFetchDetails.pageLength)
        ? 6
        : tilesFetchDetails.pageLength,
      page: tilesFetchDetails.page,
      filter: "",
    };
    if (cardFetch) {
      param.page = 1;
    }

    await postData(
      param,
      APIEndPoints.GET_CUSTOM_USER_GROUPS(userInfo?.teams?.companyId)
    )
      .then((customUserGroupsData) => {
        const newData =
          customUserGroupsData.data.resultData === undefined
            ? customUserGroupsData?.data
            : customUserGroupsData?.data?.resultData;

        if (newData.length > 0) {
          const rowIndex = newData?.at(-1).rowIndex;
          const totalCount = newData?.at(-1).totalCount;
          if (rowIndex === totalCount) {
            setTilesFetchDetails((previousData) => {
              return {
                ...previousData,
                hasMoreData: false,
              };
            });
          }
          //console.log("rowIndex", newData?.at(-1).rowIndex, newData?.at(-1).totalCount);
        }

        if (isLoadMore) {
          setCardsData((prevData) => {
            return [...prevData, ...newData];
          });
        } else {
          setCardsData(newData);
        }

        //console.log("allCardsData", cardsData);
        setIsCardLoading(false);
      })
      .catch((error) => {
        setIsCardLoading(false);

        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorAPI(true);
        }
      })
      .finally(() => {
        setTilesFetchDetails((previousData) => {
          return {
            ...previousData,
            isFetching: false,
            page: previousData?.page + 1,
          };
        });
      });
  };

  const handleReload = async () => {
    try {
      let topbarHeight = document
        .getElementById("topbar")
        .getBoundingClientRect().height;
      scrollIntoViewWithOffset(props?.id, -(topbarHeight + 40));
    } catch (e) {}
    await getCardData(undefined, true);
  };
  const handleLoadMore = () => {
    setTilesFetchDetails((previousData) => {
      return {
        ...previousData,

        isFetching: true,
      };
    });
    getCardData(true);
  };

  if (errorAPI) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;
  return (
    <>
      {isCardLoading ? (
        <ReusableLoader />
      ) : viewSelfCustomUserGroup ? (
        <div className="CardComponent">
          {cardsData?.length > 0 ? (
            <>
              <div className="CardList">
                {cardsData?.map((item) => (
                  <CardExampleSize
                    key={item.customUserGroupId}
                    id={item.customUserGroupId}
                    item={item}
                    config={cardConfigData}
                    isCustomUserGroup={true}
                    isImage={false}
                    showMoreButton={true}
                    onClick={disable}
                    handleReload={handleReload}
                  />
                ))}
              </div>
              <LoadMore
                onClick={() => handleLoadMore()}
                isFetching={tilesFetchDetails.isFetching}
                hasMoreData={tilesFetchDetails.hasMoreData}
              />
            </>
          ) : (
            <NoUserGroupsAvailable />
          )}
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};
