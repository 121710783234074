import React from "react";
import { Checkbox } from "@fluentui/react-northstar";
import "./multiCheckBox.css";

const MultiCheckbox = ({
  checkBoxArray,
  onChange,
  className,
  defaultOptions,
  featureFlags,
}) => {
  const handleChange = (event, checkboxProps) => {
    onChange(event, checkboxProps);
  };

  const checkboxes = checkBoxArray?.filter((item) => {
    if (item?.hasOwnProperty("accessibility") && item?.showOption == true) {
      let privileges = item?.accessibility?.privileges;
      if (
        typeof privileges === "object" &&
        privileges?.root?.privilegeCheck == true
      ) {
        let featureFlag = privileges?.root?.featureFlagsDetails?.featureFlagKey;
        if (
          Object.keys(featureFlags)
            .map((flag) => flag.toLowerCase())
            .includes(featureFlag.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return item?.showOption == true;
    }
  });
  return (
    <div className="multi_select_checkBox">
      <p style={{ fontWeight: 600 }}>{"Search Parameters :"}</p>
      {defaultOptions?.length > 0 &&
        defaultOptions?.map((checkbox, index) => (
          <Checkbox
            key={index}
            name={checkbox?.controlID}
            label={checkbox?.controlLabelName}
            checked={true}
            disabled={true}
            className={className}
          />
        ))}
      {checkboxes?.map((checkbox, index) => (
        <Checkbox
          key={index}
          name={checkbox.searchOptionID}
          label={checkbox.searchOptionName}
          //checked={selected[checkbox.searchOptionID] || checkbox.isChecked}
          checked={checkbox.isChecked}
          disabled={checkbox.isDisabled}
          onChange={handleChange}
          className={className}
        />
      ))}
    </div>
  );
};

export default MultiCheckbox;
