import React from "react";
import CustomizedToolTip from "../../../commonui/tooltip/toolTip";
import { ImageUtil } from "../../../utility/utility";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import emojisList from "../../../data/emojislist/emojislist.json";
import "./reactionPill.css";

const ReactionPill = (props) => {
  const { userInfo } = UseUserInfoContext();
  const emojiUserMap = props.reactions.reduce((acc, currReaction) => {
    if (!acc[currReaction.emojiUnicode]) {
      acc[currReaction.emojiUnicode] = [];
    }
    currReaction.userDetails.forEach((userDetail) => {
      acc[currReaction.emojiUnicode].push(userDetail.userName);
    });
    return acc;
  }, {});

  // Sort the reactions by whether the user reacted and by emoji
  const sortedReactions = props.reactions.sort((reactionA, reactionB) => {
    const usersA = emojiUserMap[reactionA.emojiUnicode];
    const usersB = emojiUserMap[reactionB.emojiUnicode];

    if (usersA.includes(userInfo.displayName)) return 0;
    if (usersB.includes(userInfo.displayName)) return 0;

    return reactionB.count - reactionA.count;
  });

  // Find the user's reaction and move it to the third spot if it exists and is not in the top three
  const userReactionIndex = sortedReactions.findIndex((reaction) =>
    emojiUserMap[reaction.emojiUnicode].includes(userInfo.displayName)
  );
  if (userReactionIndex > 2) {
    const userReaction = sortedReactions.splice(userReactionIndex, 1)[0];
    sortedReactions.splice(2, 0, userReaction);
  }

  // Split the sorted reactions into the first three and the rest
  const firstThreeReactions = sortedReactions.slice(0, 3);
  const remainingReactions = sortedReactions
    .slice(3)
    .filter((reaction) => reaction.count > 0);

  // Calculate the total count of the remaining reactions
  const remainingCount = remainingReactions.reduce(
    (total, reaction) => total + reaction.count,
    0
  );
  const unselectableStyles = {
    userSelect: "none",
    WebkitUserSelect: "none", // for Safari and Chrome
    MozUserSelect: "none", // for Firefox
    MsUserSelect: "none", // for Internet Explorer 10+
    WebkitTapHighlightColor: "transparent", // for mobile browsers
  };

  return (
    <div className="reaction" style={unselectableStyles}>
      {firstThreeReactions.length > 0 &&
        firstThreeReactions.map(
          (reaction, i) =>
            reaction.count > 0 && ( // Add this condition
              <CustomizedToolTip
                onDesktopClick={() => props.onDesktopClick(reaction)}
                onTouchStart={() => props.onTouchStart(reaction)}
                onTouchEnd={() => props.onTouchEnd(reaction)}
                tabIndex={0}
                normalTooltip={true}
                position="below"
                content={getPrimaryTooltipContent([reaction])}
              >
                <button
                  key={i}
                  className="reaction-pill"
                  onClick={() => props.onClick(reaction)}
                  onMouseOver={() => props.onMouseOver(reaction)}
                >
                  {String.fromCodePoint(reaction.emojiUnicode)} {reaction.count}
                </button>
              </CustomizedToolTip>
            )
        )}
      {remainingReactions.length > 0 && (
        <CustomizedToolTip
          tabIndex={0}
          normalTooltip={true}
          position="below"
          content={getOtherTooltipContent(remainingReactions)}
        >
          <button
            className="reaction-pill"
            onMouseOver={() => props.onMouseOver(remainingReactions[0])}
          >
            +{remainingCount}
          </button>
        </CustomizedToolTip>
      )}
    </div>
  );

  function getOtherTooltipContent(reactions) {
    return (
      <div>
        <p className="more-reactions-header">More reactions</p>
        {reactions.map((reaction) => {
          const users = emojiUserMap[reaction.emojiUnicode];
          return users.map((user) => (
            <div key={user} className="more-reactions-tooltip-item">
              <img
                className="more-reactions-tooltip-item-image"
                src={ImageUtil(user)}
              />
              <span style={{ paddingRight: "5px" }}>{user}</span>
              <span>{String.fromCodePoint(reaction.emojiUnicode)}</span>
            </div>
          ));
        })}
      </div>
    );
  }
  function getPrimaryTooltipContent(reactions) {
    const emojiUserMap = reactions.reduce((acc, currReaction) => {
      if (!acc[currReaction.emojiUnicode]) {
        acc[currReaction.emojiUnicode] = [];
      }
      currReaction.userDetails.forEach((userDetail) => {
        acc[currReaction.emojiUnicode].push(userDetail.userName);
      });
      return acc;
    }, {});

    const sortedEmojiUserMap = Object.entries(emojiUserMap).sort(
      (entryA, entryB) => {
        const [emojiA, usersA] = entryA;
        const [emojiB, usersB] = entryB;
        if (usersA.includes(userInfo.displayName)) return -1;
        if (usersB.includes(userInfo.displayName)) return 1;
        return emojiA.localeCompare(emojiB);
      }
    );

    return (
      <div>
        {sortedEmojiUserMap.map(([emojiUnicode, users], index) => {
          return (
            <div key={emojiUnicode}>
              {index !== 0 && <br />}
              <p className="primary-reaction-header">
                {
                  emojisList.find(
                    (emojiItem) => emojiItem.emojiUnicode === emojiUnicode
                  )?.emojiTitle
                }
              </p>
              {users.map((user, userIndex) => (
                <div key={userIndex} className="primary-reaction-tooltip-item">
                  <img
                    className="primary-reaction-tooltip-item-image"
                    src={ImageUtil(user)}
                  />
                  {user}
                  {userIndex < users.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
};

export default ReactionPill;
